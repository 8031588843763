/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AccessLevelProto, UserProjectProto, TeamProjectProto, ProjectProto } from "../../protocol/concept";
import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";
import { AccessLevel, accessLevelOf } from "./iam";

export interface Project extends BaseResource {
    name: string;
    createdAt: Date;
}

export interface UserProject extends Project {
    accessLevel: AccessLevel;
}

export interface TeamProject extends Project {
    accessLevel: AccessLevel;
}

export function partialProjectOf(data: ProjectProto): PartialWithUuid<Project> {
    const project: PartialWithUuid<Project> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        name: data.hasName ? data.name: undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
    };
    return stripUndefinedValues(project) as PartialWithUuid<Project>;
}

function partialProjectWithAccessLevelOf(data: { project: ProjectProto, accessLevel: AccessLevelProto, hasAccessLevel: boolean }) {
    const projectWithAccessLevel = {
        ...partialProjectOf(data.project),
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
    };
    return stripUndefinedValues(projectWithAccessLevel)
}

export function partialUserProjectOf(data: UserProjectProto): PartialWithUuid<UserProject> {
    return partialProjectWithAccessLevelOf(data) as PartialWithUuid<UserProject>
}

export function partialTeamProjectOf(data: TeamProjectProto): PartialWithUuid<TeamProject> {
    return partialProjectWithAccessLevelOf(data) as PartialWithUuid<TeamProject>
}

export function projectProtoOf(project: Partial<Project>): ProjectProto {
    return new ProjectProto({
        uuid: project.uuid ? stringToBytes(project.uuid) : undefined,
        id: project.id,
        name: project.name,
        createdAt: project.createdAt?.getTime(),
    });
}

type BaseProjectColumn = "id" | "name" | "createdAt";
export type ProjectColumn = BaseProjectColumn | "accessLevel";

export const projectProperties: Record<BaseProjectColumn, Property> = {
    id: { id: "id", name: "Project ID", attributeType: "id" },
    name: { id: "name", name: "Project Name", attributeType: "name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

const assignedProjectProperties: Record<ProjectColumn, Property> = {
    ...projectProperties,
    accessLevel: { id: "accessLevel", name: "Access Level", ownerType: "assignment", attributeType: "access-level" },
}
export const teamProjectProperties = assignedProjectProperties
export const userProjectProperties = assignedProjectProperties

export const projectPropertiesList = [projectProperties.id, projectProperties.name, projectProperties.createdAt];
const assignedProjectPropertiesList = [...projectPropertiesList, assignedProjectProperties.accessLevel];
export const teamProjectPropertiesList = assignedProjectPropertiesList;
export const userProjectPropertiesList = assignedProjectPropertiesList;
