/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/team-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/concept";
import * as dependency_2 from "./../../protocol/common";
import * as pb_1 from "google-protobuf";
export class TeamCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProto {
        const message = new TeamCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProto {
        return TeamCtlProto.deserialize(bytes);
    }
}
export class TeamCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: TeamCtlProtoGetAccessLevelsReq;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: TeamCtlProtoCheckTeamIDReq;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: TeamCtlProtoCreateTeamReq;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: TeamCtlProtoGetTeamReq;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: TeamCtlProtoUpdateTeamReq;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: TeamCtlProtoDeleteTeamReq;
        listMembers?: never;
        listProjects?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: TeamCtlProtoListMembersReq;
        listProjects?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: TeamCtlProtoListProjectsReq;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: never;
        addMember?: TeamCtlProtoAddMemberReq;
        removeMember?: never;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: never;
        addMember?: never;
        removeMember?: TeamCtlProtoRemoveMemberReq;
        setMemberAccessLevel?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        createTeam?: never;
        getTeam?: never;
        updateTeam?: never;
        deleteTeam?: never;
        listMembers?: never;
        listProjects?: never;
        addMember?: never;
        removeMember?: never;
        setMemberAccessLevel?: TeamCtlProtoSetMemberAccessLevelReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkTeamId" in data && data.checkTeamId != undefined) {
                this.checkTeamId = data.checkTeamId;
            }
            if ("createTeam" in data && data.createTeam != undefined) {
                this.createTeam = data.createTeam;
            }
            if ("getTeam" in data && data.getTeam != undefined) {
                this.getTeam = data.getTeam;
            }
            if ("updateTeam" in data && data.updateTeam != undefined) {
                this.updateTeam = data.updateTeam;
            }
            if ("deleteTeam" in data && data.deleteTeam != undefined) {
                this.deleteTeam = data.deleteTeam;
            }
            if ("listMembers" in data && data.listMembers != undefined) {
                this.listMembers = data.listMembers;
            }
            if ("listProjects" in data && data.listProjects != undefined) {
                this.listProjects = data.listProjects;
            }
            if ("addMember" in data && data.addMember != undefined) {
                this.addMember = data.addMember;
            }
            if ("removeMember" in data && data.removeMember != undefined) {
                this.removeMember = data.removeMember;
            }
            if ("setMemberAccessLevel" in data && data.setMemberAccessLevel != undefined) {
                this.setMemberAccessLevel = data.setMemberAccessLevel;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetAccessLevelsReq, 1) as TeamCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: TeamCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkTeamId() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoCheckTeamIDReq, 2) as TeamCtlProtoCheckTeamIDReq;
    }
    set checkTeamId(value: TeamCtlProtoCheckTeamIDReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckTeamId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get createTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoCreateTeamReq, 3) as TeamCtlProtoCreateTeamReq;
    }
    set createTeam(value: TeamCtlProtoCreateTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasCreateTeam() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetTeamReq, 4) as TeamCtlProtoGetTeamReq;
    }
    set getTeam(value: TeamCtlProtoGetTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetTeam() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get updateTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoUpdateTeamReq, 5) as TeamCtlProtoUpdateTeamReq;
    }
    set updateTeam(value: TeamCtlProtoUpdateTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasUpdateTeam() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get deleteTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoDeleteTeamReq, 6) as TeamCtlProtoDeleteTeamReq;
    }
    set deleteTeam(value: TeamCtlProtoDeleteTeamReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasDeleteTeam() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get listMembers() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListMembersReq, 7) as TeamCtlProtoListMembersReq;
    }
    set listMembers(value: TeamCtlProtoListMembersReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListMembers() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listProjects() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListProjectsReq, 8) as TeamCtlProtoListProjectsReq;
    }
    set listProjects(value: TeamCtlProtoListProjectsReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListProjects() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get addMember() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoAddMemberReq, 9) as TeamCtlProtoAddMemberReq;
    }
    set addMember(value: TeamCtlProtoAddMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasAddMember() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get removeMember() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoRemoveMemberReq, 10) as TeamCtlProtoRemoveMemberReq;
    }
    set removeMember(value: TeamCtlProtoRemoveMemberReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasRemoveMember() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get setMemberAccessLevel() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoSetMemberAccessLevelReq, 11) as TeamCtlProtoSetMemberAccessLevelReq;
    }
    set setMemberAccessLevel(value: TeamCtlProtoSetMemberAccessLevelReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasSetMemberAccessLevel() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkTeamId" | "createTeam" | "getTeam" | "updateTeam" | "deleteTeam" | "listMembers" | "listProjects" | "addMember" | "removeMember" | "setMemberAccessLevel";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkTeamId",
            3: "createTeam",
            4: "getTeam",
            5: "updateTeam",
            6: "deleteTeam",
            7: "listMembers",
            8: "listProjects",
            9: "addMember",
            10: "removeMember",
            11: "setMemberAccessLevel"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof TeamCtlProtoGetAccessLevelsReq.prototype.toObject>;
        checkTeamId?: ReturnType<typeof TeamCtlProtoCheckTeamIDReq.prototype.toObject>;
        createTeam?: ReturnType<typeof TeamCtlProtoCreateTeamReq.prototype.toObject>;
        getTeam?: ReturnType<typeof TeamCtlProtoGetTeamReq.prototype.toObject>;
        updateTeam?: ReturnType<typeof TeamCtlProtoUpdateTeamReq.prototype.toObject>;
        deleteTeam?: ReturnType<typeof TeamCtlProtoDeleteTeamReq.prototype.toObject>;
        listMembers?: ReturnType<typeof TeamCtlProtoListMembersReq.prototype.toObject>;
        listProjects?: ReturnType<typeof TeamCtlProtoListProjectsReq.prototype.toObject>;
        addMember?: ReturnType<typeof TeamCtlProtoAddMemberReq.prototype.toObject>;
        removeMember?: ReturnType<typeof TeamCtlProtoRemoveMemberReq.prototype.toObject>;
        setMemberAccessLevel?: ReturnType<typeof TeamCtlProtoSetMemberAccessLevelReq.prototype.toObject>;
    }): TeamCtlProtoReq {
        const message = new TeamCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = TeamCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.checkTeamId != null) {
            message.checkTeamId = TeamCtlProtoCheckTeamIDReq.fromObject(data.checkTeamId);
        }
        if (data.createTeam != null) {
            message.createTeam = TeamCtlProtoCreateTeamReq.fromObject(data.createTeam);
        }
        if (data.getTeam != null) {
            message.getTeam = TeamCtlProtoGetTeamReq.fromObject(data.getTeam);
        }
        if (data.updateTeam != null) {
            message.updateTeam = TeamCtlProtoUpdateTeamReq.fromObject(data.updateTeam);
        }
        if (data.deleteTeam != null) {
            message.deleteTeam = TeamCtlProtoDeleteTeamReq.fromObject(data.deleteTeam);
        }
        if (data.listMembers != null) {
            message.listMembers = TeamCtlProtoListMembersReq.fromObject(data.listMembers);
        }
        if (data.listProjects != null) {
            message.listProjects = TeamCtlProtoListProjectsReq.fromObject(data.listProjects);
        }
        if (data.addMember != null) {
            message.addMember = TeamCtlProtoAddMemberReq.fromObject(data.addMember);
        }
        if (data.removeMember != null) {
            message.removeMember = TeamCtlProtoRemoveMemberReq.fromObject(data.removeMember);
        }
        if (data.setMemberAccessLevel != null) {
            message.setMemberAccessLevel = TeamCtlProtoSetMemberAccessLevelReq.fromObject(data.setMemberAccessLevel);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof TeamCtlProtoGetAccessLevelsReq.prototype.toObject>;
            checkTeamId?: ReturnType<typeof TeamCtlProtoCheckTeamIDReq.prototype.toObject>;
            createTeam?: ReturnType<typeof TeamCtlProtoCreateTeamReq.prototype.toObject>;
            getTeam?: ReturnType<typeof TeamCtlProtoGetTeamReq.prototype.toObject>;
            updateTeam?: ReturnType<typeof TeamCtlProtoUpdateTeamReq.prototype.toObject>;
            deleteTeam?: ReturnType<typeof TeamCtlProtoDeleteTeamReq.prototype.toObject>;
            listMembers?: ReturnType<typeof TeamCtlProtoListMembersReq.prototype.toObject>;
            listProjects?: ReturnType<typeof TeamCtlProtoListProjectsReq.prototype.toObject>;
            addMember?: ReturnType<typeof TeamCtlProtoAddMemberReq.prototype.toObject>;
            removeMember?: ReturnType<typeof TeamCtlProtoRemoveMemberReq.prototype.toObject>;
            setMemberAccessLevel?: ReturnType<typeof TeamCtlProtoSetMemberAccessLevelReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkTeamId != null) {
            data.checkTeamId = this.checkTeamId.toObject();
        }
        if (this.createTeam != null) {
            data.createTeam = this.createTeam.toObject();
        }
        if (this.getTeam != null) {
            data.getTeam = this.getTeam.toObject();
        }
        if (this.updateTeam != null) {
            data.updateTeam = this.updateTeam.toObject();
        }
        if (this.deleteTeam != null) {
            data.deleteTeam = this.deleteTeam.toObject();
        }
        if (this.listMembers != null) {
            data.listMembers = this.listMembers.toObject();
        }
        if (this.listProjects != null) {
            data.listProjects = this.listProjects.toObject();
        }
        if (this.addMember != null) {
            data.addMember = this.addMember.toObject();
        }
        if (this.removeMember != null) {
            data.removeMember = this.removeMember.toObject();
        }
        if (this.setMemberAccessLevel != null) {
            data.setMemberAccessLevel = this.setMemberAccessLevel.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckTeamId)
            writer.writeMessage(2, this.checkTeamId, () => this.checkTeamId.serialize(writer));
        if (this.hasCreateTeam)
            writer.writeMessage(3, this.createTeam, () => this.createTeam.serialize(writer));
        if (this.hasGetTeam)
            writer.writeMessage(4, this.getTeam, () => this.getTeam.serialize(writer));
        if (this.hasUpdateTeam)
            writer.writeMessage(5, this.updateTeam, () => this.updateTeam.serialize(writer));
        if (this.hasDeleteTeam)
            writer.writeMessage(6, this.deleteTeam, () => this.deleteTeam.serialize(writer));
        if (this.hasListMembers)
            writer.writeMessage(7, this.listMembers, () => this.listMembers.serialize(writer));
        if (this.hasListProjects)
            writer.writeMessage(8, this.listProjects, () => this.listProjects.serialize(writer));
        if (this.hasAddMember)
            writer.writeMessage(9, this.addMember, () => this.addMember.serialize(writer));
        if (this.hasRemoveMember)
            writer.writeMessage(10, this.removeMember, () => this.removeMember.serialize(writer));
        if (this.hasSetMemberAccessLevel)
            writer.writeMessage(11, this.setMemberAccessLevel, () => this.setMemberAccessLevel.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = TeamCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkTeamId, () => message.checkTeamId = TeamCtlProtoCheckTeamIDReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.createTeam, () => message.createTeam = TeamCtlProtoCreateTeamReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getTeam, () => message.getTeam = TeamCtlProtoGetTeamReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.updateTeam, () => message.updateTeam = TeamCtlProtoUpdateTeamReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.deleteTeam, () => message.deleteTeam = TeamCtlProtoDeleteTeamReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listMembers, () => message.listMembers = TeamCtlProtoListMembersReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listProjects, () => message.listProjects = TeamCtlProtoListProjectsReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.addMember, () => message.addMember = TeamCtlProtoAddMemberReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.removeMember, () => message.removeMember = TeamCtlProtoRemoveMemberReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.setMemberAccessLevel, () => message.setMemberAccessLevel = TeamCtlProtoSetMemberAccessLevelReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoReq {
        return TeamCtlProtoReq.deserialize(bytes);
    }
}
export class TeamCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 4, 7, 8]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: TeamCtlProtoGetAccessLevelsRes;
        checkTeamId?: never;
        getTeam?: never;
        listMembers?: never;
        listProjects?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: TeamCtlProtoCheckTeamIDRes;
        getTeam?: never;
        listMembers?: never;
        listProjects?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: TeamCtlProtoGetTeamRes;
        listMembers?: never;
        listProjects?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        listMembers?: TeamCtlProtoListMembersRes;
        listProjects?: never;
    } | {
        getAccessLevels?: never;
        checkTeamId?: never;
        getTeam?: never;
        listMembers?: never;
        listProjects?: TeamCtlProtoListProjectsRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkTeamId" in data && data.checkTeamId != undefined) {
                this.checkTeamId = data.checkTeamId;
            }
            if ("getTeam" in data && data.getTeam != undefined) {
                this.getTeam = data.getTeam;
            }
            if ("listMembers" in data && data.listMembers != undefined) {
                this.listMembers = data.listMembers;
            }
            if ("listProjects" in data && data.listProjects != undefined) {
                this.listProjects = data.listProjects;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetAccessLevelsRes, 1) as TeamCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: TeamCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkTeamId() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoCheckTeamIDRes, 2) as TeamCtlProtoCheckTeamIDRes;
    }
    set checkTeamId(value: TeamCtlProtoCheckTeamIDRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckTeamId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getTeam() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoGetTeamRes, 4) as TeamCtlProtoGetTeamRes;
    }
    set getTeam(value: TeamCtlProtoGetTeamRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetTeam() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get listMembers() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListMembersRes, 7) as TeamCtlProtoListMembersRes;
    }
    set listMembers(value: TeamCtlProtoListMembersRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListMembers() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listProjects() {
        return pb_1.Message.getWrapperField(this, TeamCtlProtoListProjectsRes, 8) as TeamCtlProtoListProjectsRes;
    }
    set listProjects(value: TeamCtlProtoListProjectsRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListProjects() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkTeamId" | "getTeam" | "listMembers" | "listProjects";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkTeamId",
            4: "getTeam",
            7: "listMembers",
            8: "listProjects"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 4, 7, 8])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof TeamCtlProtoGetAccessLevelsRes.prototype.toObject>;
        checkTeamId?: ReturnType<typeof TeamCtlProtoCheckTeamIDRes.prototype.toObject>;
        getTeam?: ReturnType<typeof TeamCtlProtoGetTeamRes.prototype.toObject>;
        listMembers?: ReturnType<typeof TeamCtlProtoListMembersRes.prototype.toObject>;
        listProjects?: ReturnType<typeof TeamCtlProtoListProjectsRes.prototype.toObject>;
    }): TeamCtlProtoRes {
        const message = new TeamCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = TeamCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.checkTeamId != null) {
            message.checkTeamId = TeamCtlProtoCheckTeamIDRes.fromObject(data.checkTeamId);
        }
        if (data.getTeam != null) {
            message.getTeam = TeamCtlProtoGetTeamRes.fromObject(data.getTeam);
        }
        if (data.listMembers != null) {
            message.listMembers = TeamCtlProtoListMembersRes.fromObject(data.listMembers);
        }
        if (data.listProjects != null) {
            message.listProjects = TeamCtlProtoListProjectsRes.fromObject(data.listProjects);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof TeamCtlProtoGetAccessLevelsRes.prototype.toObject>;
            checkTeamId?: ReturnType<typeof TeamCtlProtoCheckTeamIDRes.prototype.toObject>;
            getTeam?: ReturnType<typeof TeamCtlProtoGetTeamRes.prototype.toObject>;
            listMembers?: ReturnType<typeof TeamCtlProtoListMembersRes.prototype.toObject>;
            listProjects?: ReturnType<typeof TeamCtlProtoListProjectsRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkTeamId != null) {
            data.checkTeamId = this.checkTeamId.toObject();
        }
        if (this.getTeam != null) {
            data.getTeam = this.getTeam.toObject();
        }
        if (this.listMembers != null) {
            data.listMembers = this.listMembers.toObject();
        }
        if (this.listProjects != null) {
            data.listProjects = this.listProjects.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckTeamId)
            writer.writeMessage(2, this.checkTeamId, () => this.checkTeamId.serialize(writer));
        if (this.hasGetTeam)
            writer.writeMessage(4, this.getTeam, () => this.getTeam.serialize(writer));
        if (this.hasListMembers)
            writer.writeMessage(7, this.listMembers, () => this.listMembers.serialize(writer));
        if (this.hasListProjects)
            writer.writeMessage(8, this.listProjects, () => this.listProjects.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = TeamCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkTeamId, () => message.checkTeamId = TeamCtlProtoCheckTeamIDRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getTeam, () => message.getTeam = TeamCtlProtoGetTeamRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listMembers, () => message.listMembers = TeamCtlProtoListMembersRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listProjects, () => message.listProjects = TeamCtlProtoListProjectsRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRes {
        return TeamCtlProtoRes.deserialize(bytes);
    }
}
export class TeamCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoGetAccessLevels {
        const message = new TeamCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetAccessLevels {
        return TeamCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class TeamCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuids" in data && data.teamUuids != undefined) {
                this.teamUuids = data.teamUuids;
            }
        }
    }
    get teamUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set teamUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        teamUuids?: Uint8Array[];
    }): TeamCtlProtoGetAccessLevelsReq {
        const message = new TeamCtlProtoGetAccessLevelsReq({});
        if (data.teamUuids != null) {
            message.teamUuids = data.teamUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuids?: Uint8Array[];
        } = {};
        if (this.teamUuids != null) {
            data.teamUuids = this.teamUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuids.length)
            writer.writeRepeatedBytes(1, this.teamUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetAccessLevelsReq {
        return TeamCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_1.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_1.AccessLevelProto[];
    }
    set accessLevels(value: dependency_1.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_1.AccessLevelProto[];
    }): TeamCtlProtoGetAccessLevelsRes {
        const message = new TeamCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_1.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetAccessLevelsRes {
        return TeamCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class TeamCtlProtoCheckTeamID extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoCheckTeamID {
        const message = new TeamCtlProtoCheckTeamID({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCheckTeamID {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCheckTeamID();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCheckTeamID {
        return TeamCtlProtoCheckTeamID.deserialize(bytes);
    }
}
export class TeamCtlProtoCheckTeamIDReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        id?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        id?: string;
    }): TeamCtlProtoCheckTeamIDReq {
        const message = new TeamCtlProtoCheckTeamIDReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            id?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.id != null) {
            data.id = this.id;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.id.length)
            writer.writeString(2, this.id);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCheckTeamIDReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCheckTeamIDReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCheckTeamIDReq {
        return TeamCtlProtoCheckTeamIDReq.deserialize(bytes);
    }
}
export class TeamCtlProtoCheckTeamIDRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        exists?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("exists" in data && data.exists != undefined) {
                this.exists = data.exists;
            }
        }
    }
    get exists() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set exists(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        exists?: boolean;
    }): TeamCtlProtoCheckTeamIDRes {
        const message = new TeamCtlProtoCheckTeamIDRes({});
        if (data.exists != null) {
            message.exists = data.exists;
        }
        return message;
    }
    toObject() {
        const data: {
            exists?: boolean;
        } = {};
        if (this.exists != null) {
            data.exists = this.exists;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.exists != false)
            writer.writeBool(1, this.exists);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCheckTeamIDRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCheckTeamIDRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.exists = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCheckTeamIDRes {
        return TeamCtlProtoCheckTeamIDRes.deserialize(bytes);
    }
}
export class TeamCtlProtoCreateTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoCreateTeam {
        const message = new TeamCtlProtoCreateTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCreateTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCreateTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCreateTeam {
        return TeamCtlProtoCreateTeam.deserialize(bytes);
    }
}
export class TeamCtlProtoCreateTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        team?: dependency_1.TeamProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("team" in data && data.team != undefined) {
                this.team = data.team;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get team() {
        return pb_1.Message.getWrapperField(this, dependency_1.TeamProto, 2) as dependency_1.TeamProto;
    }
    set team(value: dependency_1.TeamProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasTeam() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        team?: ReturnType<typeof dependency_1.TeamProto.prototype.toObject>;
    }): TeamCtlProtoCreateTeamReq {
        const message = new TeamCtlProtoCreateTeamReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.team != null) {
            message.team = dependency_1.TeamProto.fromObject(data.team);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            team?: ReturnType<typeof dependency_1.TeamProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.team != null) {
            data.team = this.team.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasTeam)
            writer.writeMessage(2, this.team, () => this.team.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoCreateTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoCreateTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.team, () => message.team = dependency_1.TeamProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoCreateTeamReq {
        return TeamCtlProtoCreateTeamReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoGetTeam {
        const message = new TeamCtlProtoGetTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetTeam {
        return TeamCtlProtoGetTeam.deserialize(bytes);
    }
}
export class TeamCtlProtoGetTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        teamId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("teamId" in data && data.teamId != undefined) {
                this.teamId = data.teamId;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get teamId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set teamId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        teamId?: string;
    }): TeamCtlProtoGetTeamReq {
        const message = new TeamCtlProtoGetTeamReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.teamId != null) {
            message.teamId = data.teamId;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            teamId?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.teamId != null) {
            data.teamId = this.teamId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.teamId.length)
            writer.writeString(2, this.teamId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.teamId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetTeamReq {
        return TeamCtlProtoGetTeamReq.deserialize(bytes);
    }
}
export class TeamCtlProtoGetTeamRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        team?: dependency_1.TeamProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("team" in data && data.team != undefined) {
                this.team = data.team;
            }
        }
    }
    get team() {
        return pb_1.Message.getWrapperField(this, dependency_1.TeamProto, 1) as dependency_1.TeamProto;
    }
    set team(value: dependency_1.TeamProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTeam() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        team?: ReturnType<typeof dependency_1.TeamProto.prototype.toObject>;
    }): TeamCtlProtoGetTeamRes {
        const message = new TeamCtlProtoGetTeamRes({});
        if (data.team != null) {
            message.team = dependency_1.TeamProto.fromObject(data.team);
        }
        return message;
    }
    toObject() {
        const data: {
            team?: ReturnType<typeof dependency_1.TeamProto.prototype.toObject>;
        } = {};
        if (this.team != null) {
            data.team = this.team.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTeam)
            writer.writeMessage(1, this.team, () => this.team.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoGetTeamRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoGetTeamRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.team, () => message.team = dependency_1.TeamProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoGetTeamRes {
        return TeamCtlProtoGetTeamRes.deserialize(bytes);
    }
}
export class TeamCtlProtoUpdateTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoUpdateTeam {
        const message = new TeamCtlProtoUpdateTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoUpdateTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoUpdateTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoUpdateTeam {
        return TeamCtlProtoUpdateTeam.deserialize(bytes);
    }
}
export class TeamCtlProtoUpdateTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        team?: dependency_1.TeamProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("team" in data && data.team != undefined) {
                this.team = data.team;
            }
        }
    }
    get team() {
        return pb_1.Message.getWrapperField(this, dependency_1.TeamProto, 1) as dependency_1.TeamProto;
    }
    set team(value: dependency_1.TeamProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTeam() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        team?: ReturnType<typeof dependency_1.TeamProto.prototype.toObject>;
    }): TeamCtlProtoUpdateTeamReq {
        const message = new TeamCtlProtoUpdateTeamReq({});
        if (data.team != null) {
            message.team = dependency_1.TeamProto.fromObject(data.team);
        }
        return message;
    }
    toObject() {
        const data: {
            team?: ReturnType<typeof dependency_1.TeamProto.prototype.toObject>;
        } = {};
        if (this.team != null) {
            data.team = this.team.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTeam)
            writer.writeMessage(1, this.team, () => this.team.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoUpdateTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoUpdateTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.team, () => message.team = dependency_1.TeamProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoUpdateTeamReq {
        return TeamCtlProtoUpdateTeamReq.deserialize(bytes);
    }
}
export class TeamCtlProtoDeleteTeam extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoDeleteTeam {
        const message = new TeamCtlProtoDeleteTeam({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoDeleteTeam {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoDeleteTeam();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoDeleteTeam {
        return TeamCtlProtoDeleteTeam.deserialize(bytes);
    }
}
export class TeamCtlProtoDeleteTeamReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
    }): TeamCtlProtoDeleteTeamReq {
        const message = new TeamCtlProtoDeleteTeamReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoDeleteTeamReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoDeleteTeamReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoDeleteTeamReq {
        return TeamCtlProtoDeleteTeamReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListMembers extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoListMembers {
        const message = new TeamCtlProtoListMembers({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListMembers {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListMembers();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListMembers {
        return TeamCtlProtoListMembers.deserialize(bytes);
    }
}
export class TeamCtlProtoListMembersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): TeamCtlProtoListMembersReq {
        const message = new TeamCtlProtoListMembersReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListMembersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListMembersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListMembersReq {
        return TeamCtlProtoListMembersReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListMembersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        members?: dependency_1.TeamMemberProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("members" in data && data.members != undefined) {
                this.members = data.members;
            }
        }
    }
    get members() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.TeamMemberProto, 1) as dependency_1.TeamMemberProto[];
    }
    set members(value: dependency_1.TeamMemberProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        members?: ReturnType<typeof dependency_1.TeamMemberProto.prototype.toObject>[];
    }): TeamCtlProtoListMembersRes {
        const message = new TeamCtlProtoListMembersRes({});
        if (data.members != null) {
            message.members = data.members.map(item => dependency_1.TeamMemberProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            members?: ReturnType<typeof dependency_1.TeamMemberProto.prototype.toObject>[];
        } = {};
        if (this.members != null) {
            data.members = this.members.map((item: dependency_1.TeamMemberProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.members.length)
            writer.writeRepeatedMessage(1, this.members, (item: dependency_1.TeamMemberProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListMembersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListMembersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.members, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.TeamMemberProto.deserialize(reader), dependency_1.TeamMemberProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListMembersRes {
        return TeamCtlProtoListMembersRes.deserialize(bytes);
    }
}
export class TeamCtlProtoListProjects extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoListProjects {
        const message = new TeamCtlProtoListProjects({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListProjects {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListProjects();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListProjects {
        return TeamCtlProtoListProjects.deserialize(bytes);
    }
}
export class TeamCtlProtoListProjectsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): TeamCtlProtoListProjectsReq {
        const message = new TeamCtlProtoListProjectsReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListProjectsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListProjectsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListProjectsReq {
        return TeamCtlProtoListProjectsReq.deserialize(bytes);
    }
}
export class TeamCtlProtoListProjectsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projects?: dependency_1.TeamProjectProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projects" in data && data.projects != undefined) {
                this.projects = data.projects;
            }
        }
    }
    get projects() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.TeamProjectProto, 1) as dependency_1.TeamProjectProto[];
    }
    set projects(value: dependency_1.TeamProjectProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        projects?: ReturnType<typeof dependency_1.TeamProjectProto.prototype.toObject>[];
    }): TeamCtlProtoListProjectsRes {
        const message = new TeamCtlProtoListProjectsRes({});
        if (data.projects != null) {
            message.projects = data.projects.map(item => dependency_1.TeamProjectProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            projects?: ReturnType<typeof dependency_1.TeamProjectProto.prototype.toObject>[];
        } = {};
        if (this.projects != null) {
            data.projects = this.projects.map((item: dependency_1.TeamProjectProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projects.length)
            writer.writeRepeatedMessage(1, this.projects, (item: dependency_1.TeamProjectProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoListProjectsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoListProjectsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.projects, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.TeamProjectProto.deserialize(reader), dependency_1.TeamProjectProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoListProjectsRes {
        return TeamCtlProtoListProjectsRes.deserialize(bytes);
    }
}
export class TeamCtlProtoAddMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoAddMember {
        const message = new TeamCtlProtoAddMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoAddMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoAddMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoAddMember {
        return TeamCtlProtoAddMember.deserialize(bytes);
    }
}
export class TeamCtlProtoAddMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_1.AccessLevelProto.NONE) as dependency_1.AccessLevelProto;
    }
    set accessLevel(value: dependency_1.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }): TeamCtlProtoAddMemberReq {
        const message = new TeamCtlProtoAddMemberReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            userUuid?: Uint8Array;
            accessLevel?: dependency_1.AccessLevelProto;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (this.accessLevel != dependency_1.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoAddMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoAddMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoAddMemberReq {
        return TeamCtlProtoAddMemberReq.deserialize(bytes);
    }
}
export class TeamCtlProtoRemoveMember extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoRemoveMember {
        const message = new TeamCtlProtoRemoveMember({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRemoveMember {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRemoveMember();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRemoveMember {
        return TeamCtlProtoRemoveMember.deserialize(bytes);
    }
}
export class TeamCtlProtoRemoveMemberReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
    }): TeamCtlProtoRemoveMemberReq {
        const message = new TeamCtlProtoRemoveMemberReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            userUuid?: Uint8Array;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoRemoveMemberReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoRemoveMemberReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoRemoveMemberReq {
        return TeamCtlProtoRemoveMemberReq.deserialize(bytes);
    }
}
export class TeamCtlProtoSetMemberAccessLevel extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): TeamCtlProtoSetMemberAccessLevel {
        const message = new TeamCtlProtoSetMemberAccessLevel({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoSetMemberAccessLevel {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoSetMemberAccessLevel();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoSetMemberAccessLevel {
        return TeamCtlProtoSetMemberAccessLevel.deserialize(bytes);
    }
}
export class TeamCtlProtoSetMemberAccessLevelReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teamUuid" in data && data.teamUuid != undefined) {
                this.teamUuid = data.teamUuid;
            }
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("accessLevel" in data && data.accessLevel != undefined) {
                this.accessLevel = data.accessLevel;
            }
        }
    }
    get teamUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set teamUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get accessLevel() {
        return pb_1.Message.getFieldWithDefault(this, 3, dependency_1.AccessLevelProto.NONE) as dependency_1.AccessLevelProto;
    }
    set accessLevel(value: dependency_1.AccessLevelProto) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        teamUuid?: Uint8Array;
        userUuid?: Uint8Array;
        accessLevel?: dependency_1.AccessLevelProto;
    }): TeamCtlProtoSetMemberAccessLevelReq {
        const message = new TeamCtlProtoSetMemberAccessLevelReq({});
        if (data.teamUuid != null) {
            message.teamUuid = data.teamUuid;
        }
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.accessLevel != null) {
            message.accessLevel = data.accessLevel;
        }
        return message;
    }
    toObject() {
        const data: {
            teamUuid?: Uint8Array;
            userUuid?: Uint8Array;
            accessLevel?: dependency_1.AccessLevelProto;
        } = {};
        if (this.teamUuid != null) {
            data.teamUuid = this.teamUuid;
        }
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.accessLevel != null) {
            data.accessLevel = this.accessLevel;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teamUuid.length)
            writer.writeBytes(1, this.teamUuid);
        if (this.userUuid.length)
            writer.writeBytes(2, this.userUuid);
        if (this.accessLevel != dependency_1.AccessLevelProto.NONE)
            writer.writeEnum(3, this.accessLevel);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TeamCtlProtoSetMemberAccessLevelReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TeamCtlProtoSetMemberAccessLevelReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.teamUuid = reader.readBytes();
                    break;
                case 2:
                    message.userUuid = reader.readBytes();
                    break;
                case 3:
                    message.accessLevel = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TeamCtlProtoSetMemberAccessLevelReq {
        return TeamCtlProtoSetMemberAccessLevelReq.deserialize(bytes);
    }
}
