/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Parameters } from "../../concept/common";
import { Organization } from "../../concept/organization";
import {
    ProjectColumn, projectProperties,
    TeamProject, teamProjectProperties,
    teamProjectPropertiesList
} from "../../concept/project";
import { Team } from "../../concept/team";
import { TeamMember, teamMemberProperties, teamMemberPropertiesList, UserColumn } from "../../concept/user";
import { AuthorizationService } from "../authorization.service";
import { BaseMembersTable, BaseProjectsTable } from "../org/org-controller.service";
import { ResourceTable, SpecialColumn } from "../resource-table.service";
import { Observable, Subject } from "rxjs";
import { ApiListResponse } from "../../concept/api-response";
import { TeamApi } from "./team-api.service";
import { ApplicationState } from "../application-state.service";
import { stringFilterSpec } from "../../framework/table";
import { accessLevelValues } from "../../concept/iam";

@Injectable({
    providedIn: "root",
})
export class TeamController {

    constructor(private api: TeamApi, private authorization: AuthorizationService, private app: ApplicationState) { }

    membersTable(team: Team, unsub$: Subject<void>): ResourceTable<TeamMember, UserColumn> {
        return new TeamMembersTable(this.app.requireCurrentOrg(), team, this.api, this.authorization, unsub$);
    }

    projectsTable(team: Team, unsub$: Subject<void>): ResourceTable<TeamProject, ProjectColumn> {
        return new TeamProjectsTable(this.app.requireCurrentOrg(), team, this.api, this.authorization, unsub$);
    }
}

class TeamMembersTable extends BaseMembersTable<TeamMember, UserColumn> {
    override properties = teamMemberPropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(teamMemberProperties.id),
        stringFilterSpec(teamMemberProperties.email),
        stringFilterSpec(teamMemberProperties.firstName),
        stringFilterSpec(teamMemberProperties.lastName),
        stringFilterSpec(teamMemberProperties.accessLevel, accessLevelValues)
    ];

    constructor(org: Organization, private team: Team, private teamApi: TeamApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<TeamMember>> {
        return this.teamApi.listMembers(this.team.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (UserColumn | SpecialColumn)[] {
        return ["avatar", ...this.displayedProperties.map(x => x.id as UserColumn), "actions"];
    }
}

class TeamProjectsTable extends BaseProjectsTable<TeamProject> {
    override properties = teamProjectPropertiesList
    override displayedProperties = [...this.properties]
    override filterSpecs = [
        stringFilterSpec(projectProperties.id),
        stringFilterSpec(projectProperties.name),
        stringFilterSpec(teamProjectProperties.accessLevel, accessLevelValues),
    ];

    constructor(org: Organization, private team: Team, private teamApi: TeamApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, authorization, unsub$);
    }

    override get displayedColumns(): ProjectColumn[] {
        return [...this.displayedProperties.map(x => x.id as ProjectColumn)];
    }

    override getData(params: Parameters): Observable<ApiListResponse<TeamProject>> {
        return this.teamApi.listProjects(this.team.uuid, params, this.unsub$);
    }
}
