/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    first,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap
} from "rxjs";
import {
    BreadcrumbComponent,
    ButtonComponent,
    ConfirmationModalComponent,
    ContactSupportDialogData,
    ContactSupportModalComponent,
    DeleteResourceSectionComponent,
    ModalComponent,
    PropertiesTableComponent,
    PropertiesTableRowComponent
} from "typedb-platform-framework";
import { ProjectColumn, UserProject } from "../../../concept/project";
import { UserTeam, TeamColumn } from "../../../concept/team";
import { OrgMember } from "../../../concept/user";
import { ActivatedRoute } from "@angular/router";
import { membersPath } from "../../../routing/resource-paths";
import { ResourceTable } from "../../../service/resource-table.service";
import { ResourceService } from "../../../service/resource.service";
import { ApplicationState } from "../../../service/application-state.service";
import { UserController } from "../../../service/user/user-controller.service";
import { PageScaffoldComponent, ResourceAvailability } from "../../scaffold/page/page-scaffold.component";
import { TeamsTableComponent } from "../../team/table/teams-table.component";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { AccessLevelPipe } from "../../../framework/access-level.pipe";
import { OrgApi } from "../../../service/org/org-api.service";
import { ProjectsTableComponent } from "../../project/table/projects-table.component";
import {
    OrgSetUserAccessLevelDialogComponent
} from "../../org/set-user-access-level/org-set-user-access-level-dialog.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AccessLevel } from "../../../concept/iam";

@Component({
    selector: "tp-member-details-page",
    templateUrl: "./member-details-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        DeleteResourceSectionComponent, ConfirmationModalComponent, AsyncPipe, TableToolbarComponent, FontAwesomeModule,
        TablePaginatorComponent, TeamsTableComponent, PropertiesTableRowComponent, AccessLevelPipe, ProjectsTableComponent, ButtonComponent
    ],
})
export class MemberDetailsPageComponent implements OnInit, OnDestroy{
    readonly orgMember$ = new BehaviorSubject<OrgMember | null>(null);
    readonly breadcrumb$ = this.orgMember$.pipe(
        map((user) => {
            if (!user) return null;
            else return { items: [user.id], url: membersPath(this.app.requireCurrentOrg()) };
        }),
    );
    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.orgMember$.pipe(map(x => x ? "ready" : "loading"), catchError(() => of("failed" as const)));

    private readonly unsubProjects$ = new Subject<void>();
    projects?: ResourceTable<UserProject, ProjectColumn>;
    private readonly unsubTeams$ = new Subject<void>();
    teams?: ResourceTable<UserTeam, TeamColumn>;

    constructor(
        protected app: ApplicationState, private route: ActivatedRoute, private userCtl: UserController,
        private orgApi: OrgApi, private dialog: MatDialog, private resourceService: ResourceService,
    ) {}

    ngOnInit() {
        this.initDataLoader();
    }

    ngOnDestroy() {
        this.unsub$.next();
        this.unsubProjects$.next();
        this.unsubTeams$.next();
    }

    initDataLoader() {
        this.orgMember$.pipe(first(x => !!x), map(x => x!)).subscribe(user => {
            this.projects = this.userCtl.projectsTable(user, this.unsubProjects$);
            this.teams = this.userCtl.teamsTable(user, this.unsubTeams$);
        });

        this.route.params.pipe(
            map((params) => params["user-id"] as string),
            distinctUntilChanged(),
            tap(() => { this.unsub$.next(); }),
            switchMap((userId) => this.orgApi.getMember(userId, this.app.requireCurrentOrg().uuid, this.unsub$)),
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.orgMember$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.orgMember$.error(err);
            }
        });
    }

    beginRemoveUser() {
        this.dialog.open<ContactSupportModalComponent, ContactSupportDialogData>(
            ContactSupportModalComponent, { data: { title: "Remove user" } }
        );
    }

    openSetAccessLevelModal(user: OrgMember) {
        this.dialog.open(OrgSetUserAccessLevelDialogComponent, { data: { org: this.app.requireCurrentOrg(), user } });
    }
}
