/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, combineLatest } from "rxjs";
import { Organization } from "../../../concept/organization";
import { OrgMember } from "../../../concept/user";
import { DialogResult } from "../../../service/dialog.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { FormActionsComponent, FormComponent, FormOption, FormSelectComponent, ModalComponent, requiredValidator } from "typedb-platform-framework";
import { AccessLevel, accessLevelToString, accessLevelValues } from "../../../concept/iam";

export type OrgSetUserAccessLevelDialogData = { org: Organization, user: OrgMember };

@Component({
    selector: "tp-org-set-user-access-level-dialog",
    templateUrl: "./org-set-user-access-level-dialog.component.html",
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent, FormSelectComponent, FormActionsComponent],
})
export class OrgSetUserAccessLevelDialogComponent {
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        accessLevel: [this.data.user.accessLevel, [requiredValidator]],
    });
    readonly accessLevelOptions: FormOption<AccessLevel>[] = accessLevelValues.map(x => ({ value: x, viewValue: accessLevelToString(x) }));

    constructor(
        private dialogRef: MatDialogRef<OrgSetUserAccessLevelDialogComponent, DialogResult>, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public  data: OrgSetUserAccessLevelDialogData,
        private orgService: OrgApi, private snackbar: SnackbarService
    ) {}

    submit() {
        const form = this.form.value;
        const accessLevel = form.accessLevel!;
        this.orgService.setMemberAccessLevel({ orgUuid: this.data.org.uuid, userUuid: this.data.user.uuid, accessLevel }).subscribe({
            next: () => {
                this.snackbar.success(`${this.data.user.email}'s access level to ${this.data.org.id} updated to '${accessLevel}'.`);
                this.close("ok");
            },
            error: () => this.isSubmitting$.next(false),
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
