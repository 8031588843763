<mat-sidenav-container>
  <mat-sidenav-content>
    <tp-banner/>
    @if (!(hideTopbar$ | async)) {
      <mat-toolbar><tp-topbar (avatarClick)="toggleRightSidenav()"/></mat-toolbar>
    }
    <router-outlet/>
  </mat-sidenav-content>

  <mat-sidenav mode="over" position="end"><tp-right-sidebar (closeClick)="toggleRightSidenav()"/></mat-sidenav>
</mat-sidenav-container>
