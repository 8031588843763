<tp-modal dialogTitle="Change Access Level" [isBusy]="isSubmitting$ | async">
  <p>Change access level for user '{{ this.data.user.email }}' in team '{{ this.data.team.name }}'</p>
  <br/>
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <tp-form-select [form]="form" field="accessLevel" label="Access Level" [options]="accessLevelOptions"/>
    <tp-form-actions
      [submitDisabled]="form.value.accessLevel == data.user.accessLevel"
      [cancellable]="true" submitText="Submit" [isSubmitting]="isSubmitting$ | async"
      buttonIdPrefix="teamSetUserAccessModal" (cancel)="close()"
    />
  </tp-form>
</tp-modal>
