<tp-modal [dialogTitle]="dialogTitle" [isBusy]="!stripe">
  @if (stripe) {
    <div class="pd-stripe-element-container">
      @switch (step) {
        @case ("address") {
          <ngx-stripe-address [stripe]="stripe" [options]="addressOpts" [elementsOptions]="elementsOpts"/>
        }
        @case ("payment") {
          <ngx-stripe-payment [stripe]="stripe" [options]="paymentOpts" [elementsOptions]="elementsOpts" [clientSecret]="$any(elementsOpts).clientSecret"/>
        }
      }
    </div>
    @switch (step) {
      @case ("address") {
        <tp-form-actions
          (cancel)="close()" [isSubmitting]="false" [cancellable]="true" submitText="Continue"
          buttonIdPrefix="paymentDetailsModal_address" (submitClick)="submitAddress()"
        />
      }
      @case ("payment") {
        <p class="terms-privacy-additional-info">
          By adding a payment method, you are accepting the
          <a class="tos-privacy-link" href="https://typedb.com/legal/terms-of-service" target="_blank" rel="noopener">Terms of Service</a>
          and the
          <a class="tos-privacy-link" href="https://typedb.com/legal/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>.
        </p>
        <tp-form-actions
          (cancel)="close()" [isSubmitting]="isSubmitting" [cancellable]="true" submitText="Continue"
          buttonIdPrefix="paymentDetailsModal_payment" (submitClick)="submitPayment()"
        />
      }
    }
  }
</tp-modal>
