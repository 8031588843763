<tp-modal [dialogTitle]="'Scope for ' + data.name">
  <p>These credits are valid for the following cluster configurations:</p>
  <br/>
  @if (scopeString; as stringData) {
    {{ stringData }}
  } @else {
    <tp-properties-table class="dark">
      @for (scopeObject of scopeObjects; track scopeObject.name) {
        <tp-properties-table-row [key]="scopeObject.name">{{ scopeObject.scope }}</tp-properties-table-row>
      }
    </tp-properties-table>
  }
</tp-modal>
