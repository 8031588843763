/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
    FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator,
} from "typedb-platform-framework";
import {
    emailPattern, emailPatternErrorText, idPattern, idPatternErrorText, namePattern, namePatternErrorText
} from "typedb-web-common/lib";
import { User } from "../../../concept/user";
import { EMAIL, RETURN_TO } from "../../../framework/url-params";
import { AnalyticsService } from "../../../service/analytics.service";
import { DialogResult } from "../../../service/dialog.service";
import { UserApi } from "../../../service/user/user-api.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ApplicationState } from "../../../service/application-state.service";
import { Router } from "@angular/router";
import { SnackbarService } from "../../../service/snackbar.service";

export type UserUpdateDialogData = { user: User, token: string };

@Component({
    selector: "tp-user-update-dialog",
    templateUrl: "./user-update-dialog.component.html",
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule,
        FormComponent, MatFormFieldModule, MatInputModule
    ],
})
export class UserUpdateDialogComponent {
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: [this.data.user.id, [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        email: [this.data.user.email, [patternValidator(emailPattern, emailPatternErrorText), requiredValidator]],
        firstName: [this.data.user.firstName, [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
        lastName: [this.data.user.lastName, [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private dialogRef: MatDialogRef<UserUpdateDialogComponent, DialogResult>, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: UserUpdateDialogData, private userApi: UserApi,
        private app: ApplicationState, private router: Router, private snackbar: SnackbarService,
        private analytics: AnalyticsService,
    ) {
    }

    get showEmailChangeInfo(): boolean {
        return this.form.value.email !== this.data.user.email;
    }

    submit() {
        const form = this.form.value;
        const oldEmail = this.data.user.email;
        const [id, newEmail, firstName, lastName] = [form.id!, form.email!, form.firstName!, form.lastName!];
        const token = this.data.token;
        this.userApi.updateUserWithToken({ uuid: this.data.user.uuid, email: newEmail, firstName, lastName, id: id }, token).subscribe({
            next: () => {
                this.close("ok");
                if (oldEmail !== newEmail) {
                    this.analytics.posthog.alias(newEmail, oldEmail);
                    this.analytics.posthog.set({ email: newEmail, previous_email: oldEmail });
                    this.analytics.cio.identify(newEmail, { "previous_email": oldEmail });
                    this.app.signOut({ deauthUser: false, redirect: false, announce: false }).subscribe(() => {
                        this.router.navigate(["/sign-in"], { queryParams: { [RETURN_TO]: this.router.url, [EMAIL]: newEmail } }).then(() => {
                            this.app.unsetCurrentOrg();
                        })
                        this.snackbar.success(`Your details have been updated. Please sign in with the new email, '${newEmail}'.`, { duration: 7000 });
                    });
                } else {
                    this.snackbar.success("Your details have been updated");
                }
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
