<div class="setup-container">
  <div class="form-container wide">
    <p class="box-title">Connect to a Cloud Marketplace</p>
    <p>Integrate with your cloud providers through their marketplaces, to consolidate your cloud usage.</p>
    <tp-marketplace-cards [gcpAccountPresent]="false" [azureAccountPresent]="false" [awsAccountPresent]="false" [canDisconnect]="false" (cardClick)="onCardClick()"/>
    <div class="skip-text">
      <p><a [href]="continueLink">{{ continueText }} <fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></a></p>
    </div>
  </div>
</div>
