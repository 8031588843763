/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsOf<OBJECT> = Pick<OBJECT, { [KEY in keyof OBJECT]: KEY }[keyof OBJECT]>;

export function renderCentsAsUSD(amount: number) {
    return (amount / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
}

export function isBlank(str: string | null | undefined) {
    return str == null || /^\s*$/.test(str);
}

declare global {
    interface Array<T> {
        filterMap<R>(func: (element: T) => R | null | undefined): Array<R>;
        remove<T>(element: T): void;
    }
}

if (!Array.prototype.filterMap) {
    Array.prototype.filterMap = function <T, R>(
        func: (element: T) => R | null | undefined
    ): Array<R> {
        const output: R[] = [];
        for (const element of this) {
            const value = func(element);
            if (value) output.push(value);
        }
        return output;
    };
}

if (!Array.prototype.remove) {
    Array.prototype.remove = function <T>(element: T): void {
        const idx = this.indexOf(element);
        if (idx !== -1) this.splice(idx, 1);
    };
}

export const toSentenceCase = (phrase: string): string => {
    return phrase
        .split(" ")
        .map((word: string) => {
            if (word.length >= 2)
                return (
                    word[0].toUpperCase() +
                    word.substring(1, undefined).toLowerCase()
                );
            else return word[0].toUpperCase();
        })
        .join(" ");
};

export function bytesToString(bytes: Uint8Array): string {
    return new TextDecoder().decode(bytes);
}

export function stringToBytes(str: string): Uint8Array {
    return new TextEncoder().encode(str);
}

export function stringToBytesOrUndefined(str: string | null | undefined): Uint8Array | undefined {
    return str != null ? stringToBytes(str) : undefined;
}

export function stripUndefinedValues(obj: Record<string, unknown>) {
    return Object.keys(obj).reduce((acc, key) => obj[key] === undefined ? {...acc} : {...acc, [key] : obj[key]} , {})
}
