/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { FaIconLibrary } from "@fortawesome/angular-fontawesome";

import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons/faCreditCard";
import { faCheck as faCheckSolid } from "@fortawesome/pro-solid-svg-icons/faCheck";

import { faXmark } from "@fortawesome/pro-light-svg-icons/faXmark";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { faCircleDot } from "@fortawesome/pro-light-svg-icons/faCircleDot";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons/faEllipsis";
import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons/faEllipsisVertical";
import { faFolders } from "@fortawesome/pro-light-svg-icons/faFolders";
import { faHeadset } from "@fortawesome/pro-light-svg-icons/faHeadset";
import { faGear } from "@fortawesome/pro-light-svg-icons/faGear";
import { faMessageDots } from "@fortawesome/pro-light-svg-icons/faMessageDots";
import { faUsers } from "@fortawesome/pro-light-svg-icons/faUsers";
import { faUserGroup } from "@fortawesome/pro-light-svg-icons/faUserGroup";
import { faFiles } from "@fortawesome/pro-light-svg-icons/faFiles";
import { faObjectsColumn } from "@fortawesome/pro-light-svg-icons/faObjectsColumn";
import { faColumns3 } from "@fortawesome/pro-light-svg-icons/faColumns3";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare";
import { faSquarePlus } from "@fortawesome/pro-light-svg-icons/faSquarePlus";
import { faCirclePause } from "@fortawesome/pro-light-svg-icons/faCirclePause";
import { faCirclePlay } from "@fortawesome/pro-light-svg-icons/faCirclePlay";
import { faCircleUp } from "@fortawesome/pro-light-svg-icons/faCircleUp";
import { faTrashCanXmark } from "@fortawesome/pro-light-svg-icons/faTrashCanXmark";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons/faPenToSquare";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faEye } from "@fortawesome/pro-light-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/pro-light-svg-icons/faEyeSlash";
import { faCopy } from "@fortawesome/pro-light-svg-icons/faCopy";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { faSignOut } from "@fortawesome/pro-light-svg-icons/faSignOut";
import { faArrowsRotate } from "@fortawesome/pro-light-svg-icons/faArrowsRotate";
import { faPlay } from "@fortawesome/pro-light-svg-icons/faPlay";
import { faPause } from "@fortawesome/pro-light-svg-icons/faPause";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faDownload } from "@fortawesome/pro-light-svg-icons/faDownload";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons/faArrowRight";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faDash } from "@fortawesome/pro-light-svg-icons/faDash";
import { faHyphen } from "@fortawesome/pro-light-svg-icons/faHyphen";
import { faRefresh } from "@fortawesome/pro-light-svg-icons/faRefresh";
import { faRabbit } from "@fortawesome/pro-light-svg-icons/faRabbit";
import { faPig } from "@fortawesome/pro-light-svg-icons/faPig";
import { faSquirrel } from "@fortawesome/pro-light-svg-icons/faSquirrel";
import { faCat } from "@fortawesome/pro-light-svg-icons/faCat";
import { faMouseField } from "@fortawesome/pro-light-svg-icons/faMouseField";
import { faMonkey } from "@fortawesome/pro-light-svg-icons/faMonkey";
import { faHippo } from "@fortawesome/pro-light-svg-icons/faHippo";
import { faTurtle } from "@fortawesome/pro-light-svg-icons/faTurtle";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
import { faChevronsDown } from "@fortawesome/pro-light-svg-icons/faChevronsDown";

import { faEnvelope as faEnvelopeSharp } from "@fortawesome/sharp-light-svg-icons/faEnvelope";

import { faEnvelope as faEnvelopeThin } from "@fortawesome/pro-thin-svg-icons/faEnvelope";

export function faIconsInitializer(library: FaIconLibrary) {
    library.addIcons(...[
        faObjectsColumn, faFolders, faUsers, faUserGroup, faGear, faHeadset, faMessageDots, faFiles,
        faCircleDot, faColumns3, faChevronUp, faChevronDown, faArrowUpRightFromSquare, faSquarePlus, faCirclePause,
        faCirclePlay, faTrashCanXmark, faPenToSquare, faEnvelope, faChevronLeft, faChevronRight, faEllipsisVertical,
        faEllipsis, faCircle, faPlus, faXmark, faEye, faEyeSlash, faCopy, faCircleCheck, faCircleExclamation,
        faCircleXmark, faCircleInfo, faCircleUp, faUser, faSignOut, faArrowsRotate, faPlay, faPause, faDownload,
        faCreditCard, faArrowRight, faCheck, faDash, faHyphen, faCheckSolid, faEnvelopeSharp, faEnvelopeThin, faRefresh,
        faHippo, faRabbit, faPig, faSquirrel, faCat, faMouseField, faMonkey, faTurtle, faArrowLeft, faChevronsDown
    ] as any[]);
}
