/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { PartialWithId } from "../../../concept/base";
import { Project } from "../../../concept/project";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { projectDetailsPath } from "../../../routing/resource-paths";
import { ProjectApi } from "../../../service/project/project-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { SnackbarService } from "../../../service/snackbar.service";

@Component({
    selector: "tp-project-creation-dialog",
    templateUrl: "./project-creation-dialog.component.html",
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent],
})
export class ProjectCreationDialogComponent {
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: ["", [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private app: ApplicationState, private dialogRef: MatDialogRef<ProjectCreationDialogComponent>,
        private router: Router, private formBuilder: FormBuilder, private projectService: ProjectApi,
        private snackbar: SnackbarService,
    ) {
    }

    submit() {
        const org = this.app.requireCurrentOrg();
        const project: PartialWithId<Project> = { id: this.form.value.id!, name: this.form.value.name! };
        this.projectService.createProject({ project, orgUuid: org.uuid }).subscribe({
            next: () => {
                this.router.navigate([projectDetailsPath(project, org)]);
                this.close();
                this.snackbar.success("Project created successfully");
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close() {
        this.dialogRef.close();
    }
}
