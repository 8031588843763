/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { MachineType, machineTypeToString, StorageType, storageTypeToString } from "../../concept/cluster-options";

@Pipe({
    name: "storageType",
    standalone: true,
})
export class StorageTypePipe implements PipeTransform {
    transform(value: StorageType): string {
        return storageTypeToString(value);
    }
}
