/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe, NgClass, NgOptimizedImage } from "@angular/common";
import { Component } from "@angular/core";
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import {
    ButtonComponent,
    FormActionsComponent,
    FormComponent,
    FormInputComponent,
    FormOption,
    FormOptionGroup,
    FormSelectComponent,
    FormToggleGroupComponent,
    patternValidator,
    requiredValidator,
    SpinnerComponent
} from "typedb-platform-framework";
import { ActivatedRoute, Router } from "@angular/router";
import { RegionPipe } from "../region.pipe";
import {
    ClusterPreset,
    MachineType,
    machineTypeToString,
    Provider,
    ProviderOptions,
    Region,
    RegionOptions,
    regionToString,
    StorageType,
    storageTypeToString
} from "../../../concept/cluster-options";
import { ApplicationState } from "../../../service/application-state.service";
import { ClusterApi } from "../../../service/cluster/cluster-api.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { AbstractControl, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    BehaviorSubject,
    combineLatest,
    concat,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    Observable,
    of,
    ReplaySubject,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    tap
} from "rxjs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MachineTypePipe } from "../machine-type.pipe";
import { Project } from "../../../concept/project";
import { Cluster, storageSizeToString } from "../../../concept/cluster";
import { ProjectApi } from "../../../service/project/project-api.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { AnalyticsService } from "../../../service/analytics.service";
import { mergeListChanges } from "../../../concept/api-response";
import { PROJECT_ID } from "../../../framework/url-params";
import { renderCentsAsUSD } from "../../../util";
import { clusterDetailsPath } from "../../../routing/resource-paths";
import { idPattern, idPatternErrorText } from "typedb-web-common/lib";
import { MatDialog } from "@angular/material/dialog";
import { PaymentDetailsModalComponent } from "../../org/payment-details-modal/payment-details-modal.component";
import { StorageTypePipe } from "../storage-type.pipe";
import { MatInputModule } from "@angular/material/input";
import { generateName } from "./cluster-names";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatStepperModule } from "@angular/material/stepper";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ProviderIdPipe } from "../provider-id.pipe";
import { DialogResult } from "../../../service/dialog.service";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { IconName } from "@fortawesome/fontawesome-svg-core";

export enum ClusterCreationSteps {
    CLOUD = 0,
    CAPACITY = 1,
    CONFIGURATION = 2,
}

const serverCountOptions = [1, 3, 5, 7] as const;
type serverCountOption = typeof serverCountOptions[number];
const storageSliderValueToGBMap: { [key: number]: number } = {
    0: 10,
    1: 25,
    2: 50,
    3: 75,
    4: 100,
    5: 200,
    6: 400,
    7: 600,
    8: 800,
    9: 1000,
};
const gbToStorageSliderValueMap = Object.fromEntries(
    Object.entries(storageSliderValueToGBMap).map(([value, gb]) => [gb, value])
) as unknown as Record<number, number>
const freeOptions: { serverCount: serverCountOption, storageSlider: number } = { serverCount: 1, storageSlider: 0 };

@Component({
    selector: "tp-cluster-deployment-page",
    templateUrl: "./cluster-deployment-page.component.html",
    styleUrls: ["./cluster-deployment-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, PaymentDetailsModalComponent, MatFormFieldModule, MatInputModule, MatSelectModule,
        FormsModule, ReactiveFormsModule, AsyncPipe, ButtonComponent, FormSelectComponent, FormInputComponent,
        FormActionsComponent, SpinnerComponent, FormComponent, FormToggleGroupComponent, NgClass, NgOptimizedImage,
        MatSliderModule, MatTooltipModule, RegionPipe, MachineTypePipe, StorageTypePipe, FontAwesomeModule,
        MatStepperModule, MatButtonModule, MatSlideToggleModule, MatCheckboxModule, ProviderIdPipe
    ],
})
export class ClusterDeploymentPageComponent {
    readonly org = this.app.requireCurrentOrg();
    readonly clusterOptions$ = this.clusterApi.getClusterOptions().pipe(first(), shareReplay(1));
    providers$: Observable<ProviderOptions[]> = this.clusterOptions$.pipe(
        map((options) => options?.providers || [])
    );
    regionGroups: FormOptionGroup<RegionOptions>[] = [];
    machineTypes: FormOption<MachineType>[] = [];
    storageTypes: FormOption<StorageType>[] = [];
    clusterPresets: FormOption<ClusterPreset & { pricePerHourCents: number }>[] = [];
    readonly projects$: Observable<Project[]> = this.orgCtl.listWritableProjectsSnapshot().pipe(shareReplay(1));
    readonly projectOptions$: Observable<FormOption<Project>[]> = this.projects$
        .pipe(map((projects) => projects.map(x => ({ value: x, viewValue: x.id }))));
    readonly typeDBVersions$: Observable<FormOption<string>[]> = this.clusterOptions$.pipe(
        map((options) => (options?.typeDBVersions || [])
            .sort((x, y) => -x.localeCompare(y))
            .map(x => ({ value: x })))
    );
    readonly existingClusters$ = new BehaviorSubject<Cluster[] | null>(null);
    readonly unsubExistingClusters$ = new Subject<void>();
    readonly payableProviders$ = new ReplaySubject<Provider[]>(1);

    readonly isSubmitting$ = new BehaviorSubject(false);
    readonly form = this.formBuilder.group({
        id: ["", [
            patternValidator(idPattern, idPatternErrorText),
            requiredValidator,
            (control: AbstractControl) => this.clusterIDUniqueValidator(control)
        ]],
        project: [null as Project | null, [requiredValidator]],
        provider: [null as ProviderOptions | null, [requiredValidator]],
        region: [null as RegionOptions | null, [requiredValidator]],
        machineType: [null as MachineType | null, [requiredValidator]],
        serverCount: [null as serverCountOption | null, [requiredValidator]],
        storageType: [null as StorageType | null, [requiredValidator]],
        storageSlider: [null as number | null, [requiredValidator]],
        typeDBVersion: ["", [requiredValidator]],
    });
    private readonly billableFields: (keyof typeof this.form.value)[] = ["provider", "region", "machineType", "serverCount", "storageSlider"];
    selectedPreset?: ClusterPreset;

    pricePerHourCents$ = combineLatest(this.billableFields.map(field => concat(of(this.form.value[field]), this.form.get(field)!.valueChanges))).pipe(
        debounceTime(300),
        switchMap(() => this.fetchPrice()),
        shareReplay(1),
    );
    pricePerHourString$ = this.pricePerHourCents$.pipe(map(x => {
        if (x == null) return "--";
        else if (x === "loading") return undefined;
        else return this.pricePerHourToPerHourString(x);
    }));
    pricePer30DaysString$ = this.pricePerHourCents$.pipe(map(x => {
        if (x == null) return "--";
        else if (x === "loading") return undefined;
        else return this.pricePerHourToPer30DaysString(x);
    }));
    providerSectionReady = false;
    readonly isFreeTierSelected$ = new ReplaySubject<boolean>(1);
    memorisedFieldsBeforeChoosingFreeTier = freeOptions;
    serverCountLocked = false;
    storageSliderLocked = false;
    readonly serverCountTooltip$: Observable<string> = this.isFreeTierSelected$.pipe(map((isFreeTierSelected) => {
        return isFreeTierSelected ? "Free clusters are limited to 1 machine" : "";
    }));
    readonly storageSliderTooltip$: Observable<string> = this.isFreeTierSelected$.pipe(map((isFreeTierSelected) => {
        return isFreeTierSelected ? "Free clusters are limited to 10 GB of storage" : "";
    }));
    serverCounts$ = this.isFreeTierSelected$.pipe(
        map((isFreeTierSelected) => {
            if (isFreeTierSelected) return serverCountOptions.map(x => ({ value: x, viewValue: x === 1 ? "1*" : x.toString() }));
            else return serverCountOptions.map(x => ({ value: x }));
        })
    );
    readonly orgAlreadyHasFreeCluster$ = this.orgApi.getFreeClusterCount(this.org.uuid).pipe(
        map(x => x > 0),
        distinctUntilChanged(),
        shareReplay(1),
    );
    readonly cannotDeploySecondFreeCluster$ = combineLatest([this.isFreeTierSelected$, this.orgAlreadyHasFreeCluster$]).pipe(map(([x, y]) => x && y));
    readonly hasUsablePaymentMethod$ = combineLatest([this.payableProviders$, this.form.valueChanges]).pipe(
        filter(() => !!this.form.value.provider),
        map(([payable]) => payable.map(x => x.id).includes(this.form.value.provider!.id))
    );
    readonly mustAddPaymentMethod$ = combineLatest([this.isFreeTierSelected$, this.hasUsablePaymentMethod$]).pipe(
        map(([isFreeTierSelected, hasUsablePaymentMethod]) => !isFreeTierSelected && !hasUsablePaymentMethod),
        shareReplay(1),
    );
    readonly showAskAdminForPaymentMethodPrompt$ = combineLatest([this.mustAddPaymentMethod$, this.app.hasOrgAdminAccess$]).pipe(
        map(([mustAddPaymentMethod, isAdmin]) => mustAddPaymentMethod && !isAdmin),
    );

    currentStep: ClusterCreationSteps = ClusterCreationSteps.CLOUD;
    advancedConfig: boolean = false;
    checkoutShowMore: boolean = false;
    idGenerateCount = 0;
    animalIconOptions: IconName[] = ["hippo", "rabbit", "pig", "squirrel", "cat", "mouse-field", "monkey", "turtle"];
    animalIcon: IconName = this.animalIconOptions[0];

    constructor(
        private app: ApplicationState, private formBuilder: FormBuilder, private route: ActivatedRoute,
        private clusterApi: ClusterApi, private projectApi: ProjectApi, private orgApi: OrgApi,
        private router: Router, private snackbar: SnackbarService, private dialog: MatDialog,
        private orgCtl: OrgController, private analytics: AnalyticsService,
    ) {}

    ngOnInit() {
        this.initExistingClustersLoader();
        this.fetchPayableProviders(false);
        this.restrictSpecsWhenOnFreeTier();
        this.autoUpdateRegionAndMachines();
        this.prepopulateProject();
        this.prepopulateProviderAndTypeDBVersion();
        this.generateAndFillID();
    }

    private initExistingClustersLoader() {
        this.form.controls.project.valueChanges.pipe(
            tap(() => this.unsubExistingClusters$.next()),
            switchMap((project) => this.projectApi.listClusters(
                project!.uuid,
                { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } },
                this.unsubExistingClusters$
            )),
        ).subscribe((res) => {
            const mergeResult = mergeListChanges(this.existingClusters$.value, res);
            if (mergeResult.result === "shouldResync") {
                console.warn(`Received update is not valid in the current state; forcing a full resync`);
                this.initExistingClustersLoader();
                return;
            }
            this.existingClusters$.next(mergeResult.data);
            this.form.controls.id.updateValueAndValidity();
        });
    }

    fetchPayableProviders(manualTrigger: boolean) {
        const org = this.org;
        if (manualTrigger) this.isSubmitting$.next(true);
        this.orgApi.getPayableProviders(org.uuid).subscribe((payableProviders) => {
            this.payableProviders$.next(payableProviders);
            this.isSubmitting$.next(false);
            if (manualTrigger) {
                const selectedProvider = this.form.value.provider!;
                if (payableProviders.map(x => x.id).includes(selectedProvider.id)) {
                    this.snackbar.success("Payment method validated successfully");
                } else {
                    this.snackbar.warnPersistent(`No valid payment method found. Please contact an organization administrator.`);
                }
            }
        });
    }

    private autoUpdateRegionAndMachines() {
        // TODO: should use the same logic as setup/project. This would require a data migration as setup/project
        //       expects a different data layout
        this.form.controls.provider.valueChanges.subscribe((provider) => {
            const continentNames = [...new Set(provider!.regions
                .sort((x, y) => x.continentOrdinal - y.continentOrdinal)
                .map(x => x.continentName)
            )];
            const regionMap = Object.fromEntries(continentNames.map(x => [x, [] as FormOption<RegionOptions>[]]));
            provider!.regions.sort((a, b) => a.vendorId.localeCompare(b.vendorId)).forEach(region => {
                const formOption = this.regionOptionOf(region);
                regionMap[region.continentName].push(formOption);
            });
            this.regionGroups = Object.entries(regionMap).map(([name, options]) => ({ name, options }));
            this.form.patchValue({ region: this.regionGroups
                .flatMap(x => x.options)
                .find(x => x.value.isFreeAvailable)?.value
                    || this.regionGroups[0].options[0].value
            });
        });

        combineLatest([
            this.orgAlreadyHasFreeCluster$, this.form.controls.region.valueChanges
        ]).subscribe(([alreadyHasFree, r]) => {
            const region = r!;
            this.machineTypes = region.machineTypes
                .filter((machineType) => !machineType.isFree || !alreadyHasFree)
                .sort(this.sortMachineType)
                .map(this.machineTypeOptionOf);
            this.storageTypes = region.storageTypes
                .sort((a, b) => a.vendorId.localeCompare(b.vendorId))
                .map(this.storageTypeOptionOf);
            const sortedPresets = region.clusterPresets
                .sort((a, b) => this.sortMachineType(a.machineType, b.machineType));

            combineLatest(sortedPresets.map((preset) =>
                this.clusterApi.getClusterPresetPriceCentsPerHour({ regionId: region.id, presetId: preset.id, orgUuid: this.org.uuid })
                    .pipe(map((price) => this.clusterPresetOptionOf(preset, price)))
            )).subscribe((presets) => {
                this.clusterPresets = presets;
                const equivalentPreset = sortedPresets.find((x) => x.name == this.selectedPreset?.name);
                if (equivalentPreset) {
                    this.selectPreset(equivalentPreset);
                } else if (this.form.controls.machineType.touched || this.currentStep >= ClusterCreationSteps.CAPACITY) {
                    this.selectPreset(sortedPresets[0]);
                }
                this.advancedConfig = false;
            });
        });
    }

    private sortMachineType(a: MachineType, b: MachineType) {
        return (a.cpu - b.cpu) || (a.vendorId.localeCompare(b.vendorId)) || (Number(b.isFree) - Number(a.isFree));
    }

    private prepopulateProviderAndTypeDBVersion() {
        combineLatest([this.providers$, this.payableProviders$]).pipe(first()).subscribe(([providers, payable]) => {
            const preferredProvider = providers.find(x => x.isFreeAvailable && payable.map(y => y.id).includes(x.id))
                || providers.find(x => payable.map(y => y.id).includes(x.id))
                || providers.find(x => x.isFreeAvailable)
                || providers[0];
            this.form.patchValue({ provider: preferredProvider });
            this.providerSectionReady = true;
        });
        this.typeDBVersions$.subscribe((versions) => {
            this.form.patchValue({ typeDBVersion: versions[0].value });
        });
    }

    private prepopulateProject() {
        combineLatest([this.route.queryParamMap.pipe(first()), this.projects$.pipe(first())]).subscribe(([params, projects]) => {
            const requestedProjectID = params.get(PROJECT_ID);
            if (requestedProjectID != null) {
                const project = projects.find(x => x.id === requestedProjectID);
                if (project) {
                    this.form.patchValue({ project });
                    return;
                } else {
                    this.snackbar.warn(`Project with ID '${requestedProjectID}' not found`);
                }
            }
            if (projects.length) this.form.patchValue({ project: projects.find(x => x.id === "default") || projects[0] })
            else this.snackbar.warn(`This organization has no projects. Please create a project first.`);
        });
    }

    private restrictSpecsWhenOnFreeTier() {
        this.form.controls.machineType.valueChanges.pipe(
            filter((machineType) => !!machineType),
            map((machineType) => machineType!.isFree)
        ).subscribe((isFree) => {
            this.isFreeTierSelected$.next(isFree);
        });

        this.isFreeTierSelected$.pipe(
            distinctUntilChanged(),
        ).subscribe((isFreeTierSelected) => {
            if (isFreeTierSelected) {
                if (this.form.value.serverCount && this.form.value.storageSlider) {
                    this.memorisedFieldsBeforeChoosingFreeTier = {
                        serverCount: this.form.value.serverCount,
                        storageSlider: this.form.value.storageSlider,
                    };
                }
                this.form.patchValue(freeOptions);
                this.serverCountLocked = true;
                this.storageSliderLocked = true;
            } else {
                this.serverCountLocked = false;
                this.storageSliderLocked = false;
                this.form.patchValue({
                    serverCount: this.memorisedFieldsBeforeChoosingFreeTier.serverCount,
                    storageSlider: this.memorisedFieldsBeforeChoosingFreeTier.storageSlider,
                });
            }
        });
    }

    private clusterIDUniqueValidator(control: AbstractControl<string>) {
        if (!this.existingClusters$.value) return null;
        if (this.existingClusters$.value.some(x => x.id === control.value)) {
            return { errorText: `The cluster name '${control.value}' is already in use in this project` };
        } else return null;
    }

    regionOptionOf(region: RegionOptions): FormOption<RegionOptions> {
        return { value: region, viewValue: regionToString(region) };
    }

    machineTypeOptionOf(machine: MachineType): FormOption<MachineType> {
        return { value: machine, viewValue: machineTypeToString(machine) };
    }

    storageTypeOptionOf(storage: StorageType): FormOption<StorageType> {
        return { value: storage, viewValue: storageTypeToString(storage) };
    }

    clusterPresetOptionOf(preset: ClusterPreset, pricePerHourCents: number): FormOption<ClusterPreset & { pricePerHourCents: number }> {
        return { value: { ...preset, pricePerHourCents }, viewValue: preset.name };
    }

    providerCardClasses(provider: ProviderOptions): { [clazz: string]: boolean } {
        return {
            "card-selected": this.form.value.provider?.id === provider.id,
            [`provider-card-${provider.id}`]: true,
            // "provider-card-free-available": provider.isFreeAvailable, // TODO: bring back once something isn't free-available
        };
    }

    presetCardClasses(preset: ClusterPreset): { [clazz: string]: boolean } {
        return {
            "card-selected": this.selectedPreset?.id == preset.id && !this.advancedConfig,
            [`preset-card-${preset.id.split("-")[0]}`]: true,
        };
    }

    providerImageUrl(provider: Provider): string {
        return `/assets/logo/${provider.id}.svg`;
    }

    regionFlagUrl(region: Region): string {
        return `/assets/flags/${region.countryCode.toLowerCase()}.svg`;
    }

    selectProvider(provider: ProviderOptions) {
        this.form.patchValue({ provider, machineType: null, storageType: null });
    }

    selectPreset(preset: ClusterPreset) {
        this.selectedPreset = preset;
        this.orgAlreadyHasFreeCluster$.pipe(first()).subscribe((orgAlreadyHasFreeCluster) => this.updateFormFromPresetValues(preset, orgAlreadyHasFreeCluster));
    }

    updateFormFromPresetValues(preset: ClusterPreset, orgAlreadyHasFreeCluster: boolean) {
        const machineTypes = this.machineTypes.filter(x => x.value.vendorId == preset.machineType.vendorId);
        const machineType = orgAlreadyHasFreeCluster ? machineTypes[0] : machineTypes.find(x => x.value.isFree == true) || machineTypes[0];
        this.form.patchValue({
            machineType: machineType.value,
            storageType: this.storageTypes.find(x => x.value.vendorId == preset.storageType.vendorId)?.value,
            storageSlider: this.storageSliderGBToValue(preset.storageGB),
            serverCount: preset.serverCount as typeof serverCountOptions[number],
        });
    }

    storageSliderGBToValue(gb: number): number {
        return gbToStorageSliderValueMap[gb];
    }

    storageSliderValueToGB(rawValue: number): number {
        return storageSliderValueToGBMap[rawValue];
    }

    private fetchPrice(): Observable<number | "loading" | null> {
        const values = this.form.value;
        if (this.billableFields.some(x => values[x] == null)) return of(null);
        const cluster = this.clusterFromFormValues();

        return this.isFreeTierSelected$.pipe(
            first(),
            switchMap((isFreeTier) => {
                if (isFreeTier) return of(0);
                else return this.clusterApi.getClusterPriceCentsPerHour({
                    cluster: cluster,
                    orgUuid: this.org.uuid,
                }).pipe(startWith<number | "loading">("loading"));
            }),
        );
    }

    pricePerHourToPerHourString(pricePerHourCents: number): string {
        if (pricePerHourCents === 0) return `Free`;
        else return `${renderCentsAsUSD(pricePerHourCents)}`;
    }

    pricePerHourToPer30DaysString(pricePerHourCents: number): string {
        if (pricePerHourCents === 0) return `Free`;
        else return `${renderCentsAsUSD(pricePerHourCents * 720)}`;
    }

    clusterFromFormValues() {
        const form = this.form.value;
        const id = form.id!;
        const project = form.project!;
        const [provider, region] = [form.provider!, form.region!];
        const [machineType, serverCount, storageType, storageGB]
            = [form.machineType!, form.serverCount!, form.storageType!, this.storageSliderValueToGB(form.storageSlider!)]
        const typeDBVersion = form.typeDBVersion!;

        return { id, project, provider, region, machineType, serverCount, storageType, storageGB, typeDBVersion };
    }

    submit() {
        const cluster = this.clusterFromFormValues();
        this.clusterApi.deployCluster(cluster).subscribe({
            next: () => {
                this.analytics.google.reportAdConversion(cluster.machineType.isFree ? "deployFreeCluster" : "deployPaidCluster");
                this.router.navigate([clusterDetailsPath(cluster, this.org)]);
                this.snackbar.success(`Your cluster is starting up and will be ready soon. You can monitor its status from this page.`, { duration: 10000 });
            },
            error: () => {
                this.isSubmitting$.next(false);
            }
        });
    }

    cancel() {
        this.router.navigate([".."], { relativeTo: this.route });
    }

    advance() {
        this.currentStep++;
    }

    return() {
        this.currentStep--;
    }

    isIdValid(id: string) {
        return !this.form.controls.id.validator?.call(this, new FormControl(id));
    }

    generateAndFillID() {
        this.idGenerateCount++;
        if (this.idGenerateCount % 10 == 0) {
            const animalIndex = Math.floor(Math.random() * this.animalIconOptions.length);
            this.animalIcon = this.animalIconOptions[animalIndex];
        }
        this.form.patchValue({ id: generateName((id) => this.isIdValid(id))});
    }

    toggleAdvanced() {
        this.advancedConfig = !this.advancedConfig
        if (!this.advancedConfig && this.selectedPreset) this.selectPreset(this.selectedPreset);
    }

    onStepChange(stepChange: StepperSelectionEvent) {
        if (stepChange.selectedIndex >= ClusterCreationSteps.CAPACITY && !this.advancedConfig && !this.selectedPreset && this.clusterPresets.length > 0) {
            this.selectPreset(this.clusterPresets[0].value);
        }
        this.idGenerateCount = 0;
    }

    get machineTypeFieldTitle(): string {
        return this.machineTypes.find(x => x.value === this.form.value.machineType)?.viewValue ?? "Select an option";
    }

    get storageSliderIndicatorText(): string {
        let storageString = this.storageSliderCurrentValueToString;
        if (this.form.value.machineType?.isFree) storageString += "*";
        return storageString;
    }

    get storageSliderCurrentValueToString(): string {
        if (this.form.value.storageSlider != null) return this.storageSliderValueToString(this.form.value.storageSlider!);
        else return "--";
    }

    storageSliderValueToString(rawValue: number): string {
        const gb = storageSliderValueToGBMap[rawValue];
        return storageSizeToString(gb);
    }

    openPaymentDetailsDialog() {
        this.dialog.open<PaymentDetailsModalComponent, any, DialogResult>(PaymentDetailsModalComponent).afterClosed().subscribe(result => {
            if (result === "ok") {
                this.fetchPayableProviders(true);
                this.snackbar.success("Payment method added successfully");
            }
            else if (result == null || result === "cancelled") return;
            else this.snackbar.errorPersistent(`Error setting up payment method: ${result.error}`);
        });
    }

    protected readonly storageSizeToString = storageSizeToString;
    protected readonly ClusterCreationSteps = ClusterCreationSteps;
}
