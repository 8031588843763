<div>
  <p class="box-title">Forgot your password?</p>
  <p class="box-text">
    Enter your email address and we will send an email containing a link to reset your password
  </p>
  <tp-form [isSubmitting$]="isSubmitting$" [formGroup]="form" (doSubmit)="onSubmit()">
    <tp-form-input [form]="form" field="email" label="Email"/>
    <tp-form-actions [isSubmitting]="isSubmitting$ | async" [submitDisabled]="!form.dirty" submitText="Reset password" buttonIdPrefix="forgotPassword"/>
  </tp-form>

  <a routerLink="/">
    <tp-button style="margin-top: 24px" type="button" [height]="20" buttonStyle="secondary" buttonId="backToSignIn">Sign in</tp-button>
  </a>
</div>
@if (resetPasswordSuccess) {
  <div class="check-email-notification">
    <div class="check-email-text">
      <fa-icon [icon]="['fal', 'envelope']"/>
      Check your email for a link to reset your password
    </div>
    <div>
      <span class="box-text">Have you not received your email?</span>
      &nbsp;
      <a class="box-text-clickable" href="javascript:void(0);" (click)="requestPasswordResetEmail()">
        Send email again.
      </a>
    </div>
  </div>
}
