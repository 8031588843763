/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { AccessLevel, accessLevelToString } from "../concept/iam";

@Pipe({
    name: "accessLevel",
    standalone: true,
})
export class AccessLevelPipe implements PipeTransform {
    transform(value: AccessLevel | null | undefined): string {
        return value ? accessLevelToString(value) : "";
    }
}
