/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    UserCtlProtoAcceptInvitationReq, UserCtlProtoCheckUserIDReq, UserCtlProtoCompleteOnboardingSurveyReq, UserCtlProtoCreateUserReq, UserCtlProtoGetAccessLevelsReq, UserCtlProtoGetUserReq, UserCtlProtoLeaveOrganizationReq,
    UserCtlProtoListInvitationsReq, UserCtlProtoListOrgsReq, UserCtlProtoListProjectsReq, UserCtlProtoListTeamsReq,
    UserCtlProtoRejectInvitationReq, UserCtlProtoReq, UserCtlProtoSendVerifyEmailReq, UserCtlProtoSetPropertyReq, UserCtlProtoSignUpReq, UserCtlProtoUnsetPropertyReq,
    UserCtlProtoUpdateUserReq, UserCtlProtoUpdateVerificationStatusReq,
} from "../../../application/protocol/user-controller";
import { Ok, PartialWithUuid } from "../../concept/base";
import { Parameters, parametersProtoOf } from "../../concept/common";
import { AccessLevel, accessLevelOf } from "../../concept/iam";
import { Invitation, UserOrg, partialInvitationOf, partialUserOrgOf } from "../../concept/organization";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { UserTeam, partialUserTeamOf } from "../../concept/team";
import { partialUserOf, User, userProtoOf } from "../../concept/user";
import { stringToBytes } from "../../util";
import { UnsubListener } from "../backend/platform-api-backend.service";
import { ApiService } from "../backend/api.service";
import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { partialUserProjectOf, UserProject } from "../../concept/project";

@Injectable({
    providedIn: "root",
})
export class UserApi {
    constructor(private api: ApiService) {}

    getAccessLevels(userUuids: string[]): Observable<AccessLevel[]> {
        return this.api.userReqRes(new UserCtlProtoReq({ getAccessLevels: new UserCtlProtoGetAccessLevelsReq({ userUuids: userUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    signUp(email: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            signUp: new UserCtlProtoSignUpReq({ email }),
        }));
    }

    createUser(user: Partial<User>): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            createUser: new UserCtlProtoCreateUserReq({ user: userProtoOf(user) })
        }));
    }

    checkUserId(userId: string): Observable<{ exists: boolean }> {
        return this.api.userReqRes(new UserCtlProtoReq({
            checkUserId: new UserCtlProtoCheckUserIDReq({ id: userId })
        })).pipe(map((res) => ({ exists: res.checkUserId.exists })));
    }

    getUser(userId: string, unsub$: UnsubListener): Observable<ApiResponse<User>> {
        return this.api.userReqSub(new UserCtlProtoReq(
            { getUser: new UserCtlProtoGetUserReq({ userId: userId }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialUserOf(res.data.getUser.user)))
        );
    }

    updateUser(user: PartialWithUuid<User>): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ updateUser: new UserCtlProtoUpdateUserReq({ user: userProtoOf(user) }) }));
    }

    updateUserWithToken(user: PartialWithUuid<User>, token: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ updateUser: new UserCtlProtoUpdateUserReq({ user: userProtoOf(user), token }) }));
    }

    listOrgs(params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<UserOrg>> {
        return this.api.userReqSub(new UserCtlProtoReq(
            { listOrgs: new UserCtlProtoListOrgsReq({ params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listOrgs.orgs.map(partialUserOrgOf))));
    }

    listProjects(props: { userUuid: string, orgUuid: string }, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<UserProject>> {
        return this.api.userReqSub(new UserCtlProtoReq({ listProjects: new UserCtlProtoListProjectsReq({
                userUuid: stringToBytes(props.userUuid), orgUuid: stringToBytes(props.orgUuid), params: parametersProtoOf(params)
        }) }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listProjects.projects.map(partialUserProjectOf))));
    }

    listTeams(props: { userUuid: string, orgUuid: string }, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<UserTeam>> {
        return this.api.userReqSub(new UserCtlProtoReq({ listTeams: new UserCtlProtoListTeamsReq({
                userUuid: stringToBytes(props.userUuid), orgUuid: stringToBytes(props.orgUuid), params: parametersProtoOf(params) }
        ) }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listTeams.teams.map(partialUserTeamOf))));
    }

    listInvitations(userUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Invitation>> {
        return this.api.userReqSub(new UserCtlProtoReq(
            { listInvitations: new UserCtlProtoListInvitationsReq({ userUuid: stringToBytes(userUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listInvitations.invitations.map(partialInvitationOf))));
    }

    acceptInvitation(invitationUuid: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            acceptInvitation: new UserCtlProtoAcceptInvitationReq({ invitationUuid: stringToBytes(invitationUuid) })
        }));
    }

    rejectInvitation(invitationUuid: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            rejectInvitation: new UserCtlProtoRejectInvitationReq({ invitationUuid: stringToBytes(invitationUuid) })
        }));
    }

    sendVerifyEmail(isAsync: boolean): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            sendVerifyEmail: new UserCtlProtoSendVerifyEmailReq({ isAsync })
        }));
    }

    updateVerificationStatus(token: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            updateVerificationStatus: new UserCtlProtoUpdateVerificationStatusReq({ token })
        }));
    }

    leaveOrg(orgUuid: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({
            leaveOrg: new UserCtlProtoLeaveOrganizationReq({ orgUuid: stringToBytes(orgUuid) })
        }));
    }

    setProperty(key: string, value: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ setProperty: new UserCtlProtoSetPropertyReq({ key, value }) }));
    }

    unsetProperty(key: string): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ unsetProperty: new UserCtlProtoUnsetPropertyReq({ key }) }));
    }

    completeOnboardingSurvey(): Observable<Ok> {
        return this.api.userReq(new UserCtlProtoReq({ completeOnboardingSurvey: new UserCtlProtoCompleteOnboardingSurveyReq() }));
    }
}
