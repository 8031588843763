/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, last, scan } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, rangeValidator } from "typedb-platform-framework";
import { Cluster, Server } from "../../../../concept/cluster";
import { ClusterApi } from "../../../../service/cluster/cluster-api.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DialogResult } from "../../../../service/dialog.service";
import { SnackbarService } from "../../../../service/snackbar.service";

export type DownloadClusterServerLogsData = { cluster: Cluster, server: Server };

@Component({
    selector: "tp-download-cluster-server-logs-dialog",
    templateUrl: "./download-cluster-server-logs-dialog.component.html",
    styleUrls: ["./download-cluster-server-logs-dialog.component.scss"],
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule,
        FormComponent, MatFormFieldModule, MatInputModule
    ],
})
export class DownloadClusterServerLogsDialogComponent {
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        limit: [10_000, [rangeValidator(1, 10_000_000)]],
    });

    constructor(
        private dialogRef: MatDialogRef<DownloadClusterServerLogsDialogComponent, DialogResult>,
        @Inject(MAT_DIALOG_DATA) private data: DownloadClusterServerLogsData, private formBuilder: FormBuilder,
        private clusterApi: ClusterApi, private snackbar: SnackbarService,
    ) {
    }

    submit() {
        this.clusterApi.listServerLogsSnapshot({
            clusterUuid: this.data.cluster.uuid,
            serverUuid: this.data.server.uuid,
            serverId: this.data.server.id,
            maxLines: this.form.value.limit!
        }).pipe(
            scan(((current, next) => {
                next.lines.forEach((line, idx) => current[next.lineNumberStart + idx] = line);
                return current;
            }), [] as string[]),
            last(),
        ).subscribe({
            next: (logs) => {
                const blob = new Blob([logs.join("\n")], { type: "text/plain" });
                const anchor = document.createElement("a");
                anchor.href = URL.createObjectURL(blob);
                const timeString = new Date().toISOString().split(".")[0].replace(":", "");
                anchor.download = `logs-${this.data.cluster.id}-${this.data.server.id}-${timeString}`;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                this.close("ok");
            },
            error: (err) => {
                console.error(err);
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
