/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { PartialWithId } from "../../../concept/base";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { projectDetailsPath } from "../../../routing/resource-paths";
import { ApplicationState } from "../../../service/application-state.service";
import { Router } from "@angular/router";
import { DialogResult } from "../../../service/dialog.service";
import { ProjectApi } from "../../../service/project/project-api.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { Project } from "../../../concept/project";

export type ProjectUpdateDialogData = { project: Project };

@Component({
    selector: "tp-project-update-dialog",
    templateUrl: "./project-update-dialog.component.html",
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule,
        FormComponent, MatFormFieldModule, MatInputModule
    ],
})
export class ProjectUpdateDialogComponent {
    readonly org = this.app.requireCurrentOrg();
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: [this.data.project.id, [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: [this.data.project.name, [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private dialogRef: MatDialogRef<ProjectUpdateDialogComponent, DialogResult>, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: ProjectUpdateDialogData, private snackbar: SnackbarService,
        private projectApi: ProjectApi, private app: ApplicationState, private router: Router,
    ) {
    }

    submit() {
        const form = this.form.value;
        const project: PartialWithId<Project> = { id: form.id!, name: form.name };
        this.projectApi.updateProject({
            uuid: this.data.project.uuid,
            id: project.id !== this.data.project.id ? project.id : undefined,
            name: project.name !== this.data.project.name ? project.name : undefined
        }).subscribe({
            next: () => {
                this.close("ok");
                if (project.id !== this.data.project.id) {
                    this.router.navigate([projectDetailsPath(project, this.org)], { replaceUrl: true });
                }
                this.snackbar.success(`Project updated successfully`);
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
