/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
    TeamCtlProtoAddMemberReq, TeamCtlProtoCreateTeamReq, TeamCtlProtoDeleteTeamReq, TeamCtlProtoGetAccessLevelsReq,
    TeamCtlProtoGetTeamReq, TeamCtlProtoListMembersReq, TeamCtlProtoListProjectsReq,
    TeamCtlProtoRemoveMemberReq, TeamCtlProtoReq, TeamCtlProtoSetMemberAccessLevelReq, TeamCtlProtoUpdateTeamReq
} from "../../../application/protocol/team-controller";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { Ok, PartialWithUuid } from "../../concept/base";
import { Parameters, parametersProtoOf } from "../../concept/common";
import { AccessLevel, accessLevelOf, accessLevelProtoOf } from "../../concept/iam";
import { partialTeamOf, Team, teamProtoOf } from "../../concept/team";
import { partialTeamMemberOf, TeamMember } from "../../concept/user";
import { stringToBytes } from "../../util";
import { ApiService } from "../backend/api.service";
import { UnsubListener } from "../backend/platform-api-backend.service";
import { partialTeamProjectOf, TeamProject } from "../../concept/project";

@Injectable({
    providedIn: "root",
})
export class TeamApi {
    constructor(private api: ApiService) {
    }

    getAccessLevels(teamUuids: string[]): Observable<AccessLevel[]> {
        return this.api.teamReqRes(new TeamCtlProtoReq({ getAccessLevels: new TeamCtlProtoGetAccessLevelsReq({ teamUuids: teamUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    createTeam(props: { team: Partial<Team>, orgUuid: string }): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            createTeam: new TeamCtlProtoCreateTeamReq({
                team: teamProtoOf(props.team),
                orgUuid: stringToBytes(props.orgUuid)
            })
        }));
    }

    getTeam(props: { teamId: string, orgUuid: string }, unsub$: UnsubListener): Observable<ApiResponse<Team>> {
        return this.api.teamReqSub(new TeamCtlProtoReq(
            { getTeam: new TeamCtlProtoGetTeamReq({ teamId: props.teamId, orgUuid: stringToBytes(props.orgUuid) }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialTeamOf(res.data.getTeam.team)))
        );
    }

    updateTeam(team: PartialWithUuid<Team>): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({ updateTeam: new TeamCtlProtoUpdateTeamReq({ team: teamProtoOf(team) }) }));
    }

    deleteTeam(uuid: string): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({ deleteTeam: new TeamCtlProtoDeleteTeamReq({ teamUuid: stringToBytes(uuid) }) }));
    }

    listProjects(teamUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<TeamProject>> {
        return this.api.teamReqSub(new TeamCtlProtoReq({
            listProjects: new TeamCtlProtoListProjectsReq({ teamUuid: stringToBytes(teamUuid), params: parametersProtoOf(params) })
        }), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listProjects.projects.map(partialTeamProjectOf))));
    }

    listMembers(teamUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<TeamMember>> {
        return this.api.teamReqSub(new TeamCtlProtoReq(
            { listMembers: new TeamCtlProtoListMembersReq({ teamUuid: stringToBytes(teamUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listMembers.members.map(partialTeamMemberOf))));
    }

    addMember(props: { teamUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            addMember: new TeamCtlProtoAddMemberReq({
                teamUuid: stringToBytes(props.teamUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    removeMember(props: { teamUuid: string, userUuid: string }): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            removeMember: new TeamCtlProtoRemoveMemberReq(
                { teamUuid: stringToBytes(props.teamUuid), userUuid: stringToBytes(props.userUuid) }
            )
        }));
    }

    setMemberAccessLevel(props: { teamUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.teamReq(new TeamCtlProtoReq({
            setMemberAccessLevel: new TeamCtlProtoSetMemberAccessLevelReq({
                teamUuid: stringToBytes(props.teamUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }
}
