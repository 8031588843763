/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { bytesToString, stringToBytes, stripUndefinedValues } from "../util";
import { BaseResource, PartialWithUuid, Property } from "./base";
import { AccessLevelProto, UserTeamProto, ProjectTeamAssignmentProto, TeamProto, } from "../../protocol/concept";
import { AccessLevel, accessLevelOf } from "./iam";

export interface Team extends BaseResource {
    name: string;
    createdAt: Date;
}

export interface ProjectTeamAssignment extends Team {
    accessLevel: AccessLevel;
}

export interface UserTeam extends Team {
    accessLevel: AccessLevel;
}

export function partialTeamOf(data: TeamProto): PartialWithUuid<Team> {
    const team: PartialWithUuid<Team> = {
        uuid: bytesToString(data.uuid),
        id: data.hasId ? data.id : undefined,
        name: data.hasName ? data.name : undefined,
        createdAt: data.hasCreatedAt ? new Date(data.createdAt) : undefined,
    };
    return stripUndefinedValues(team) as PartialWithUuid<Team>;
}

function partialTeamWithAccessLevelOf(data: { team: TeamProto, accessLevel: AccessLevelProto, hasAccessLevel: boolean }) {
    const userWithAccessLevel = {
        ...partialTeamOf(data.team),
        accessLevel: data.hasAccessLevel ? accessLevelOf(data.accessLevel) : undefined,
    };
    return stripUndefinedValues(userWithAccessLevel);
}

export function partialProjectTeamAssignmentOf(data: ProjectTeamAssignmentProto): PartialWithUuid<ProjectTeamAssignment> {
    return partialTeamWithAccessLevelOf(data) as PartialWithUuid<ProjectTeamAssignment>;
}

export function partialUserTeamOf(data: UserTeamProto): PartialWithUuid<UserTeam> {
    return partialTeamWithAccessLevelOf(data) as PartialWithUuid<UserTeam>;
}

export function teamProtoOf(team: Partial<Team>): TeamProto {
    return new TeamProto({
        uuid: team.uuid ? stringToBytes(team.uuid) : undefined,
        id: team.id,
        name: team.name,
        createdAt: team.createdAt?.getTime(),
    });
}

type BaseTeamColumn = "id" | "name" | "createdAt";
export type TeamColumn = BaseTeamColumn | "accessLevel";

export const teamProperties: Record<BaseTeamColumn, Property> = {
    id: { id: "id", name: "Team ID", attributeType: "id" },
    name: { id: "name", name: "Team Name", attributeType: "name" },
    createdAt: { id: "createdAt", name: "Creation Date", attributeType: "created-at" },
};

export const projectTeamAssignmentProperties: Record<TeamColumn, Property> = {
    ...teamProperties,
    accessLevel: { id: "accessLevel", name: "Project Access Level", ownerType: "assignment", attributeType: "access-level" },
}

export const memberTeamProperties: Record<TeamColumn, Property> = {
    ...teamProperties,
    accessLevel: { id: "accessLevel", name: "Access Level", ownerType: "membership", attributeType: "access-level" },
}

export const teamPropertiesList = [teamProperties.id, teamProperties.name, teamProperties.createdAt];
export const memberTeamPropertiesList = [...teamPropertiesList, memberTeamProperties.accessLevel];
export const projectTeamAssignmentPropertiesList = [...teamPropertiesList, projectTeamAssignmentProperties.accessLevel];
