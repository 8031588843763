/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { BehaviorSubject, concat, filter, first, map, Observable, Subject, switchMap, takeUntil, tap, take, finalize, combineLatest } from "rxjs";
import { v4 as uuid } from "uuid";
import {
    ApplicationProtoPrivateReq, ApplicationProtoPrivateRes, ApplicationProtoPrivateUnsubscribeReq,
    ApplicationProtoPublicReq, ApplicationProtoPublicRes, ApplicationProtoReq, ApplicationProtoRes,
} from "../../../application/protocol/application";
import { ok, Ok } from "../../concept/base";

import { environment } from "../../environments/environment";
import { SnackbarComponent } from "typedb-platform-framework";
import { bytesToString, stringToBytes } from "../../util";
import { SnackbarService } from "../snackbar.service";
import { reqToJson, resToJson } from "./debug";
import { ServiceError, UNAUTHENTICATED_REQUEST_CODE, WS_CLOSE_REASON_API_VERSION_MISMATCH } from "../error";
import { version } from "../../version"

const API_VERSION = version.VALUE;
const RECONNECT_BASE_DELAY_MS = 2_000;

export interface AbstractApiBackend {
    publicReqRes(req: ApplicationProtoPublicReq): Observable<ApplicationProtoPublicRes>;
    privateReq(req: ApplicationProtoPrivateReq): Observable<Ok>;
    privateReqRes(req: ApplicationProtoPrivateReq): Observable<ApplicationProtoPrivateRes>;
    privateReqSub(req: ApplicationProtoPrivateReq, unsub$: UnsubListener): Observable<ApplicationProtoPrivateRes>;
    onUnauthenticatedRequest$: Observable<void>;
    onReconnect$: Observable<void>;
}

@Injectable({
    providedIn: "root",
})
export class PlatformApiBackend implements AbstractApiBackend {
    private wsChannel!: WebSocket;
    private readonly url = new URL(`${environment.sessionWebsocketUrl()}?version=${API_VERSION}`);
    private readonly isConnected$ = new BehaviorSubject(false);
    readonly isAuthenticated$ = new BehaviorSubject(false);
    private receivedProto$ = new Subject<ApplicationProtoRes>();

    private connectionFailCounter = 0;
    private reconnectingSnackbarRef: MatSnackBarRef<SnackbarComponent> | null = null;

    private _onUnauthenticatedRequest$ = new Subject<void>();
    private _onReconnect$ = new Subject<void>();

    constructor(private snackbarService: SnackbarService) {
        this.openConnection();
    }

    private openConnection() {
        // TODO: see https://developer.mozilla.org/en-US/docs/Web/API/WebSockets_API/Writing_WebSocket_servers#pings_and_pongs_the_heartbeat_of_websockets
        this.wsChannel = new WebSocket(this.url);
        this.wsChannel.binaryType = "arraybuffer";
        this.wsChannel.addEventListener("open", () => this.onChannelOpen());
        this.wsChannel.addEventListener("close", (e) => this.onChannelClose(e));
        this.wsChannel.addEventListener("error", (e) => this.onChannelError(e));
        this.wsChannel.addEventListener("message", (e) => this.onChannelMessage(e));
    }

    private onChannelOpen() {
        console.debug("Connected to URL", this.url);
        this.isConnected$.next(true);
        this._onReconnect$.next();
        if (this.reconnectingSnackbarRef) {
            this.reconnectingSnackbarRef.dismiss();
            this.reconnectingSnackbarRef = null;
            this.snackbarService.info(`Reconnected`, { duration: 1500 });
        }
        this.connectionFailCounter = 0;
    }

    private onChannelClose(e: CloseEvent) {
        console.debug("Websocket closed", e);
        this.isConnected$.next(false);
        this.isAuthenticated$.next(false);
        if (e.reason.startsWith(WS_CLOSE_REASON_API_VERSION_MISMATCH)) {
            this.snackbarService.errorPersistent(e.reason.slice(WS_CLOSE_REASON_API_VERSION_MISMATCH.length).trim());
        } else {
            this.tryReconnect();
        }
    }

    private onChannelError(e: Event) {
        console.info("Websocket error", e);
        this.isConnected$.next(false);
        this.isAuthenticated$.next(false);
    }

    private onChannelMessage(e: MessageEvent) {
        const message = ApplicationProtoRes.deserialize(new Uint8Array(e.data));
        console.debug(`Incoming response`, resToJson(message));
        this.receivedProto$.next(message);
    }

    private tryReconnect() {
        setTimeout(() => {
            this.connectionFailCounter++;
            if (!this.reconnectingSnackbarRef && this.connectionFailCounter >= 4) this.reconnectingSnackbarRef = this.snackbarService.infoPersistent(`Reconnecting...`);
            console.warn(`Connection lost. Attempting to reconnect (attempt ${this.connectionFailCounter})`);
            this.openConnection();
        }, RECONNECT_BASE_DELAY_MS * (Math.min(this.connectionFailCounter, 4) + Math.random()));
    }

    private toArrayBuffer(message: ApplicationProtoReq): ArrayBuffer {
        const bytes = message.serialize();
        return bytes.buffer.slice(bytes.byteOffset, bytes.byteLength + bytes.byteOffset);
    }

    publicReqRes(req: ApplicationProtoPublicReq) {
        const reqID = uuid();
        const message = new ApplicationProtoReq({ uuid: stringToBytes(reqID), public: req });
        return this.isConnected$.pipe(
            first(connected => connected),
            tap(() => {
                console.debug(`Outgoing request`, reqToJson(message));
                this.wsChannel!.send(this.toArrayBuffer(message));
            }),
            switchMap(() => this.receiveResponses(reqID)),
            first(),
            map((res) => {
                if (res.status === "success") return res.public;
                else this.handleErrorResponse(res);
            }),
        );
    }

    privateReq(req: ApplicationProtoPrivateReq): Observable<Ok> {
        return this.privateReqRes(req).pipe(map(() => ok));
    }

    privateReqRes(req: ApplicationProtoPrivateReq): Observable<ApplicationProtoPrivateRes> {
        const reqID = uuid();
        const reqIDBytes = stringToBytes(reqID);
        const message = new ApplicationProtoReq( { uuid: reqIDBytes, private: req });
        return combineLatest([this.isConnected$, this.isAuthenticated$]).pipe(
            first(([connected, authenticated]) => connected && authenticated),
            tap(() => {
                console.debug(`Outgoing request`, reqToJson(message));
                this.wsChannel!.send(this.toArrayBuffer(message));
            }),
            switchMap(() => this.receiveResponses(reqID)),
            first(),
            map((res) => {
                if (res.status === "success") return res.private;
                else this.handleErrorResponse(res);
            }),
        );
    }

    privateReqSub(req: ApplicationProtoPrivateReq, unsub$: UnsubListener) {
        const reqID = uuid();
        const reqIDBytes = stringToBytes(reqID);
        const message = new ApplicationProtoReq( { uuid: reqIDBytes, private: req });
        // TODO: test this new logic with fake disconnection
        return combineLatest([this.isConnected$, this.isAuthenticated$]).pipe(
            filter(([connected, authenticated]) => connected && authenticated),
            tap(() => {
                console.debug(`Outgoing request`, reqToJson(message));
                this.wsChannel!.send(this.toArrayBuffer(message));
            }),
            switchMap(() => {
                const receiveResponses = this.receiveResponses(reqID).pipe(map((res) => {
                    if (res.status === "success") return res.private;
                    else this.handleErrorResponse(res);
                }));
                return concat(
                    receiveResponses.pipe(take(1)),
                    receiveResponses.pipe(
                        takeUntil(unsub$),
                        finalize(() => {
                            const unsubMessage = new ApplicationProtoReq({
                                uuid: stringToBytes(uuid()),
                                private: new ApplicationProtoPrivateReq({ unsub: new ApplicationProtoPrivateUnsubscribeReq({ reqUuid: reqIDBytes }) })
                            });
                            this.wsChannel!.send(this.toArrayBuffer(unsubMessage));
                            console.debug(`Outgoing unsubscribe call`, { uuid: reqID });
                        })
                    )
                );
            }),
        );
    }

    private receiveResponses(reqID: string) {
        return this.receivedProto$.pipe(filter((res) => bytesToString(res.uuid) === reqID));
    }

    private handleErrorResponse(res: ApplicationProtoRes): never {
        if (res.error.code === UNAUTHENTICATED_REQUEST_CODE) {
            this.isAuthenticated$.next(false);
            this._onUnauthenticatedRequest$.next();
        } else {
            this.snackbarService.errorPersistent(res.error.message);
        }
        throw new ServiceError(res.error.message, res.error.code);
    }

    get onUnauthenticatedRequest$() {
        return this._onUnauthenticatedRequest$;
    }

    get onReconnect$() {
        return this._onReconnect$;
    }
}

export type UnsubListener = Observable<void>;
