@if (tagline$ | async; as tagline) {
  <tp-p-with-highlights class="tp-setup" level="p2" [value]="tagline"/>
}

<div class="setup-container">
  @if (selectedTab$ | async; as selectedTab) {
    <div class="tab-container">
      <div class="tab-item tab-item-first" [class.tab-item-active]="selectedTab === 'joinOrg'">
        <a (click)="selectedTab$.next('joinOrg')">Join Organization</a>
      </div>
      <div class="tab-item tab-item-last" [class.tab-item-active]="selectedTab === 'createOrg'">
        <a (click)="selectedTab$.next('createOrg')">Create Organization</a>
      </div>
    </div>
    <article class="form-container with-tabs">
      @switch (selectedTab) {
        @case ("joinOrg") {
          @if (invitations$ | async; as invitations) {
            @if (invitations.length) {
              @for (invitation of invitations; track invitation) {
                <section>
                  <tp-form
                    [formGroup]="invitationReviewForm" [isSubmitting$]="invitationReviewFormIsSubmitting$"
                    (submit)="acceptInvitation(invitation)"
                  >
                    <p>You've been invited to the <u>{{ invitation.org.name }}</u> organization.</p>
                    <div class="tp-form-actions">
                      <tp-button
                        type="button" [enabled]="!(invitationReviewFormIsSubmitting$ | async)" buttonId="rejectInvitation"
                        buttonStyle="primary-outline white stroke" (click)="declineInvitation(invitation)"
                      >
                        Reject invitation
                      </tp-button>
                      <tp-button
                        type="submit" [enabled]="!(invitationReviewFormIsSubmitting$ | async)"
                        buttonStyle="primary-solid green" buttonId="acceptInvitation"
                      >
                        Join {{ invitation.org.name }}
                      </tp-button>
                    </div>
                  </tp-form>
                </section>
              }
            } @else {
              <p>You don't have any organization invitations. <a (click)="selectedTab$.next('createOrg')">Create a new organization</a> instead.</p>
              <br/>
              <p>If this is not expected, contact your organization administrator.</p>
            }
          } @else {
            <tp-spinner/>
          }
        }
        @case ("createOrg") {
          <h1 class="box-title">Create Organization</h1>
          <tp-form [formGroup]="createOrgForm" [isSubmitting$]="createOrgFormIsSubmitting$" (doSubmit)="submitCreateOrgForm()">
            <tp-form-input [form]="createOrgForm" type="text" label="Organization Name" field="name" autocomplete="organization"/>
            <tp-form-input [form]="createOrgForm" [hint]="orgIdHint" type="text" label="Organization ID" field="id"/>

            @if (orgInfoSurvey) {
              @for (section of orgInfoSurvey.sections; track section) {
                @for (question of section.questions; track question) {
                  <tp-multiple-choice-question class="tp-org-info-question" [question]="$any(question)"/>
                }
              }
            }

            <div>
              <p class="tp-invite-team">Invite your team (comma-separated list of email addresses)</p>
              <tp-form-textarea [form]="createOrgForm" field="teamEmails" placeholder="user1@site.com, user2@site.com"/>
            </div>

            <tp-form-actions
              [isSubmitting]="createOrgFormIsSubmitting$ | async" buttonIdPrefix="createOrg"
              [submitDisabled]="!createOrgForm.dirty || !orgInfoSurvey?.isCompleted" submitText="Create organization"
            />
          </tp-form>
        }
      }
    </article>

    @if (invitationReviewFormIsSubmitting$ | async) {
      <mat-progress-bar mode="indeterminate" />
    }
  } @else {
    <tp-spinner/>
  }
</div>
