/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Project } from "../../../concept/project";
import { ProjectUserAssignment, User } from "../../../concept/user";
import { DialogResult } from "../../../service/dialog.service";
import { ProjectApi } from "../../../service/project/project-api.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { FormActionsComponent, FormComponent, FormOption, FormSelectComponent, ModalComponent, requiredValidator } from "typedb-platform-framework";
import { AccessLevel, accessLevelToString, accessLevelValues } from "../../../concept/iam";

export type ProjectSetUserAccessLevelDialogData = { project: Project, user: ProjectUserAssignment };

@Component({
    selector: "tp-project-set-user-access-level-dialog",
    templateUrl: "./project-set-user-access-level-dialog.component.html",
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent, FormSelectComponent, FormActionsComponent],
})
export class ProjectSetUserAccessLevelDialogComponent {
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        accessLevel: [this.data.user.accessLevel, [requiredValidator]],
    });
    readonly accessLevelOptions: FormOption<AccessLevel>[] = accessLevelValues.map(x => ({ value: x, viewValue: accessLevelToString(x) }));

    constructor(
        private dialogRef: MatDialogRef<ProjectSetUserAccessLevelDialogComponent, DialogResult>, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public  data: ProjectSetUserAccessLevelDialogData,
        private projectService: ProjectApi, private snackbar: SnackbarService
    ) {}

    submit() {
        const form = this.form.value;
        const accessLevel = form.accessLevel!;
        this.projectService.setUserAccessLevel({ projectUuid: this.data.project.uuid, userUuid: this.data.user.uuid, accessLevel }).subscribe({
            next: () => {
                this.snackbar.success(`User '${this.data.user.id}'s access level to ${this.data.project.id} is now '${accessLevel}'.`);
                this.close("ok");
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
