/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { combineLatest, first, map, startWith, Subject, switchMap } from "rxjs";
import {
    ButtonComponent,
    ConfirmationModalComponent,
    ConfirmationModalData,
    MenuItem,
    ModalComponent,
    StrongConfirmationModalComponent,
    StrongConfirmationModalData
} from "typedb-platform-framework";
import { Invitation, InvitationColumn } from "../../../concept/organization";
import { LOADING } from "../../../framework/strings";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { ResourceTable } from "../../../service/resource-table.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { InvitationsTableComponent } from "../../org/invitations-table/invitations-table.component";
import { PageScaffoldComponent, resourceAvailabilityStreamOf } from "../../scaffold/page/page-scaffold.component";
import { AsyncPipe } from "@angular/common";
import { InvitationDialogComponent } from "../invite/invitation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccessModalComponent } from "../../org/access-modal/access-modal.component";
import { UsersTableComponent } from "../table/users-table.component";
import { OrgMember, User } from "../../../concept/user";
import {
    OrgSetUserAccessLevelDialogComponent
} from "../../org/set-user-access-level/org-set-user-access-level-dialog.component";

@Component({
    selector: "tp-members-page",
    templateUrl: "./members-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, ModalComponent, ConfirmationModalComponent, TableToolbarComponent, AsyncPipe,
        TablePaginatorComponent, InvitationsTableComponent, ButtonComponent, FontAwesomeModule,
        MatTooltipModule, UsersTableComponent,
    ],
})
export class MembersPageComponent implements OnDestroy {
    readonly org = this.app.requireCurrentOrg();
    readonly currentUser = this.app.requireCurrentUser();

    private readonly unsubMembers$ = new Subject<void>();
    readonly members = this.orgCtl.membersTable(this.unsubMembers$);
    private readonly unsubInvitations$ = new Subject<void>();
    invitations?: ResourceTable<Invitation, InvitationColumn>;
    readonly availability$ = resourceAvailabilityStreamOf(this.members);

    readonly cannotInviteReason$ = this.app.hasOrgAdminAccess$.pipe(map(isAdmin => {
        if (!isAdmin) return "Only organization administrators can invite members";
        return null;
    }), startWith(LOADING));
    readonly inviteEnabled$ = this.cannotInviteReason$.pipe(map(x => !x));

    usersTableRowPopupMenuItems: (user: OrgMember) => MenuItem[] = (user) => [];

    readonly invitationsTableRowPopupMenuItems: (invitation: Invitation) => MenuItem[] = (invitation) => [
        {
            label: "Access Details",
            action: () => {
                this.dialog.open(AccessModalComponent, { data: invitation });
            }
        },
        {
            label: "Resend invitation email",
            action: () => {
                this.orgApi.resendInvitation(invitation.uuid).subscribe(() => {
                    this.snackbar.success(`A new invitation email has been sent to '${invitation.userEmail}'`);
                });
            },
        },
        {
            label: "Revoke invitation",
            action: () => {
                this.openRevokeInvitationModal(invitation);
            }
        },
    ];

    constructor(
        public app: ApplicationState, private orgApi: OrgApi, private snackbar: SnackbarService,
        private orgCtl: OrgController, private dialog: MatDialog
    ) {
        this.app.hasOrgAdminAccess$.pipe(first()).subscribe((isAdmin) => {
            if (isAdmin) this.invitations = this.orgCtl.invitationsTable(this.unsubInvitations$);
            this.usersTableRowPopupMenuItems = (user: OrgMember) => [
                {
                    label: "Change access level",
                    action: () => this.openChangeAccessLevelModal(user),
                    disabled: !isAdmin,
                    disabledReason: !isAdmin ? "Only organization administrators can change user access levels" : undefined
                },
                {
                    label: "Remove",
                    action: () => this.openRemoveMemberModal(user),
                    disabled: !isAdmin || user.id == this.currentUser.id,
                    disabledReason: !isAdmin ? "Only organization administrators can remove members" :
                        user.id == this.currentUser.id ? "You can't remove yourself from an organization" : undefined
                },
            ];
        });
    }

    ngOnDestroy() {
        this.unsubMembers$.next();
        this.unsubInvitations$.next();
    }

    onInviteClick() {
        this.dialog.open(InvitationDialogComponent, { width: "600px" }).beforeClosed().subscribe(() => { this.invitations!.refresh(); });
    }

    openRevokeInvitationModal(invitation: Invitation) {
        const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
            data: {
                title: "Revoke invitation",
                body: `Are you sure you would like to revoke ${invitation.userEmail}'s invitation to ${invitation.org?.name}?`,
                confirmText: "Revoke invitation",
                confirmButtonStyle: "primary-solid red",
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.orgApi.revokeInvitation(invitation.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Invitation revoked successfully.`);
                this.invitations!.refresh();
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openChangeAccessLevelModal(user: OrgMember) {
        this.dialog.open(OrgSetUserAccessLevelDialogComponent, { data: { org: this.org, user } });
    }

    openRemoveMemberModal(user: User) {
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Remove member",
                body: confirmationMessage("remove", [user], "member") + ` They will no longer be able to view this organization. This does not prevent them connecting to running TypeDB Cloud clusters, but they will no longer be accessible via the web UI.`,
                confirmText: "Remove member",
                strongConfirmationString: strongConfirmationStringOf([user]),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => combineLatest([user].map(x => this.orgApi.removeMember({
            orgUuid: this.org.uuid,
            userUuid: x.uuid
        }))))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Member(s) removed successfully.`);
                this.members.refresh();
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
