/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Parameters } from "../../concept/common";
import {
    Cluster,
    ClusterColumn,
    clusterProperties,
    clusterPropertiesList,
    clusterStatusFromProtoMap
} from "../../concept/cluster";
import { AccessLevel, accessLevelValues, hasWriteAccess } from "../../concept/iam";
import {
    Invitation,
    InvitationColumn,
    invitationProperties,
    invitationPropertiesList,
    Organization
} from "../../concept/organization";
import {
    Project,
    ProjectColumn,
    projectProperties,
    projectPropertiesList
} from "../../concept/project";
import { Team, TeamColumn, teamProperties, teamPropertiesList } from "../../concept/team";
import {
    OrgMember,
    orgMemberProperties,
    orgMemberPropertiesList,
    User,
    UserColumn,
    userProperties,
} from "../../concept/user";
import { longFilterSpec, stringFilterSpec } from "../../framework/table";
import {
    clusterDetailsPath,
    memberDetailsPath,
    projectDetailsPath,
    teamDetailsPath
} from "../../routing/resource-paths";
import { AuthorizationService } from "../authorization.service";
import { DEFAULT_INITIAL_PARAMS, ResourceTable, SpecialColumn } from "../resource-table.service";
import { OrgApi } from "./org-api.service";
import { first, map, Observable, of, Subject, switchMap } from "rxjs";
import { ApiListResponse } from "../../concept/api-response";
import { ApplicationState } from "../application-state.service";
import { Property } from "../../concept/base";

@Injectable({
    providedIn: "root",
})
export class OrgController {

    constructor(private api: OrgApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    clustersTable(unsub$: Subject<void>): ResourceTable<Cluster, ClusterColumn> {
        return new OrgClustersTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    projectsTable(unsub$: Subject<void>, initialParams: Parameters = DEFAULT_INITIAL_PARAMS): ResourceTable<Project, ProjectColumn> {
        return new OrgProjectsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$, initialParams);
    }

    membersTable(unsub$: Subject<void>): BaseMembersTable<OrgMember, UserColumn> {
        return new OrgMembersTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    invitationsTable(unsub$: Subject<void>): ResourceTable<Invitation, InvitationColumn> {
        return new OrgInvitationsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    teamsTable(unsub$: Subject<void>): ResourceTable<Team, TeamColumn> {
        return new OrgTeamsTable(this.app.requireCurrentOrg(), this.api, this.authorization, unsub$);
    }

    listWritableTeamsSnapshot(): Observable<Team[]> {
        return this.api.listTeams(
            this.app.requireCurrentOrg().uuid,
            { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } },
            of(undefined),
        ).pipe(
            first(res => !!res.initial),
            map(res => res.initial!),
            switchMap((teams) => {
                return this.authorization.teamAccessLevels(teams.map(x => x.uuid)).pipe(
                    map(accessLevels => accessLevels.map((level, idx) => {
                        return hasWriteAccess(level) ? idx : null;
                    })),
                    map(indexes => indexes.filter(x => x != null) as number[]),
                    map(indexes => indexes.map(x => teams[x])),
                )
            })
        )
    }

    listWritableProjectsSnapshot(): Observable<Project[]> {
        return this.api.listProjects(
            this.app.requireCurrentOrg().uuid,
            { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } },
            of(undefined)
        ).pipe(
            first(res => !!res.initial),
            map(res => res.initial!),
            switchMap((projects) => {
                return this.authorization.projectAccessLevels(projects.map(x => x.uuid)).pipe(
                    map(accessLevels => accessLevels.map((level, idx) => {
                        return hasWriteAccess(level) ? idx : null;
                    })),
                    map(indexes => indexes.filter(x => x != null) as number[]),
                    map(indexes => indexes.map(x => projects[x])),
                );
            }),
        );
    }
}

export class OrgClustersTable extends ResourceTable<Cluster, ClusterColumn> {
    override readonly properties = clusterPropertiesList;
    override readonly primaryProperty = clusterProperties.id;
    override displayedProperties = [...this.properties.filter(x => !["storageType", "storageSize", "typeDBVersion", "createdAt"].includes(x.id))];
    override readonly filterSpecs = [
        stringFilterSpec(clusterProperties.id),
        stringFilterSpec(clusterProperties.project),
        stringFilterSpec(clusterProperties.provider),
        stringFilterSpec(clusterProperties.region),
        stringFilterSpec(clusterProperties.machineType),
        longFilterSpec(clusterProperties.clusterSize),
        stringFilterSpec(clusterProperties.storageType),
        longFilterSpec(clusterProperties.storageSize),
        stringFilterSpec(clusterProperties.clusterStatus, Object.values(clusterStatusFromProtoMap)),
        stringFilterSpec(clusterProperties.typeDBVersion),
    ];

    constructor(private org: Organization, private orgApi: OrgApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Cluster>> {
        return this.orgApi.listClusters(this.org.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Cluster[]): Observable<AccessLevel[]> {
        return this.authorization.clusterAccessLevels(resources.map(x => x.uuid));
    }

    override get displayedColumns(): ("select" | "actions" | "status" | ClusterColumn)[] {
        return ["select", ...this.displayedProperties.map(x => x.id as ClusterColumn), "status", "actions"];
    }

    override itemRoute(cluster: Cluster): string {
        return clusterDetailsPath(cluster, this.org);
    }

    override noAccessMessage(cluster: Cluster): string {
        return `You don't have access to the cluster '${cluster.id}'`;
    }
}

export abstract class BaseProjectsTable<ENTITY extends Project> extends ResourceTable<ENTITY, ProjectColumn> {
    override readonly primaryProperty = projectProperties.id;

    protected constructor(
        protected org: Organization, private authorization: AuthorizationService, unsub$: Subject<void>, initialParams: Parameters = DEFAULT_INITIAL_PARAMS
    ) {
        super(unsub$, initialParams);
    }

    override getAccessLevels(resources: ENTITY[]): Observable<AccessLevel[]> {
        return this.authorization.projectAccessLevels(resources.map(x => x.uuid));
    }

    override itemRoute(project: ENTITY): string {
        return projectDetailsPath(project, this.org);
    }

    override noAccessMessage(project: ENTITY): string {
        return `You don't have access to the project '${project.id}'`;
    }
}

export class OrgProjectsTable extends BaseProjectsTable<Project> {
    override readonly properties = projectPropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [stringFilterSpec(projectProperties.id), stringFilterSpec(projectProperties.name)];

    constructor(protected override org: Organization, private orgApi: OrgApi, authorization: AuthorizationService, unsub$: Subject<void>, initialParams: Parameters = DEFAULT_INITIAL_PARAMS) {
        super(org, authorization, unsub$, initialParams);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Project>> {
        return this.orgApi.listProjects(this.org.uuid, params, this.unsub$);
    }
}

export abstract class BaseMembersTable<ENTITY extends User, COLUMN extends string> extends ResourceTable<ENTITY, COLUMN> {
    override readonly primaryProperty = userProperties.id;

    protected constructor(protected org: Organization, private authorization: AuthorizationService, unsub$: Subject<void>) { super(unsub$); }

    override getAccessLevels(resources: ENTITY[]): Observable<AccessLevel[]> {
        return this.authorization.userAccessLevels(resources.map(x => x.uuid));
    }

    override noAccessMessage(user: ENTITY): string {
        return `You don't have permissions to manage user '${user.id}'`;
    }

    override itemRoute(user: ENTITY): string {
        return memberDetailsPath(user, this.org);
    }
}

export class OrgMembersTable extends BaseMembersTable<OrgMember, UserColumn> {
    override readonly properties = orgMemberPropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(orgMemberProperties.id),
        stringFilterSpec(orgMemberProperties.email),
        stringFilterSpec(orgMemberProperties.firstName),
        stringFilterSpec(orgMemberProperties.lastName),
        stringFilterSpec(orgMemberProperties.accessLevel, accessLevelValues)
    ];

    constructor(protected override org: Organization, private orgApi: OrgApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, authorization, unsub$);
        // TODO
        // this.items$.pipe(
        //     switchMap((members) => {
        //         const orgAccessLevels = this.authorization.
        //     }),
        // ).subscribe();
    }

    override getData(params: Parameters): Observable<ApiListResponse<OrgMember>> {
        return this.orgApi.listMembers(this.org.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (SpecialColumn | UserColumn)[] {
        return ["avatar", ...this.displayedProperties.map(x => x.id), "actions"] as (SpecialColumn | UserColumn)[];
    }
}

export abstract class BaseTeamsTable<ENTITY extends Team, COLUMN extends string> extends ResourceTable<ENTITY, COLUMN> {
    override readonly primaryProperty = teamProperties.id;

    protected constructor(protected org: Organization, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getAccessLevels(resources: ENTITY[]): Observable<AccessLevel[]> {
        return this.authorization.teamAccessLevels(resources.map(x => x.uuid));
    }

    override itemRoute(team: ENTITY): string {
        return teamDetailsPath(team, this.org);
    }

    override noAccessMessage(team: ENTITY): string {
        return `You don't have access to the team '${team.id}'`;
    }
}

export class OrgTeamsTable extends BaseTeamsTable<Team, TeamColumn> {
    override readonly properties = teamPropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [stringFilterSpec(teamProperties.id), stringFilterSpec(teamProperties.name)];

    constructor(protected override org: Organization, private orgApi: OrgApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Team>> {
        return this.orgApi.listTeams(this.org.uuid, params, this.unsub$);
    }
}

export class OrgInvitationsTable extends ResourceTable<Invitation, InvitationColumn> {
    override readonly properties;
    override readonly primaryProperty = invitationProperties.userEmail;
    override displayedProperties: Property[];
    override readonly filterSpecs = [
        stringFilterSpec(invitationProperties.userEmail),
        stringFilterSpec(invitationProperties.orgAccessLevel, accessLevelValues)
    ];

    constructor(protected org: Organization, private orgApi: OrgApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        const initialParams: Parameters = {
            pagination: { offset: 0, limit: 10 },
            sorting: { attributeType: "created-at", direction: "desc" },
            filters: [],
        };
        super(unsub$, initialParams);
        const properties = [...invitationPropertiesList];
        properties.remove(invitationProperties.orgId);
        properties.remove(invitationProperties.orgName);
        this.displayedProperties = [...properties];
        this.properties = properties;
    }

    override getData(params: Parameters): Observable<ApiListResponse<Invitation>> {
        return this.orgApi.listInvitations(this.org.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Invitation[]): Observable<AccessLevel[]> {
        return this.authorization.orgAccessLevels(resources.map(x => x.org.uuid));
    }

    override get displayedColumns(): ("actions" | InvitationColumn)[] {
        return [...this.displayedProperties.map(x => x.id as InvitationColumn), "actions"];
    }

    override itemRoute(): string {
        return ``;
    }

    override noAccessMessage(): string {
        return ``;
    }
}
