/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
    FormActionsComponent, FormComponent, FormInputComponent, ModalComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { DialogResult } from "../../../service/dialog.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ApplicationState } from "../../../service/application-state.service";
import { Router } from "@angular/router";
import { SnackbarService } from "../../../service/snackbar.service";

@Component({
    selector: "tp-org-update-dialog",
    templateUrl: "./org-update-dialog.component.html",
    standalone: true,
    imports: [
        ModalComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormsModule, ReactiveFormsModule,
        FormComponent, MatFormFieldModule, MatInputModule,
    ],
})
export class OrgUpdateDialogComponent {
    readonly org = this.app.requireCurrentOrg();
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        id: [this.org.id, [patternValidator(idPattern, idPatternErrorText), requiredValidator]],
        name: [this.org.name, [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
    });

    constructor(
        private dialogRef: MatDialogRef<OrgUpdateDialogComponent, DialogResult>, private formBuilder: FormBuilder,
        private orgApi: OrgApi, private app: ApplicationState, private router: Router,
        private snackbar: SnackbarService,
    ) {
    }

    submit() {
        const form = this.form.value;
        const [id, name] = [form.id, form.name];
        this.orgApi.updateOrg({
            uuid: this.org.uuid,
            id: id !== this.org.id ? id : undefined,
            name: name !== this.org.name ? name : undefined
        }).subscribe({
            next: () => {
                this.close("ok");
                // Org is loaded in the auth guard, so only a page refresh can reload it
                if (id !== this.org.id) {
                    window.location.replace(`${window.location.origin}/org/${id}/settings`);
                } else {
                    window.location.reload();
                }
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
