/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Routes } from "@angular/router";
import { ServerDetailsPageComponent } from "../module/cluster/server/server-details-page.component";
import { OrgSubsiteScaffoldComponent } from "../module/scaffold/org-subsite/org-subsite-scaffold.component";
import { SetupProjectComponent } from "../module/setup/project/setup-project.component";
import { SetupComponent } from "../module/setup/setup.component";
import { genericGuard, orgLoadedGuard, loggedOutGuard, profileDomainGuard, setupGuard, orgJoinedNotSurveyedGuard } from "./auth-guard";

import { _404PageComponent } from "../module/404/404-page.component";
import { Internal404PageComponent } from "../module/404/internal/internal-404-page.component";
import { ClusterDeploymentPageComponent } from "../module/cluster/deploy/cluster-deployment-page.component";
import { ClusterDetailsPageComponent } from "../module/cluster/details/cluster-details-page.component";
import { ClustersPageComponent } from "../module/cluster/page/clusters-page.component";
import { PasswordResetPageComponent } from "../module/login/password-reset/password-reset-page.component";
import { LoginPageComponent } from "../module/login/login-page/login-page.component";
import { OrgSettingsPageComponent } from "../module/org/settings/org-settings-page.component";
import { UserSettingsPageComponent } from "../module/user/page/user-settings-page.component";
import { ProjectDetailsPageComponent } from "../module/project/details/project-details-page.component";
import { ProjectsPageComponent } from "../module/project/page/projects-page.component";
import { TeamDetailsPageComponent } from "../module/team/details/team-details-page.component";
import { TeamsPageComponent } from "../module/team/page/teams-page.component";
import { MemberDetailsPageComponent } from "../module/member/details/member-details-page.component";
import { MembersPageComponent } from "../module/member/page/members-page.component";
import { SpinnerComponent } from "typedb-platform-framework";
import { GcpMarketplaceIntegrationComponent } from "../module/org/gcp-marketplace-integration/gcp-marketplace-integration.component";
import { SetupMarketplaceComponent } from "../module/setup/marketplace/setup-marketplace.component";
import { SetupScaffoldComponent } from "../module/setup/scaffold/setup-scaffold.component";
import {
    AzureMarketplaceIntegrationComponent
} from "../module/org/azure-marketplace-integration/azure-marketplace-integration.component";
import {
    AwsMarketplaceIntegrationComponent
} from "../module/org/aws-marketplace-integration/aws-marketplace-integration.component";

const domains = {
    clusters: "clusters",
    projects: "projects",
    teams: "teams",
    members: "members",
    settings: "settings",
} as const;

export type RouteDomain = typeof domains[keyof typeof domains];

export const routeDomains = domains;

export const routes: Routes = [
    { path: "", component: SpinnerComponent, pathMatch: "full", canActivate: [genericGuard] },
    { path: "sign-in", component: LoginPageComponent, canActivate: [loggedOutGuard], title: "Sign In" },
    { path: "sign-up", component: LoginPageComponent, canActivate: [loggedOutGuard], title: "Sign Up" },
    { path: "forgot-password", component: LoginPageComponent, title: "Forgot Password" },
    { path: "verify-email", component: LoginPageComponent, title: "Verify Email" },
    {
        path: "setup",
        component: SetupScaffoldComponent,
        children: [
            { path: "", component: SetupComponent, pathMatch: "full", canActivate: [setupGuard], title: "Setup" },
            { path: "marketplace", component: SetupMarketplaceComponent, title: "Connect Marketplace Account" },
            { path: "project", component: SetupProjectComponent, canActivate: [orgJoinedNotSurveyedGuard], data: { variant: "standalone" }, title: "Project Info" },
        ]
    },
    { path: "reset-password", component: PasswordResetPageComponent, canActivate: [loggedOutGuard], title: "Reset Password" },
    {
        path: "org/:org-id",
        canActivate: [orgLoadedGuard],
        component: OrgSubsiteScaffoldComponent,
        children: [
            { path: "", redirectTo: "clusters", pathMatch: "full" },
            { path: "settings", component: OrgSettingsPageComponent, data: { domain: domains.settings, }, title: "Organization Settings" },
            { path: "clusters", component: ClustersPageComponent, data: { domain: domains.clusters, }, title: "Clusters" },
            { path: "clusters/deploy", component: ClusterDeploymentPageComponent, data: { domain: domains.clusters, hideTopbar: true, }, title: "Deploy Cluster" },
            { path: "projects/:project-id/clusters/:cluster-id", component: ClusterDetailsPageComponent, data: { domain: domains.clusters, }, title: "Clusters" },
            { path: "projects/:project-id/clusters/:cluster-id/servers/:server-id", component: ServerDetailsPageComponent, data: { domain: domains.clusters, }, title: "Clusters" },
            { path: "projects", component: ProjectsPageComponent, data: { domain: domains.projects, }, title: "Projects" },
            { path: "projects/:project-id", component: ProjectDetailsPageComponent, data: { domain: domains.projects, }, title: "Projects" },
            { path: "members", component: MembersPageComponent, data: { domain: domains.members, }, title: "Members" },
            { path: "members/:user-id", component: MemberDetailsPageComponent, data: { domain: domains.members }, title: "Members" },
            { path: "teams", component: TeamsPageComponent, data: { domain: domains.teams, }, title: "Teams" },
            { path: "teams/:team-id", component: TeamDetailsPageComponent, data: { domain: domains.teams, }, title: "Teams" },

            // TODO: clean up these redirects eventually
            { path: "deployments", redirectTo: "clusters", pathMatch: "full" },
            { path: "deployments/create", redirectTo: "clusters/deploy" },
            { path: "projects/:project-id/deployments/:deployment-id", redirectTo: "projects/:project-id/clusters/:deployment-id" },
            { path: "projects/:project-id/deployments/:deployment-id/servers/:server-id", redirectTo: "projects/:project-id/clusters/:deployment-id/servers/:server-id" },

            { path: "**", component: Internal404PageComponent, title: "404" },
        ],
    },
    {
        path: "marketplace",
        component: SetupScaffoldComponent,
        children: [
            {
                path: "gcp",
                canActivate: [profileDomainGuard],
                component: GcpMarketplaceIntegrationComponent,
            },
            {
                path: "azure",
                canActivate: [profileDomainGuard],
                component: AzureMarketplaceIntegrationComponent,
            },
            {
                path: "aws",
                canActivate: [profileDomainGuard],
                component: AwsMarketplaceIntegrationComponent,
            }
        ]
    },
    {
        path: "settings",
        canActivate: [profileDomainGuard],
        component: OrgSubsiteScaffoldComponent,
        children: [{ path: "", component: UserSettingsPageComponent, title: "User Settings" }],
    },
    { path: "**", component: _404PageComponent, title: "404" },
];
