/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { combineLatest, map, Observable, of, startWith, Subject, switchMap } from "rxjs";
import {
    ButtonComponent,
    ConfirmationModalComponent,
    MenuItem,
    ModalComponent,
    StrongConfirmationModalComponent,
    StrongConfirmationModalData
} from "typedb-platform-framework";
import { Project, projectProperties } from "../../../concept/project";
import { LOADING } from "../../../framework/strings";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { ProjectApi } from "../../../service/project/project-api.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { ProjectCreationDialogComponent } from "../create/project-creation-dialog.component";
import { PageScaffoldComponent, resourceAvailabilityStreamOf } from "../../scaffold/page/page-scaffold.component";
import { ProjectsTableComponent } from "../table/projects-table.component";
import { AsyncPipe } from "@angular/common";
import { ApplicationState } from "../../../service/application-state.service";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccessLevel, hasAdminAccess, hasReadAccess } from "../../../concept/iam";

@Component({
    selector: "tp-projects-page",
    templateUrl: "./projects-page.component.html",
    styleUrls: ["./projects-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, ProjectsTableComponent, ModalComponent, ConfirmationModalComponent, AsyncPipe,
        TableToolbarComponent, TablePaginatorComponent, ButtonComponent, FontAwesomeModule, MatTooltipModule,
    ],
})
export class ProjectsPageComponent implements OnInit, OnDestroy {
    readonly org = this.app.requireCurrentOrg();
    private readonly unsub$ = new Subject<void>();
    readonly projects = this.orgCtl.projectsTable(this.unsub$);
    readonly secondProjectInOrg = this.orgCtl.projectsTable(this.unsub$, {
        pagination: { offset: 1, limit: 1 },
        sorting: { attributeType: projectProperties.id.attributeType }
    });
    readonly availability$ = resourceAvailabilityStreamOf(this.projects);
    readonly clusterCountByProjectUuid$: Observable<Record<string, number>> = this.projects.items$.pipe(
        switchMap((projects) => combineLatest(projects.map((project) => this.projects.accessLevelByUuid$(project.uuid)
                .pipe(map((accessLevel) => {
                    return { projectUuid: project.uuid, accessLevel }
                }))
        ))),
        switchMap((projects) => combineLatest(
            projects.filter((x) => x.accessLevel && hasReadAccess(x.accessLevel)).map((x) =>
                this.projectApi.listClusters(x.projectUuid, { pagination: { offset: 0, limit: 1 }, sorting: { attributeType: "id" } }, of(undefined))
                    .pipe(map((depList) => [x.projectUuid, depList.initial!.length]))
            )
        )),
        map(Object.fromEntries)
    )

    readonly cannotCreateReason$ = this.app.hasOrgWriteAccess$.pipe(map(canWrite => {
        if (!canWrite) return "Only users with write permissions to the organization can create projects";
        return null;
    }), startWith(LOADING));
    readonly createEnabled$ = this.cannotCreateReason$.pipe(map(x => !x));

    projectsTableRowPopupMenuItems: (project: Project, accessLevel: AccessLevel) => MenuItem[] = () => [];

    constructor(
        private projectApi: ProjectApi, private orgCtl: OrgController, private dialog: MatDialog,
        private app: ApplicationState, private snackbar: SnackbarService,
    ) {}

    ngOnInit() {
        this.clusterCountByProjectUuid$.subscribe((clusterCountByProjectUuid) => {
            this.projectsTableRowPopupMenuItems = (project, accessLevel): MenuItem[] => {
                const projectHasClusters = !!clusterCountByProjectUuid[project.uuid]
                const cannotDeleteReason = !hasAdminAccess(accessLevel) ? "You don't have admin access to this project" :
                    projectHasClusters ? "This project cannot be deleted because it has at least one cluster" :
                        !this.orgHasMultipleProjects ? "An organization must have at least one project" : undefined
                return [{
                    label: "Delete",
                    action: () => this.openDeleteModal(project),
                    disabled: !!cannotDeleteReason,
                    disabledReason: cannotDeleteReason
                }];
            }
        });
    }

    ngOnDestroy() {
        this.unsub$.next();
    }

    get orgHasMultipleProjects(): boolean {
        return !!this.secondProjectInOrg.items?.length
    }

    openCreateModal() {
        this.dialog.open(ProjectCreationDialogComponent);
    }

    openDeleteModal(project: Project) {
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Delete project",
                body: confirmationMessage("delete", [project], "project") + ` This action cannot be undone.`,
                confirmText: "Delete project",
                strongConfirmationString: strongConfirmationStringOf([project]),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => combineLatest([project].map(x => this.projectApi.deleteProject(x.uuid))))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Project deleted successfully.`);
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
