/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/subscription-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/concept";
import * as dependency_2 from "./../../protocol/common";
import * as pb_1 from "google-protobuf";
export class SubscriptionCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProto {
        const message = new SubscriptionCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProto {
        return SubscriptionCtlProto.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: SubscriptionCtlProtoGetAccessLevelsReq;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: SubscriptionCtlProtoStartAddCreditCardReq;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: SubscriptionCtlProtoListCreditCardsReq;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: SubscriptionCtlProtoRemoveCreditCardReq;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: SubscriptionCtlProtoSetDefaultCreditCardReq;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: SubscriptionCtlProtoSetGCPAccountReq;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: SubscriptionCtlProtoSetAzureAccountReq;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: SubscriptionCtlProtoSetAWSAccountReq;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: SubscriptionCtlProtoGetMarketplaceAccountsReq;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: SubscriptionCtlProtoListCreditsReq;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: SubscriptionCtlProtoListDiscountsReq;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: SubscriptionCtlProtoGetInvoiceReq;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: SubscriptionCtlProtoGetUpcomingInvoiceReq;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: SubscriptionCtlProtoListPreviousInvoicesReq;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        removeCreditCard?: never;
        setDefaultCreditCard?: never;
        setGcpAccount?: never;
        setAzureAccount?: never;
        setAwsAccount?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: SubscriptionCtlProtoGetMarketplaceAddressesReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("startAddCreditCard" in data && data.startAddCreditCard != undefined) {
                this.startAddCreditCard = data.startAddCreditCard;
            }
            if ("listCreditCards" in data && data.listCreditCards != undefined) {
                this.listCreditCards = data.listCreditCards;
            }
            if ("removeCreditCard" in data && data.removeCreditCard != undefined) {
                this.removeCreditCard = data.removeCreditCard;
            }
            if ("setDefaultCreditCard" in data && data.setDefaultCreditCard != undefined) {
                this.setDefaultCreditCard = data.setDefaultCreditCard;
            }
            if ("setGcpAccount" in data && data.setGcpAccount != undefined) {
                this.setGcpAccount = data.setGcpAccount;
            }
            if ("setAzureAccount" in data && data.setAzureAccount != undefined) {
                this.setAzureAccount = data.setAzureAccount;
            }
            if ("setAwsAccount" in data && data.setAwsAccount != undefined) {
                this.setAwsAccount = data.setAwsAccount;
            }
            if ("getMarketplaceAccounts" in data && data.getMarketplaceAccounts != undefined) {
                this.getMarketplaceAccounts = data.getMarketplaceAccounts;
            }
            if ("listCredits" in data && data.listCredits != undefined) {
                this.listCredits = data.listCredits;
            }
            if ("listDiscounts" in data && data.listDiscounts != undefined) {
                this.listDiscounts = data.listDiscounts;
            }
            if ("getInvoice" in data && data.getInvoice != undefined) {
                this.getInvoice = data.getInvoice;
            }
            if ("getUpcomingInvoice" in data && data.getUpcomingInvoice != undefined) {
                this.getUpcomingInvoice = data.getUpcomingInvoice;
            }
            if ("listPreviousInvoices" in data && data.listPreviousInvoices != undefined) {
                this.listPreviousInvoices = data.listPreviousInvoices;
            }
            if ("getMarketplaceAddresses" in data && data.getMarketplaceAddresses != undefined) {
                this.getMarketplaceAddresses = data.getMarketplaceAddresses;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetAccessLevelsReq, 1) as SubscriptionCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: SubscriptionCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get startAddCreditCard() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoStartAddCreditCardReq, 2) as SubscriptionCtlProtoStartAddCreditCardReq;
    }
    set startAddCreditCard(value: SubscriptionCtlProtoStartAddCreditCardReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasStartAddCreditCard() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get listCreditCards() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoListCreditCardsReq, 3) as SubscriptionCtlProtoListCreditCardsReq;
    }
    set listCreditCards(value: SubscriptionCtlProtoListCreditCardsReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasListCreditCards() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get removeCreditCard() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoRemoveCreditCardReq, 4) as SubscriptionCtlProtoRemoveCreditCardReq;
    }
    set removeCreditCard(value: SubscriptionCtlProtoRemoveCreditCardReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasRemoveCreditCard() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get setDefaultCreditCard() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoSetDefaultCreditCardReq, 5) as SubscriptionCtlProtoSetDefaultCreditCardReq;
    }
    set setDefaultCreditCard(value: SubscriptionCtlProtoSetDefaultCreditCardReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasSetDefaultCreditCard() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get setGcpAccount() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoSetGCPAccountReq, 6) as SubscriptionCtlProtoSetGCPAccountReq;
    }
    set setGcpAccount(value: SubscriptionCtlProtoSetGCPAccountReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasSetGcpAccount() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get setAzureAccount() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoSetAzureAccountReq, 7) as SubscriptionCtlProtoSetAzureAccountReq;
    }
    set setAzureAccount(value: SubscriptionCtlProtoSetAzureAccountReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasSetAzureAccount() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get setAwsAccount() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoSetAWSAccountReq, 8) as SubscriptionCtlProtoSetAWSAccountReq;
    }
    set setAwsAccount(value: SubscriptionCtlProtoSetAWSAccountReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasSetAwsAccount() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get getMarketplaceAccounts() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetMarketplaceAccountsReq, 9) as SubscriptionCtlProtoGetMarketplaceAccountsReq;
    }
    set getMarketplaceAccounts(value: SubscriptionCtlProtoGetMarketplaceAccountsReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasGetMarketplaceAccounts() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listCredits() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoListCreditsReq, 10) as SubscriptionCtlProtoListCreditsReq;
    }
    set listCredits(value: SubscriptionCtlProtoListCreditsReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListCredits() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get listDiscounts() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoListDiscountsReq, 11) as SubscriptionCtlProtoListDiscountsReq;
    }
    set listDiscounts(value: SubscriptionCtlProtoListDiscountsReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasListDiscounts() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get getInvoice() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetInvoiceReq, 12) as SubscriptionCtlProtoGetInvoiceReq;
    }
    set getInvoice(value: SubscriptionCtlProtoGetInvoiceReq) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasGetInvoice() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get getUpcomingInvoice() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetUpcomingInvoiceReq, 13) as SubscriptionCtlProtoGetUpcomingInvoiceReq;
    }
    set getUpcomingInvoice(value: SubscriptionCtlProtoGetUpcomingInvoiceReq) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasGetUpcomingInvoice() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get listPreviousInvoices() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoListPreviousInvoicesReq, 14) as SubscriptionCtlProtoListPreviousInvoicesReq;
    }
    set listPreviousInvoices(value: SubscriptionCtlProtoListPreviousInvoicesReq) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
    }
    get hasListPreviousInvoices() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get getMarketplaceAddresses() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetMarketplaceAddressesReq, 15) as SubscriptionCtlProtoGetMarketplaceAddressesReq;
    }
    set getMarketplaceAddresses(value: SubscriptionCtlProtoGetMarketplaceAddressesReq) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
    }
    get hasGetMarketplaceAddresses() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "startAddCreditCard" | "listCreditCards" | "removeCreditCard" | "setDefaultCreditCard" | "setGcpAccount" | "setAzureAccount" | "setAwsAccount" | "getMarketplaceAccounts" | "listCredits" | "listDiscounts" | "getInvoice" | "getUpcomingInvoice" | "listPreviousInvoices" | "getMarketplaceAddresses";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "startAddCreditCard",
            3: "listCreditCards",
            4: "removeCreditCard",
            5: "setDefaultCreditCard",
            6: "setGcpAccount",
            7: "setAzureAccount",
            8: "setAwsAccount",
            9: "getMarketplaceAccounts",
            10: "listCredits",
            11: "listDiscounts",
            12: "getInvoice",
            13: "getUpcomingInvoice",
            14: "listPreviousInvoices",
            15: "getMarketplaceAddresses"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof SubscriptionCtlProtoGetAccessLevelsReq.prototype.toObject>;
        startAddCreditCard?: ReturnType<typeof SubscriptionCtlProtoStartAddCreditCardReq.prototype.toObject>;
        listCreditCards?: ReturnType<typeof SubscriptionCtlProtoListCreditCardsReq.prototype.toObject>;
        removeCreditCard?: ReturnType<typeof SubscriptionCtlProtoRemoveCreditCardReq.prototype.toObject>;
        setDefaultCreditCard?: ReturnType<typeof SubscriptionCtlProtoSetDefaultCreditCardReq.prototype.toObject>;
        setGcpAccount?: ReturnType<typeof SubscriptionCtlProtoSetGCPAccountReq.prototype.toObject>;
        setAzureAccount?: ReturnType<typeof SubscriptionCtlProtoSetAzureAccountReq.prototype.toObject>;
        setAwsAccount?: ReturnType<typeof SubscriptionCtlProtoSetAWSAccountReq.prototype.toObject>;
        getMarketplaceAccounts?: ReturnType<typeof SubscriptionCtlProtoGetMarketplaceAccountsReq.prototype.toObject>;
        listCredits?: ReturnType<typeof SubscriptionCtlProtoListCreditsReq.prototype.toObject>;
        listDiscounts?: ReturnType<typeof SubscriptionCtlProtoListDiscountsReq.prototype.toObject>;
        getInvoice?: ReturnType<typeof SubscriptionCtlProtoGetInvoiceReq.prototype.toObject>;
        getUpcomingInvoice?: ReturnType<typeof SubscriptionCtlProtoGetUpcomingInvoiceReq.prototype.toObject>;
        listPreviousInvoices?: ReturnType<typeof SubscriptionCtlProtoListPreviousInvoicesReq.prototype.toObject>;
        getMarketplaceAddresses?: ReturnType<typeof SubscriptionCtlProtoGetMarketplaceAddressesReq.prototype.toObject>;
    }): SubscriptionCtlProtoReq {
        const message = new SubscriptionCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = SubscriptionCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.startAddCreditCard != null) {
            message.startAddCreditCard = SubscriptionCtlProtoStartAddCreditCardReq.fromObject(data.startAddCreditCard);
        }
        if (data.listCreditCards != null) {
            message.listCreditCards = SubscriptionCtlProtoListCreditCardsReq.fromObject(data.listCreditCards);
        }
        if (data.removeCreditCard != null) {
            message.removeCreditCard = SubscriptionCtlProtoRemoveCreditCardReq.fromObject(data.removeCreditCard);
        }
        if (data.setDefaultCreditCard != null) {
            message.setDefaultCreditCard = SubscriptionCtlProtoSetDefaultCreditCardReq.fromObject(data.setDefaultCreditCard);
        }
        if (data.setGcpAccount != null) {
            message.setGcpAccount = SubscriptionCtlProtoSetGCPAccountReq.fromObject(data.setGcpAccount);
        }
        if (data.setAzureAccount != null) {
            message.setAzureAccount = SubscriptionCtlProtoSetAzureAccountReq.fromObject(data.setAzureAccount);
        }
        if (data.setAwsAccount != null) {
            message.setAwsAccount = SubscriptionCtlProtoSetAWSAccountReq.fromObject(data.setAwsAccount);
        }
        if (data.getMarketplaceAccounts != null) {
            message.getMarketplaceAccounts = SubscriptionCtlProtoGetMarketplaceAccountsReq.fromObject(data.getMarketplaceAccounts);
        }
        if (data.listCredits != null) {
            message.listCredits = SubscriptionCtlProtoListCreditsReq.fromObject(data.listCredits);
        }
        if (data.listDiscounts != null) {
            message.listDiscounts = SubscriptionCtlProtoListDiscountsReq.fromObject(data.listDiscounts);
        }
        if (data.getInvoice != null) {
            message.getInvoice = SubscriptionCtlProtoGetInvoiceReq.fromObject(data.getInvoice);
        }
        if (data.getUpcomingInvoice != null) {
            message.getUpcomingInvoice = SubscriptionCtlProtoGetUpcomingInvoiceReq.fromObject(data.getUpcomingInvoice);
        }
        if (data.listPreviousInvoices != null) {
            message.listPreviousInvoices = SubscriptionCtlProtoListPreviousInvoicesReq.fromObject(data.listPreviousInvoices);
        }
        if (data.getMarketplaceAddresses != null) {
            message.getMarketplaceAddresses = SubscriptionCtlProtoGetMarketplaceAddressesReq.fromObject(data.getMarketplaceAddresses);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof SubscriptionCtlProtoGetAccessLevelsReq.prototype.toObject>;
            startAddCreditCard?: ReturnType<typeof SubscriptionCtlProtoStartAddCreditCardReq.prototype.toObject>;
            listCreditCards?: ReturnType<typeof SubscriptionCtlProtoListCreditCardsReq.prototype.toObject>;
            removeCreditCard?: ReturnType<typeof SubscriptionCtlProtoRemoveCreditCardReq.prototype.toObject>;
            setDefaultCreditCard?: ReturnType<typeof SubscriptionCtlProtoSetDefaultCreditCardReq.prototype.toObject>;
            setGcpAccount?: ReturnType<typeof SubscriptionCtlProtoSetGCPAccountReq.prototype.toObject>;
            setAzureAccount?: ReturnType<typeof SubscriptionCtlProtoSetAzureAccountReq.prototype.toObject>;
            setAwsAccount?: ReturnType<typeof SubscriptionCtlProtoSetAWSAccountReq.prototype.toObject>;
            getMarketplaceAccounts?: ReturnType<typeof SubscriptionCtlProtoGetMarketplaceAccountsReq.prototype.toObject>;
            listCredits?: ReturnType<typeof SubscriptionCtlProtoListCreditsReq.prototype.toObject>;
            listDiscounts?: ReturnType<typeof SubscriptionCtlProtoListDiscountsReq.prototype.toObject>;
            getInvoice?: ReturnType<typeof SubscriptionCtlProtoGetInvoiceReq.prototype.toObject>;
            getUpcomingInvoice?: ReturnType<typeof SubscriptionCtlProtoGetUpcomingInvoiceReq.prototype.toObject>;
            listPreviousInvoices?: ReturnType<typeof SubscriptionCtlProtoListPreviousInvoicesReq.prototype.toObject>;
            getMarketplaceAddresses?: ReturnType<typeof SubscriptionCtlProtoGetMarketplaceAddressesReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.startAddCreditCard != null) {
            data.startAddCreditCard = this.startAddCreditCard.toObject();
        }
        if (this.listCreditCards != null) {
            data.listCreditCards = this.listCreditCards.toObject();
        }
        if (this.removeCreditCard != null) {
            data.removeCreditCard = this.removeCreditCard.toObject();
        }
        if (this.setDefaultCreditCard != null) {
            data.setDefaultCreditCard = this.setDefaultCreditCard.toObject();
        }
        if (this.setGcpAccount != null) {
            data.setGcpAccount = this.setGcpAccount.toObject();
        }
        if (this.setAzureAccount != null) {
            data.setAzureAccount = this.setAzureAccount.toObject();
        }
        if (this.setAwsAccount != null) {
            data.setAwsAccount = this.setAwsAccount.toObject();
        }
        if (this.getMarketplaceAccounts != null) {
            data.getMarketplaceAccounts = this.getMarketplaceAccounts.toObject();
        }
        if (this.listCredits != null) {
            data.listCredits = this.listCredits.toObject();
        }
        if (this.listDiscounts != null) {
            data.listDiscounts = this.listDiscounts.toObject();
        }
        if (this.getInvoice != null) {
            data.getInvoice = this.getInvoice.toObject();
        }
        if (this.getUpcomingInvoice != null) {
            data.getUpcomingInvoice = this.getUpcomingInvoice.toObject();
        }
        if (this.listPreviousInvoices != null) {
            data.listPreviousInvoices = this.listPreviousInvoices.toObject();
        }
        if (this.getMarketplaceAddresses != null) {
            data.getMarketplaceAddresses = this.getMarketplaceAddresses.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasStartAddCreditCard)
            writer.writeMessage(2, this.startAddCreditCard, () => this.startAddCreditCard.serialize(writer));
        if (this.hasListCreditCards)
            writer.writeMessage(3, this.listCreditCards, () => this.listCreditCards.serialize(writer));
        if (this.hasRemoveCreditCard)
            writer.writeMessage(4, this.removeCreditCard, () => this.removeCreditCard.serialize(writer));
        if (this.hasSetDefaultCreditCard)
            writer.writeMessage(5, this.setDefaultCreditCard, () => this.setDefaultCreditCard.serialize(writer));
        if (this.hasSetGcpAccount)
            writer.writeMessage(6, this.setGcpAccount, () => this.setGcpAccount.serialize(writer));
        if (this.hasSetAzureAccount)
            writer.writeMessage(7, this.setAzureAccount, () => this.setAzureAccount.serialize(writer));
        if (this.hasSetAwsAccount)
            writer.writeMessage(8, this.setAwsAccount, () => this.setAwsAccount.serialize(writer));
        if (this.hasGetMarketplaceAccounts)
            writer.writeMessage(9, this.getMarketplaceAccounts, () => this.getMarketplaceAccounts.serialize(writer));
        if (this.hasListCredits)
            writer.writeMessage(10, this.listCredits, () => this.listCredits.serialize(writer));
        if (this.hasListDiscounts)
            writer.writeMessage(11, this.listDiscounts, () => this.listDiscounts.serialize(writer));
        if (this.hasGetInvoice)
            writer.writeMessage(12, this.getInvoice, () => this.getInvoice.serialize(writer));
        if (this.hasGetUpcomingInvoice)
            writer.writeMessage(13, this.getUpcomingInvoice, () => this.getUpcomingInvoice.serialize(writer));
        if (this.hasListPreviousInvoices)
            writer.writeMessage(14, this.listPreviousInvoices, () => this.listPreviousInvoices.serialize(writer));
        if (this.hasGetMarketplaceAddresses)
            writer.writeMessage(15, this.getMarketplaceAddresses, () => this.getMarketplaceAddresses.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = SubscriptionCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.startAddCreditCard, () => message.startAddCreditCard = SubscriptionCtlProtoStartAddCreditCardReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.listCreditCards, () => message.listCreditCards = SubscriptionCtlProtoListCreditCardsReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.removeCreditCard, () => message.removeCreditCard = SubscriptionCtlProtoRemoveCreditCardReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.setDefaultCreditCard, () => message.setDefaultCreditCard = SubscriptionCtlProtoSetDefaultCreditCardReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.setGcpAccount, () => message.setGcpAccount = SubscriptionCtlProtoSetGCPAccountReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.setAzureAccount, () => message.setAzureAccount = SubscriptionCtlProtoSetAzureAccountReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.setAwsAccount, () => message.setAwsAccount = SubscriptionCtlProtoSetAWSAccountReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.getMarketplaceAccounts, () => message.getMarketplaceAccounts = SubscriptionCtlProtoGetMarketplaceAccountsReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listCredits, () => message.listCredits = SubscriptionCtlProtoListCreditsReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.listDiscounts, () => message.listDiscounts = SubscriptionCtlProtoListDiscountsReq.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.getInvoice, () => message.getInvoice = SubscriptionCtlProtoGetInvoiceReq.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.getUpcomingInvoice, () => message.getUpcomingInvoice = SubscriptionCtlProtoGetUpcomingInvoiceReq.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.listPreviousInvoices, () => message.listPreviousInvoices = SubscriptionCtlProtoListPreviousInvoicesReq.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.getMarketplaceAddresses, () => message.getMarketplaceAddresses = SubscriptionCtlProtoGetMarketplaceAddressesReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoReq {
        return SubscriptionCtlProtoReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 9, 10, 11, 12, 13, 14, 15]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: SubscriptionCtlProtoGetAccessLevelsRes;
        startAddCreditCard?: never;
        listCreditCards?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: SubscriptionCtlProtoStartAddCreditCardRes;
        listCreditCards?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: SubscriptionCtlProtoListCreditCardsRes;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        getMarketplaceAccounts?: SubscriptionCtlProtoGetMarketplaceAccountsRes;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        getMarketplaceAccounts?: never;
        listCredits?: SubscriptionCtlProtoListCreditsRes;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: SubscriptionCtlProtoListDiscountsRes;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: SubscriptionCtlProtoGetInvoiceRes;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: SubscriptionCtlProtoGetUpcomingInvoiceRes;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: SubscriptionCtlProtoListPreviousInvoicesRes;
        getMarketplaceAddresses?: never;
    } | {
        getAccessLevels?: never;
        startAddCreditCard?: never;
        listCreditCards?: never;
        getMarketplaceAccounts?: never;
        listCredits?: never;
        listDiscounts?: never;
        getInvoice?: never;
        getUpcomingInvoice?: never;
        listPreviousInvoices?: never;
        getMarketplaceAddresses?: SubscriptionCtlProtoGetMarketplaceAddressesRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("startAddCreditCard" in data && data.startAddCreditCard != undefined) {
                this.startAddCreditCard = data.startAddCreditCard;
            }
            if ("listCreditCards" in data && data.listCreditCards != undefined) {
                this.listCreditCards = data.listCreditCards;
            }
            if ("getMarketplaceAccounts" in data && data.getMarketplaceAccounts != undefined) {
                this.getMarketplaceAccounts = data.getMarketplaceAccounts;
            }
            if ("listCredits" in data && data.listCredits != undefined) {
                this.listCredits = data.listCredits;
            }
            if ("listDiscounts" in data && data.listDiscounts != undefined) {
                this.listDiscounts = data.listDiscounts;
            }
            if ("getInvoice" in data && data.getInvoice != undefined) {
                this.getInvoice = data.getInvoice;
            }
            if ("getUpcomingInvoice" in data && data.getUpcomingInvoice != undefined) {
                this.getUpcomingInvoice = data.getUpcomingInvoice;
            }
            if ("listPreviousInvoices" in data && data.listPreviousInvoices != undefined) {
                this.listPreviousInvoices = data.listPreviousInvoices;
            }
            if ("getMarketplaceAddresses" in data && data.getMarketplaceAddresses != undefined) {
                this.getMarketplaceAddresses = data.getMarketplaceAddresses;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetAccessLevelsRes, 1) as SubscriptionCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: SubscriptionCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get startAddCreditCard() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoStartAddCreditCardRes, 2) as SubscriptionCtlProtoStartAddCreditCardRes;
    }
    set startAddCreditCard(value: SubscriptionCtlProtoStartAddCreditCardRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasStartAddCreditCard() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get listCreditCards() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoListCreditCardsRes, 3) as SubscriptionCtlProtoListCreditCardsRes;
    }
    set listCreditCards(value: SubscriptionCtlProtoListCreditCardsRes) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasListCreditCards() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getMarketplaceAccounts() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetMarketplaceAccountsRes, 9) as SubscriptionCtlProtoGetMarketplaceAccountsRes;
    }
    set getMarketplaceAccounts(value: SubscriptionCtlProtoGetMarketplaceAccountsRes) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasGetMarketplaceAccounts() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get listCredits() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoListCreditsRes, 10) as SubscriptionCtlProtoListCreditsRes;
    }
    set listCredits(value: SubscriptionCtlProtoListCreditsRes) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListCredits() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get listDiscounts() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoListDiscountsRes, 11) as SubscriptionCtlProtoListDiscountsRes;
    }
    set listDiscounts(value: SubscriptionCtlProtoListDiscountsRes) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasListDiscounts() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get getInvoice() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetInvoiceRes, 12) as SubscriptionCtlProtoGetInvoiceRes;
    }
    set getInvoice(value: SubscriptionCtlProtoGetInvoiceRes) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasGetInvoice() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get getUpcomingInvoice() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetUpcomingInvoiceRes, 13) as SubscriptionCtlProtoGetUpcomingInvoiceRes;
    }
    set getUpcomingInvoice(value: SubscriptionCtlProtoGetUpcomingInvoiceRes) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasGetUpcomingInvoice() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get listPreviousInvoices() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoListPreviousInvoicesRes, 14) as SubscriptionCtlProtoListPreviousInvoicesRes;
    }
    set listPreviousInvoices(value: SubscriptionCtlProtoListPreviousInvoicesRes) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
    }
    get hasListPreviousInvoices() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get getMarketplaceAddresses() {
        return pb_1.Message.getWrapperField(this, SubscriptionCtlProtoGetMarketplaceAddressesRes, 15) as SubscriptionCtlProtoGetMarketplaceAddressesRes;
    }
    set getMarketplaceAddresses(value: SubscriptionCtlProtoGetMarketplaceAddressesRes) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
    }
    get hasGetMarketplaceAddresses() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "startAddCreditCard" | "listCreditCards" | "getMarketplaceAccounts" | "listCredits" | "listDiscounts" | "getInvoice" | "getUpcomingInvoice" | "listPreviousInvoices" | "getMarketplaceAddresses";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "startAddCreditCard",
            3: "listCreditCards",
            9: "getMarketplaceAccounts",
            10: "listCredits",
            11: "listDiscounts",
            12: "getInvoice",
            13: "getUpcomingInvoice",
            14: "listPreviousInvoices",
            15: "getMarketplaceAddresses"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 9, 10, 11, 12, 13, 14, 15])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof SubscriptionCtlProtoGetAccessLevelsRes.prototype.toObject>;
        startAddCreditCard?: ReturnType<typeof SubscriptionCtlProtoStartAddCreditCardRes.prototype.toObject>;
        listCreditCards?: ReturnType<typeof SubscriptionCtlProtoListCreditCardsRes.prototype.toObject>;
        getMarketplaceAccounts?: ReturnType<typeof SubscriptionCtlProtoGetMarketplaceAccountsRes.prototype.toObject>;
        listCredits?: ReturnType<typeof SubscriptionCtlProtoListCreditsRes.prototype.toObject>;
        listDiscounts?: ReturnType<typeof SubscriptionCtlProtoListDiscountsRes.prototype.toObject>;
        getInvoice?: ReturnType<typeof SubscriptionCtlProtoGetInvoiceRes.prototype.toObject>;
        getUpcomingInvoice?: ReturnType<typeof SubscriptionCtlProtoGetUpcomingInvoiceRes.prototype.toObject>;
        listPreviousInvoices?: ReturnType<typeof SubscriptionCtlProtoListPreviousInvoicesRes.prototype.toObject>;
        getMarketplaceAddresses?: ReturnType<typeof SubscriptionCtlProtoGetMarketplaceAddressesRes.prototype.toObject>;
    }): SubscriptionCtlProtoRes {
        const message = new SubscriptionCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = SubscriptionCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.startAddCreditCard != null) {
            message.startAddCreditCard = SubscriptionCtlProtoStartAddCreditCardRes.fromObject(data.startAddCreditCard);
        }
        if (data.listCreditCards != null) {
            message.listCreditCards = SubscriptionCtlProtoListCreditCardsRes.fromObject(data.listCreditCards);
        }
        if (data.getMarketplaceAccounts != null) {
            message.getMarketplaceAccounts = SubscriptionCtlProtoGetMarketplaceAccountsRes.fromObject(data.getMarketplaceAccounts);
        }
        if (data.listCredits != null) {
            message.listCredits = SubscriptionCtlProtoListCreditsRes.fromObject(data.listCredits);
        }
        if (data.listDiscounts != null) {
            message.listDiscounts = SubscriptionCtlProtoListDiscountsRes.fromObject(data.listDiscounts);
        }
        if (data.getInvoice != null) {
            message.getInvoice = SubscriptionCtlProtoGetInvoiceRes.fromObject(data.getInvoice);
        }
        if (data.getUpcomingInvoice != null) {
            message.getUpcomingInvoice = SubscriptionCtlProtoGetUpcomingInvoiceRes.fromObject(data.getUpcomingInvoice);
        }
        if (data.listPreviousInvoices != null) {
            message.listPreviousInvoices = SubscriptionCtlProtoListPreviousInvoicesRes.fromObject(data.listPreviousInvoices);
        }
        if (data.getMarketplaceAddresses != null) {
            message.getMarketplaceAddresses = SubscriptionCtlProtoGetMarketplaceAddressesRes.fromObject(data.getMarketplaceAddresses);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof SubscriptionCtlProtoGetAccessLevelsRes.prototype.toObject>;
            startAddCreditCard?: ReturnType<typeof SubscriptionCtlProtoStartAddCreditCardRes.prototype.toObject>;
            listCreditCards?: ReturnType<typeof SubscriptionCtlProtoListCreditCardsRes.prototype.toObject>;
            getMarketplaceAccounts?: ReturnType<typeof SubscriptionCtlProtoGetMarketplaceAccountsRes.prototype.toObject>;
            listCredits?: ReturnType<typeof SubscriptionCtlProtoListCreditsRes.prototype.toObject>;
            listDiscounts?: ReturnType<typeof SubscriptionCtlProtoListDiscountsRes.prototype.toObject>;
            getInvoice?: ReturnType<typeof SubscriptionCtlProtoGetInvoiceRes.prototype.toObject>;
            getUpcomingInvoice?: ReturnType<typeof SubscriptionCtlProtoGetUpcomingInvoiceRes.prototype.toObject>;
            listPreviousInvoices?: ReturnType<typeof SubscriptionCtlProtoListPreviousInvoicesRes.prototype.toObject>;
            getMarketplaceAddresses?: ReturnType<typeof SubscriptionCtlProtoGetMarketplaceAddressesRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.startAddCreditCard != null) {
            data.startAddCreditCard = this.startAddCreditCard.toObject();
        }
        if (this.listCreditCards != null) {
            data.listCreditCards = this.listCreditCards.toObject();
        }
        if (this.getMarketplaceAccounts != null) {
            data.getMarketplaceAccounts = this.getMarketplaceAccounts.toObject();
        }
        if (this.listCredits != null) {
            data.listCredits = this.listCredits.toObject();
        }
        if (this.listDiscounts != null) {
            data.listDiscounts = this.listDiscounts.toObject();
        }
        if (this.getInvoice != null) {
            data.getInvoice = this.getInvoice.toObject();
        }
        if (this.getUpcomingInvoice != null) {
            data.getUpcomingInvoice = this.getUpcomingInvoice.toObject();
        }
        if (this.listPreviousInvoices != null) {
            data.listPreviousInvoices = this.listPreviousInvoices.toObject();
        }
        if (this.getMarketplaceAddresses != null) {
            data.getMarketplaceAddresses = this.getMarketplaceAddresses.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasStartAddCreditCard)
            writer.writeMessage(2, this.startAddCreditCard, () => this.startAddCreditCard.serialize(writer));
        if (this.hasListCreditCards)
            writer.writeMessage(3, this.listCreditCards, () => this.listCreditCards.serialize(writer));
        if (this.hasGetMarketplaceAccounts)
            writer.writeMessage(9, this.getMarketplaceAccounts, () => this.getMarketplaceAccounts.serialize(writer));
        if (this.hasListCredits)
            writer.writeMessage(10, this.listCredits, () => this.listCredits.serialize(writer));
        if (this.hasListDiscounts)
            writer.writeMessage(11, this.listDiscounts, () => this.listDiscounts.serialize(writer));
        if (this.hasGetInvoice)
            writer.writeMessage(12, this.getInvoice, () => this.getInvoice.serialize(writer));
        if (this.hasGetUpcomingInvoice)
            writer.writeMessage(13, this.getUpcomingInvoice, () => this.getUpcomingInvoice.serialize(writer));
        if (this.hasListPreviousInvoices)
            writer.writeMessage(14, this.listPreviousInvoices, () => this.listPreviousInvoices.serialize(writer));
        if (this.hasGetMarketplaceAddresses)
            writer.writeMessage(15, this.getMarketplaceAddresses, () => this.getMarketplaceAddresses.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = SubscriptionCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.startAddCreditCard, () => message.startAddCreditCard = SubscriptionCtlProtoStartAddCreditCardRes.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.listCreditCards, () => message.listCreditCards = SubscriptionCtlProtoListCreditCardsRes.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.getMarketplaceAccounts, () => message.getMarketplaceAccounts = SubscriptionCtlProtoGetMarketplaceAccountsRes.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listCredits, () => message.listCredits = SubscriptionCtlProtoListCreditsRes.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.listDiscounts, () => message.listDiscounts = SubscriptionCtlProtoListDiscountsRes.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.getInvoice, () => message.getInvoice = SubscriptionCtlProtoGetInvoiceRes.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.getUpcomingInvoice, () => message.getUpcomingInvoice = SubscriptionCtlProtoGetUpcomingInvoiceRes.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.listPreviousInvoices, () => message.listPreviousInvoices = SubscriptionCtlProtoListPreviousInvoicesRes.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.getMarketplaceAddresses, () => message.getMarketplaceAddresses = SubscriptionCtlProtoGetMarketplaceAddressesRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoRes {
        return SubscriptionCtlProtoRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoGetAccessLevels {
        const message = new SubscriptionCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetAccessLevels {
        return SubscriptionCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuids" in data && data.orgUuids != undefined) {
                this.orgUuids = data.orgUuids;
            }
        }
    }
    get orgUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set orgUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuids?: Uint8Array[];
    }): SubscriptionCtlProtoGetAccessLevelsReq {
        const message = new SubscriptionCtlProtoGetAccessLevelsReq({});
        if (data.orgUuids != null) {
            message.orgUuids = data.orgUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuids?: Uint8Array[];
        } = {};
        if (this.orgUuids != null) {
            data.orgUuids = this.orgUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuids.length)
            writer.writeRepeatedBytes(1, this.orgUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetAccessLevelsReq {
        return SubscriptionCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_1.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_1.AccessLevelProto[];
    }
    set accessLevels(value: dependency_1.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_1.AccessLevelProto[];
    }): SubscriptionCtlProtoGetAccessLevelsRes {
        const message = new SubscriptionCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_1.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetAccessLevelsRes {
        return SubscriptionCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoStartAddCreditCard extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoStartAddCreditCard {
        const message = new SubscriptionCtlProtoStartAddCreditCard({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoStartAddCreditCard {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoStartAddCreditCard();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoStartAddCreditCard {
        return SubscriptionCtlProtoStartAddCreditCard.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoStartAddCreditCardReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): SubscriptionCtlProtoStartAddCreditCardReq {
        const message = new SubscriptionCtlProtoStartAddCreditCardReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoStartAddCreditCardReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoStartAddCreditCardReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoStartAddCreditCardReq {
        return SubscriptionCtlProtoStartAddCreditCardReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoStartAddCreditCardRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: string;
        apiKey?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
            if ("apiKey" in data && data.apiKey != undefined) {
                this.apiKey = data.apiKey;
            }
        }
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get apiKey() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set apiKey(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        token?: string;
        apiKey?: string;
    }): SubscriptionCtlProtoStartAddCreditCardRes {
        const message = new SubscriptionCtlProtoStartAddCreditCardRes({});
        if (data.token != null) {
            message.token = data.token;
        }
        if (data.apiKey != null) {
            message.apiKey = data.apiKey;
        }
        return message;
    }
    toObject() {
        const data: {
            token?: string;
            apiKey?: string;
        } = {};
        if (this.token != null) {
            data.token = this.token;
        }
        if (this.apiKey != null) {
            data.apiKey = this.apiKey;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.token.length)
            writer.writeString(1, this.token);
        if (this.apiKey.length)
            writer.writeString(2, this.apiKey);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoStartAddCreditCardRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoStartAddCreditCardRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.token = reader.readString();
                    break;
                case 2:
                    message.apiKey = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoStartAddCreditCardRes {
        return SubscriptionCtlProtoStartAddCreditCardRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListCreditCards extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoListCreditCards {
        const message = new SubscriptionCtlProtoListCreditCards({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListCreditCards {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListCreditCards();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListCreditCards {
        return SubscriptionCtlProtoListCreditCards.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListCreditCardsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): SubscriptionCtlProtoListCreditCardsReq {
        const message = new SubscriptionCtlProtoListCreditCardsReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListCreditCardsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListCreditCardsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListCreditCardsReq {
        return SubscriptionCtlProtoListCreditCardsReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListCreditCardsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        creditCards?: dependency_1.CreditCardProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("creditCards" in data && data.creditCards != undefined) {
                this.creditCards = data.creditCards;
            }
        }
    }
    get creditCards() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.CreditCardProto, 1) as dependency_1.CreditCardProto[];
    }
    set creditCards(value: dependency_1.CreditCardProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        creditCards?: ReturnType<typeof dependency_1.CreditCardProto.prototype.toObject>[];
    }): SubscriptionCtlProtoListCreditCardsRes {
        const message = new SubscriptionCtlProtoListCreditCardsRes({});
        if (data.creditCards != null) {
            message.creditCards = data.creditCards.map(item => dependency_1.CreditCardProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            creditCards?: ReturnType<typeof dependency_1.CreditCardProto.prototype.toObject>[];
        } = {};
        if (this.creditCards != null) {
            data.creditCards = this.creditCards.map((item: dependency_1.CreditCardProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.creditCards.length)
            writer.writeRepeatedMessage(1, this.creditCards, (item: dependency_1.CreditCardProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListCreditCardsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListCreditCardsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.creditCards, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.CreditCardProto.deserialize(reader), dependency_1.CreditCardProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListCreditCardsRes {
        return SubscriptionCtlProtoListCreditCardsRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoRemoveCreditCard extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoRemoveCreditCard {
        const message = new SubscriptionCtlProtoRemoveCreditCard({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoRemoveCreditCard {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoRemoveCreditCard();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoRemoveCreditCard {
        return SubscriptionCtlProtoRemoveCreditCard.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoRemoveCreditCardReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        creditCardId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("creditCardId" in data && data.creditCardId != undefined) {
                this.creditCardId = data.creditCardId;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get creditCardId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set creditCardId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        creditCardId?: string;
    }): SubscriptionCtlProtoRemoveCreditCardReq {
        const message = new SubscriptionCtlProtoRemoveCreditCardReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.creditCardId != null) {
            message.creditCardId = data.creditCardId;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            creditCardId?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.creditCardId != null) {
            data.creditCardId = this.creditCardId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.creditCardId.length)
            writer.writeString(2, this.creditCardId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoRemoveCreditCardReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoRemoveCreditCardReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.creditCardId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoRemoveCreditCardReq {
        return SubscriptionCtlProtoRemoveCreditCardReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoSetDefaultCreditCard extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoSetDefaultCreditCard {
        const message = new SubscriptionCtlProtoSetDefaultCreditCard({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoSetDefaultCreditCard {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoSetDefaultCreditCard();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoSetDefaultCreditCard {
        return SubscriptionCtlProtoSetDefaultCreditCard.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoSetDefaultCreditCardReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        creditCardId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("creditCardId" in data && data.creditCardId != undefined) {
                this.creditCardId = data.creditCardId;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get creditCardId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set creditCardId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        creditCardId?: string;
    }): SubscriptionCtlProtoSetDefaultCreditCardReq {
        const message = new SubscriptionCtlProtoSetDefaultCreditCardReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.creditCardId != null) {
            message.creditCardId = data.creditCardId;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            creditCardId?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.creditCardId != null) {
            data.creditCardId = this.creditCardId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.creditCardId.length)
            writer.writeString(2, this.creditCardId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoSetDefaultCreditCardReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoSetDefaultCreditCardReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.creditCardId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoSetDefaultCreditCardReq {
        return SubscriptionCtlProtoSetDefaultCreditCardReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoSetGCPAccount extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoSetGCPAccount {
        const message = new SubscriptionCtlProtoSetGCPAccount({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoSetGCPAccount {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoSetGCPAccount();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoSetGCPAccount {
        return SubscriptionCtlProtoSetGCPAccount.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoSetGCPAccountReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        jwt?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("jwt" in data && data.jwt != undefined) {
                this.jwt = data.jwt;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get jwt() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set jwt(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        jwt?: string;
    }): SubscriptionCtlProtoSetGCPAccountReq {
        const message = new SubscriptionCtlProtoSetGCPAccountReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.jwt != null) {
            message.jwt = data.jwt;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            jwt?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.jwt != null) {
            data.jwt = this.jwt;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.jwt.length)
            writer.writeString(2, this.jwt);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoSetGCPAccountReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoSetGCPAccountReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.jwt = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoSetGCPAccountReq {
        return SubscriptionCtlProtoSetGCPAccountReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoSetAzureAccount extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoSetAzureAccount {
        const message = new SubscriptionCtlProtoSetAzureAccount({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoSetAzureAccount {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoSetAzureAccount();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoSetAzureAccount {
        return SubscriptionCtlProtoSetAzureAccount.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoSetAzureAccountReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        token?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        token?: string;
    }): SubscriptionCtlProtoSetAzureAccountReq {
        const message = new SubscriptionCtlProtoSetAzureAccountReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.token != null) {
            message.token = data.token;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            token?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.token != null) {
            data.token = this.token;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.token.length)
            writer.writeString(2, this.token);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoSetAzureAccountReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoSetAzureAccountReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.token = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoSetAzureAccountReq {
        return SubscriptionCtlProtoSetAzureAccountReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoSetAWSAccount extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoSetAWSAccount {
        const message = new SubscriptionCtlProtoSetAWSAccount({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoSetAWSAccount {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoSetAWSAccount();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoSetAWSAccount {
        return SubscriptionCtlProtoSetAWSAccount.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoSetAWSAccountReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        token?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        token?: string;
    }): SubscriptionCtlProtoSetAWSAccountReq {
        const message = new SubscriptionCtlProtoSetAWSAccountReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.token != null) {
            message.token = data.token;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            token?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.token != null) {
            data.token = this.token;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.token.length)
            writer.writeString(2, this.token);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoSetAWSAccountReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoSetAWSAccountReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.token = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoSetAWSAccountReq {
        return SubscriptionCtlProtoSetAWSAccountReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetMarketplaceAccounts extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoGetMarketplaceAccounts {
        const message = new SubscriptionCtlProtoGetMarketplaceAccounts({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetMarketplaceAccounts {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetMarketplaceAccounts();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetMarketplaceAccounts {
        return SubscriptionCtlProtoGetMarketplaceAccounts.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetMarketplaceAccountsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): SubscriptionCtlProtoGetMarketplaceAccountsReq {
        const message = new SubscriptionCtlProtoGetMarketplaceAccountsReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetMarketplaceAccountsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetMarketplaceAccountsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetMarketplaceAccountsReq {
        return SubscriptionCtlProtoGetMarketplaceAccountsReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetMarketplaceAccountsRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1], [2], [3]];
    constructor(data?: any[] | ({} & (({
        gcpAccount?: dependency_1.GCPAccountProto;
    }) | ({
        azureAccount?: dependency_1.AzureAccountProto;
    }) | ({
        awsAccount?: dependency_1.AWSAccountProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("gcpAccount" in data && data.gcpAccount != undefined) {
                this.gcpAccount = data.gcpAccount;
            }
            if ("azureAccount" in data && data.azureAccount != undefined) {
                this.azureAccount = data.azureAccount;
            }
            if ("awsAccount" in data && data.awsAccount != undefined) {
                this.awsAccount = data.awsAccount;
            }
        }
    }
    get gcpAccount() {
        return pb_1.Message.getWrapperField(this, dependency_1.GCPAccountProto, 1) as dependency_1.GCPAccountProto;
    }
    set gcpAccount(value: dependency_1.GCPAccountProto) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGcpAccount() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get azureAccount() {
        return pb_1.Message.getWrapperField(this, dependency_1.AzureAccountProto, 2) as dependency_1.AzureAccountProto;
    }
    set azureAccount(value: dependency_1.AzureAccountProto) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[1], value);
    }
    get hasAzureAccount() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get awsAccount() {
        return pb_1.Message.getWrapperField(this, dependency_1.AWSAccountProto, 3) as dependency_1.AWSAccountProto;
    }
    set awsAccount(value: dependency_1.AWSAccountProto) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[2], value);
    }
    get hasAwsAccount() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get _gcp_account() {
        const cases: {
            [index: number]: "none" | "gcpAccount";
        } = {
            0: "none",
            1: "gcpAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    get _azure_account() {
        const cases: {
            [index: number]: "none" | "azureAccount";
        } = {
            0: "none",
            2: "azureAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    get _aws_account() {
        const cases: {
            [index: number]: "none" | "awsAccount";
        } = {
            0: "none",
            3: "awsAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3])];
    }
    static fromObject(data: {
        gcpAccount?: ReturnType<typeof dependency_1.GCPAccountProto.prototype.toObject>;
        azureAccount?: ReturnType<typeof dependency_1.AzureAccountProto.prototype.toObject>;
        awsAccount?: ReturnType<typeof dependency_1.AWSAccountProto.prototype.toObject>;
    }): SubscriptionCtlProtoGetMarketplaceAccountsRes {
        const message = new SubscriptionCtlProtoGetMarketplaceAccountsRes({});
        if (data.gcpAccount != null) {
            message.gcpAccount = dependency_1.GCPAccountProto.fromObject(data.gcpAccount);
        }
        if (data.azureAccount != null) {
            message.azureAccount = dependency_1.AzureAccountProto.fromObject(data.azureAccount);
        }
        if (data.awsAccount != null) {
            message.awsAccount = dependency_1.AWSAccountProto.fromObject(data.awsAccount);
        }
        return message;
    }
    toObject() {
        const data: {
            gcpAccount?: ReturnType<typeof dependency_1.GCPAccountProto.prototype.toObject>;
            azureAccount?: ReturnType<typeof dependency_1.AzureAccountProto.prototype.toObject>;
            awsAccount?: ReturnType<typeof dependency_1.AWSAccountProto.prototype.toObject>;
        } = {};
        if (this.gcpAccount != null) {
            data.gcpAccount = this.gcpAccount.toObject();
        }
        if (this.azureAccount != null) {
            data.azureAccount = this.azureAccount.toObject();
        }
        if (this.awsAccount != null) {
            data.awsAccount = this.awsAccount.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGcpAccount)
            writer.writeMessage(1, this.gcpAccount, () => this.gcpAccount.serialize(writer));
        if (this.hasAzureAccount)
            writer.writeMessage(2, this.azureAccount, () => this.azureAccount.serialize(writer));
        if (this.hasAwsAccount)
            writer.writeMessage(3, this.awsAccount, () => this.awsAccount.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetMarketplaceAccountsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetMarketplaceAccountsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.gcpAccount, () => message.gcpAccount = dependency_1.GCPAccountProto.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.azureAccount, () => message.azureAccount = dependency_1.AzureAccountProto.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.awsAccount, () => message.awsAccount = dependency_1.AWSAccountProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetMarketplaceAccountsRes {
        return SubscriptionCtlProtoGetMarketplaceAccountsRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListCredits extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoListCredits {
        const message = new SubscriptionCtlProtoListCredits({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListCredits {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListCredits();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListCredits {
        return SubscriptionCtlProtoListCredits.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListCreditsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): SubscriptionCtlProtoListCreditsReq {
        const message = new SubscriptionCtlProtoListCreditsReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListCreditsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListCreditsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListCreditsReq {
        return SubscriptionCtlProtoListCreditsReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListCreditsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        credits?: dependency_1.CreditsProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("credits" in data && data.credits != undefined) {
                this.credits = data.credits;
            }
        }
    }
    get credits() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.CreditsProto, 1) as dependency_1.CreditsProto[];
    }
    set credits(value: dependency_1.CreditsProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        credits?: ReturnType<typeof dependency_1.CreditsProto.prototype.toObject>[];
    }): SubscriptionCtlProtoListCreditsRes {
        const message = new SubscriptionCtlProtoListCreditsRes({});
        if (data.credits != null) {
            message.credits = data.credits.map(item => dependency_1.CreditsProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            credits?: ReturnType<typeof dependency_1.CreditsProto.prototype.toObject>[];
        } = {};
        if (this.credits != null) {
            data.credits = this.credits.map((item: dependency_1.CreditsProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.credits.length)
            writer.writeRepeatedMessage(1, this.credits, (item: dependency_1.CreditsProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListCreditsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListCreditsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.credits, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.CreditsProto.deserialize(reader), dependency_1.CreditsProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListCreditsRes {
        return SubscriptionCtlProtoListCreditsRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListDiscounts extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoListDiscounts {
        const message = new SubscriptionCtlProtoListDiscounts({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListDiscounts {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListDiscounts();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListDiscounts {
        return SubscriptionCtlProtoListDiscounts.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListDiscountsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        params?: dependency_2.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_2.ParametersProto, 2) as dependency_2.ParametersProto;
    }
    set params(value: dependency_2.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
    }): SubscriptionCtlProtoListDiscountsReq {
        const message = new SubscriptionCtlProtoListDiscountsReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_2.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_2.ParametersProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListDiscountsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListDiscountsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_2.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListDiscountsReq {
        return SubscriptionCtlProtoListDiscountsReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListDiscountsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        discounts?: dependency_1.DiscountProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("discounts" in data && data.discounts != undefined) {
                this.discounts = data.discounts;
            }
        }
    }
    get discounts() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.DiscountProto, 1) as dependency_1.DiscountProto[];
    }
    set discounts(value: dependency_1.DiscountProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        discounts?: ReturnType<typeof dependency_1.DiscountProto.prototype.toObject>[];
    }): SubscriptionCtlProtoListDiscountsRes {
        const message = new SubscriptionCtlProtoListDiscountsRes({});
        if (data.discounts != null) {
            message.discounts = data.discounts.map(item => dependency_1.DiscountProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            discounts?: ReturnType<typeof dependency_1.DiscountProto.prototype.toObject>[];
        } = {};
        if (this.discounts != null) {
            data.discounts = this.discounts.map((item: dependency_1.DiscountProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.discounts.length)
            writer.writeRepeatedMessage(1, this.discounts, (item: dependency_1.DiscountProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListDiscountsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListDiscountsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.discounts, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.DiscountProto.deserialize(reader), dependency_1.DiscountProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListDiscountsRes {
        return SubscriptionCtlProtoListDiscountsRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetInvoice extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoGetInvoice {
        const message = new SubscriptionCtlProtoGetInvoice({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetInvoice {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetInvoice();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetInvoice {
        return SubscriptionCtlProtoGetInvoice.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetInvoiceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        invoiceNumber?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("invoiceNumber" in data && data.invoiceNumber != undefined) {
                this.invoiceNumber = data.invoiceNumber;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get invoiceNumber() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set invoiceNumber(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        invoiceNumber?: number;
    }): SubscriptionCtlProtoGetInvoiceReq {
        const message = new SubscriptionCtlProtoGetInvoiceReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.invoiceNumber != null) {
            message.invoiceNumber = data.invoiceNumber;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            invoiceNumber?: number;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.invoiceNumber != null) {
            data.invoiceNumber = this.invoiceNumber;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.invoiceNumber != 0)
            writer.writeInt32(2, this.invoiceNumber);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetInvoiceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetInvoiceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.invoiceNumber = reader.readInt32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetInvoiceReq {
        return SubscriptionCtlProtoGetInvoiceReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetInvoiceRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invoice?: dependency_1.InvoiceProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invoice" in data && data.invoice != undefined) {
                this.invoice = data.invoice;
            }
        }
    }
    get invoice() {
        return pb_1.Message.getWrapperField(this, dependency_1.InvoiceProto, 1) as dependency_1.InvoiceProto;
    }
    set invoice(value: dependency_1.InvoiceProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasInvoice() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        invoice?: ReturnType<typeof dependency_1.InvoiceProto.prototype.toObject>;
    }): SubscriptionCtlProtoGetInvoiceRes {
        const message = new SubscriptionCtlProtoGetInvoiceRes({});
        if (data.invoice != null) {
            message.invoice = dependency_1.InvoiceProto.fromObject(data.invoice);
        }
        return message;
    }
    toObject() {
        const data: {
            invoice?: ReturnType<typeof dependency_1.InvoiceProto.prototype.toObject>;
        } = {};
        if (this.invoice != null) {
            data.invoice = this.invoice.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasInvoice)
            writer.writeMessage(1, this.invoice, () => this.invoice.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetInvoiceRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetInvoiceRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.invoice, () => message.invoice = dependency_1.InvoiceProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetInvoiceRes {
        return SubscriptionCtlProtoGetInvoiceRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetUpcomingInvoice extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoGetUpcomingInvoice {
        const message = new SubscriptionCtlProtoGetUpcomingInvoice({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetUpcomingInvoice {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetUpcomingInvoice();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetUpcomingInvoice {
        return SubscriptionCtlProtoGetUpcomingInvoice.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetUpcomingInvoiceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): SubscriptionCtlProtoGetUpcomingInvoiceReq {
        const message = new SubscriptionCtlProtoGetUpcomingInvoiceReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetUpcomingInvoiceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetUpcomingInvoiceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetUpcomingInvoiceReq {
        return SubscriptionCtlProtoGetUpcomingInvoiceReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetUpcomingInvoiceRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invoice?: dependency_1.InvoiceProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invoice" in data && data.invoice != undefined) {
                this.invoice = data.invoice;
            }
        }
    }
    get invoice() {
        return pb_1.Message.getWrapperField(this, dependency_1.InvoiceProto, 1) as dependency_1.InvoiceProto;
    }
    set invoice(value: dependency_1.InvoiceProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasInvoice() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        invoice?: ReturnType<typeof dependency_1.InvoiceProto.prototype.toObject>;
    }): SubscriptionCtlProtoGetUpcomingInvoiceRes {
        const message = new SubscriptionCtlProtoGetUpcomingInvoiceRes({});
        if (data.invoice != null) {
            message.invoice = dependency_1.InvoiceProto.fromObject(data.invoice);
        }
        return message;
    }
    toObject() {
        const data: {
            invoice?: ReturnType<typeof dependency_1.InvoiceProto.prototype.toObject>;
        } = {};
        if (this.invoice != null) {
            data.invoice = this.invoice.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasInvoice)
            writer.writeMessage(1, this.invoice, () => this.invoice.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetUpcomingInvoiceRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetUpcomingInvoiceRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.invoice, () => message.invoice = dependency_1.InvoiceProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetUpcomingInvoiceRes {
        return SubscriptionCtlProtoGetUpcomingInvoiceRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListPreviousInvoices extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoListPreviousInvoices {
        const message = new SubscriptionCtlProtoListPreviousInvoices({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListPreviousInvoices {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListPreviousInvoices();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListPreviousInvoices {
        return SubscriptionCtlProtoListPreviousInvoices.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListPreviousInvoicesReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): SubscriptionCtlProtoListPreviousInvoicesReq {
        const message = new SubscriptionCtlProtoListPreviousInvoicesReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListPreviousInvoicesReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListPreviousInvoicesReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListPreviousInvoicesReq {
        return SubscriptionCtlProtoListPreviousInvoicesReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoListPreviousInvoicesRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invoices?: dependency_1.InvoiceProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invoices" in data && data.invoices != undefined) {
                this.invoices = data.invoices;
            }
        }
    }
    get invoices() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.InvoiceProto, 1) as dependency_1.InvoiceProto[];
    }
    set invoices(value: dependency_1.InvoiceProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        invoices?: ReturnType<typeof dependency_1.InvoiceProto.prototype.toObject>[];
    }): SubscriptionCtlProtoListPreviousInvoicesRes {
        const message = new SubscriptionCtlProtoListPreviousInvoicesRes({});
        if (data.invoices != null) {
            message.invoices = data.invoices.map(item => dependency_1.InvoiceProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            invoices?: ReturnType<typeof dependency_1.InvoiceProto.prototype.toObject>[];
        } = {};
        if (this.invoices != null) {
            data.invoices = this.invoices.map((item: dependency_1.InvoiceProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invoices.length)
            writer.writeRepeatedMessage(1, this.invoices, (item: dependency_1.InvoiceProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoListPreviousInvoicesRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoListPreviousInvoicesRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.invoices, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.InvoiceProto.deserialize(reader), dependency_1.InvoiceProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoListPreviousInvoicesRes {
        return SubscriptionCtlProtoListPreviousInvoicesRes.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetMarketplaceAddresses extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoGetMarketplaceAddresses {
        const message = new SubscriptionCtlProtoGetMarketplaceAddresses({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetMarketplaceAddresses {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetMarketplaceAddresses();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetMarketplaceAddresses {
        return SubscriptionCtlProtoGetMarketplaceAddresses.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetMarketplaceAddressesReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): SubscriptionCtlProtoGetMarketplaceAddressesReq {
        const message = new SubscriptionCtlProtoGetMarketplaceAddressesReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetMarketplaceAddressesReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetMarketplaceAddressesReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetMarketplaceAddressesReq {
        return SubscriptionCtlProtoGetMarketplaceAddressesReq.deserialize(bytes);
    }
}
export class SubscriptionCtlProtoGetMarketplaceAddressesRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        gcp?: string;
        azure?: string;
        aws?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("gcp" in data && data.gcp != undefined) {
                this.gcp = data.gcp;
            }
            if ("azure" in data && data.azure != undefined) {
                this.azure = data.azure;
            }
            if ("aws" in data && data.aws != undefined) {
                this.aws = data.aws;
            }
        }
    }
    get gcp() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set gcp(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get azure() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set azure(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get aws() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set aws(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        gcp?: string;
        azure?: string;
        aws?: string;
    }): SubscriptionCtlProtoGetMarketplaceAddressesRes {
        const message = new SubscriptionCtlProtoGetMarketplaceAddressesRes({});
        if (data.gcp != null) {
            message.gcp = data.gcp;
        }
        if (data.azure != null) {
            message.azure = data.azure;
        }
        if (data.aws != null) {
            message.aws = data.aws;
        }
        return message;
    }
    toObject() {
        const data: {
            gcp?: string;
            azure?: string;
            aws?: string;
        } = {};
        if (this.gcp != null) {
            data.gcp = this.gcp;
        }
        if (this.azure != null) {
            data.azure = this.azure;
        }
        if (this.aws != null) {
            data.aws = this.aws;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.gcp.length)
            writer.writeString(1, this.gcp);
        if (this.azure.length)
            writer.writeString(2, this.azure);
        if (this.aws.length)
            writer.writeString(3, this.aws);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionCtlProtoGetMarketplaceAddressesRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionCtlProtoGetMarketplaceAddressesRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.gcp = reader.readString();
                    break;
                case 2:
                    message.azure = reader.readString();
                    break;
                case 3:
                    message.aws = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubscriptionCtlProtoGetMarketplaceAddressesRes {
        return SubscriptionCtlProtoGetMarketplaceAddressesRes.deserialize(bytes);
    }
}
