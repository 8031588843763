/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: protocol/common.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export enum AccountStatusProto {
    INVALID = 0,
    UNVERIFIED = 1,
    VERIFIED = 2,
    USER_CREATED = 3,
    SURVEY_COMPLETED = 4,
    PROFILE_COMPLETED = 5,
    ORG_JOINED_NOT_SURVEYED = 100
}
export class StatusProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): StatusProto {
        const message = new StatusProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StatusProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StatusProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): StatusProto {
        return StatusProto.deserialize(bytes);
    }
}
export class StatusProtoSuccess extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): StatusProtoSuccess {
        const message = new StatusProtoSuccess({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StatusProtoSuccess {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StatusProtoSuccess();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): StatusProtoSuccess {
        return StatusProtoSuccess.deserialize(bytes);
    }
}
export class StatusProtoError extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        code?: string;
        message?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("code" in data && data.code != undefined) {
                this.code = data.code;
            }
            if ("message" in data && data.message != undefined) {
                this.message = data.message;
            }
        }
    }
    get code() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set code(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get message() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set message(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        code?: string;
        message?: string;
    }): StatusProtoError {
        const message = new StatusProtoError({});
        if (data.code != null) {
            message.code = data.code;
        }
        if (data.message != null) {
            message.message = data.message;
        }
        return message;
    }
    toObject() {
        const data: {
            code?: string;
            message?: string;
        } = {};
        if (this.code != null) {
            data.code = this.code;
        }
        if (this.message != null) {
            data.message = this.message;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.code.length)
            writer.writeString(1, this.code);
        if (this.message.length)
            writer.writeString(2, this.message);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StatusProtoError {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StatusProtoError();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.code = reader.readString();
                    break;
                case 2:
                    message.message = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): StatusProtoError {
        return StatusProtoError.deserialize(bytes);
    }
}
export class ParametersProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        filters?: ParametersProtoFilter[];
        sorting?: ParametersProtoSorting;
        pagination?: ParametersProtoPagination;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("filters" in data && data.filters != undefined) {
                this.filters = data.filters;
            }
            if ("sorting" in data && data.sorting != undefined) {
                this.sorting = data.sorting;
            }
            if ("pagination" in data && data.pagination != undefined) {
                this.pagination = data.pagination;
            }
        }
    }
    get filters() {
        return pb_1.Message.getRepeatedWrapperField(this, ParametersProtoFilter, 1) as ParametersProtoFilter[];
    }
    set filters(value: ParametersProtoFilter[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get sorting() {
        return pb_1.Message.getWrapperField(this, ParametersProtoSorting, 2) as ParametersProtoSorting;
    }
    set sorting(value: ParametersProtoSorting) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasSorting() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get pagination() {
        return pb_1.Message.getWrapperField(this, ParametersProtoPagination, 3) as ParametersProtoPagination;
    }
    set pagination(value: ParametersProtoPagination) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasPagination() {
        return pb_1.Message.getField(this, 3) != null;
    }
    static fromObject(data: {
        filters?: ReturnType<typeof ParametersProtoFilter.prototype.toObject>[];
        sorting?: ReturnType<typeof ParametersProtoSorting.prototype.toObject>;
        pagination?: ReturnType<typeof ParametersProtoPagination.prototype.toObject>;
    }): ParametersProto {
        const message = new ParametersProto({});
        if (data.filters != null) {
            message.filters = data.filters.map(item => ParametersProtoFilter.fromObject(item));
        }
        if (data.sorting != null) {
            message.sorting = ParametersProtoSorting.fromObject(data.sorting);
        }
        if (data.pagination != null) {
            message.pagination = ParametersProtoPagination.fromObject(data.pagination);
        }
        return message;
    }
    toObject() {
        const data: {
            filters?: ReturnType<typeof ParametersProtoFilter.prototype.toObject>[];
            sorting?: ReturnType<typeof ParametersProtoSorting.prototype.toObject>;
            pagination?: ReturnType<typeof ParametersProtoPagination.prototype.toObject>;
        } = {};
        if (this.filters != null) {
            data.filters = this.filters.map((item: ParametersProtoFilter) => item.toObject());
        }
        if (this.sorting != null) {
            data.sorting = this.sorting.toObject();
        }
        if (this.pagination != null) {
            data.pagination = this.pagination.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.filters.length)
            writer.writeRepeatedMessage(1, this.filters, (item: ParametersProtoFilter) => item.serialize(writer));
        if (this.hasSorting)
            writer.writeMessage(2, this.sorting, () => this.sorting.serialize(writer));
        if (this.hasPagination)
            writer.writeMessage(3, this.pagination, () => this.pagination.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ParametersProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ParametersProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.filters, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ParametersProtoFilter.deserialize(reader), ParametersProtoFilter));
                    break;
                case 2:
                    reader.readMessage(message.sorting, () => message.sorting = ParametersProtoSorting.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.pagination, () => message.pagination = ParametersProtoPagination.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ParametersProto {
        return ParametersProto.deserialize(bytes);
    }
}
export class ParametersProtoFilter extends pb_1.Message {
    #one_of_decls: number[][] = [[1]];
    constructor(data?: any[] | ({
        attributeType?: string;
        operator?: ParametersProtoFilterOperator;
        value?: ParametersProtoFilterValue;
    } & (({
        ownerType?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("ownerType" in data && data.ownerType != undefined) {
                this.ownerType = data.ownerType;
            }
            if ("attributeType" in data && data.attributeType != undefined) {
                this.attributeType = data.attributeType;
            }
            if ("operator" in data && data.operator != undefined) {
                this.operator = data.operator;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get ownerType() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set ownerType(value: string) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasOwnerType() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get attributeType() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set attributeType(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get operator() {
        return pb_1.Message.getFieldWithDefault(this, 3, ParametersProtoFilterOperator.EQ) as ParametersProtoFilterOperator;
    }
    set operator(value: ParametersProtoFilterOperator) {
        pb_1.Message.setField(this, 3, value);
    }
    get value() {
        return pb_1.Message.getWrapperField(this, ParametersProtoFilterValue, 4) as ParametersProtoFilterValue;
    }
    set value(value: ParametersProtoFilterValue) {
        pb_1.Message.setWrapperField(this, 4, value);
    }
    get hasValue() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get _owner_type() {
        const cases: {
            [index: number]: "none" | "ownerType";
        } = {
            0: "none",
            1: "ownerType"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    static fromObject(data: {
        ownerType?: string;
        attributeType?: string;
        operator?: ParametersProtoFilterOperator;
        value?: ReturnType<typeof ParametersProtoFilterValue.prototype.toObject>;
    }): ParametersProtoFilter {
        const message = new ParametersProtoFilter({});
        if (data.ownerType != null) {
            message.ownerType = data.ownerType;
        }
        if (data.attributeType != null) {
            message.attributeType = data.attributeType;
        }
        if (data.operator != null) {
            message.operator = data.operator;
        }
        if (data.value != null) {
            message.value = ParametersProtoFilterValue.fromObject(data.value);
        }
        return message;
    }
    toObject() {
        const data: {
            ownerType?: string;
            attributeType?: string;
            operator?: ParametersProtoFilterOperator;
            value?: ReturnType<typeof ParametersProtoFilterValue.prototype.toObject>;
        } = {};
        if (this.ownerType != null) {
            data.ownerType = this.ownerType;
        }
        if (this.attributeType != null) {
            data.attributeType = this.attributeType;
        }
        if (this.operator != null) {
            data.operator = this.operator;
        }
        if (this.value != null) {
            data.value = this.value.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasOwnerType)
            writer.writeString(1, this.ownerType);
        if (this.attributeType.length)
            writer.writeString(2, this.attributeType);
        if (this.operator != ParametersProtoFilterOperator.EQ)
            writer.writeEnum(3, this.operator);
        if (this.hasValue)
            writer.writeMessage(4, this.value, () => this.value.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ParametersProtoFilter {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ParametersProtoFilter();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.ownerType = reader.readString();
                    break;
                case 2:
                    message.attributeType = reader.readString();
                    break;
                case 3:
                    message.operator = reader.readEnum();
                    break;
                case 4:
                    reader.readMessage(message.value, () => message.value = ParametersProtoFilterValue.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ParametersProtoFilter {
        return ParametersProtoFilter.deserialize(bytes);
    }
}
export enum ParametersProtoFilterOperator {
    EQ = 0,
    NEQ = 1,
    LT = 2,
    LTE = 3,
    GT = 4,
    GTE = 5,
    CONTAINS = 6,
    REGEX = 7
}
export class ParametersProtoFilterValue extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5]];
    constructor(data?: any[] | ({} & (({
        boolean?: boolean;
        long?: never;
        double?: never;
        string?: never;
        datetime?: never;
    } | {
        boolean?: never;
        long?: number;
        double?: never;
        string?: never;
        datetime?: never;
    } | {
        boolean?: never;
        long?: never;
        double?: number;
        string?: never;
        datetime?: never;
    } | {
        boolean?: never;
        long?: never;
        double?: never;
        string?: string;
        datetime?: never;
    } | {
        boolean?: never;
        long?: never;
        double?: never;
        string?: never;
        datetime?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("boolean" in data && data.boolean != undefined) {
                this.boolean = data.boolean;
            }
            if ("long" in data && data.long != undefined) {
                this.long = data.long;
            }
            if ("double" in data && data.double != undefined) {
                this.double = data.double;
            }
            if ("string" in data && data.string != undefined) {
                this.string = data.string;
            }
            if ("datetime" in data && data.datetime != undefined) {
                this.datetime = data.datetime;
            }
        }
    }
    get boolean() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set boolean(value: boolean) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasBoolean() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get long() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set long(value: number) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasLong() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get double() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set double(value: number) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
    }
    get hasDouble() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get string() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set string(value: string) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0], value);
    }
    get hasString() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get datetime() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set datetime(value: number) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0], value);
    }
    get hasDatetime() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get value() {
        const cases: {
            [index: number]: "none" | "boolean" | "long" | "double" | "string" | "datetime";
        } = {
            0: "none",
            1: "boolean",
            2: "long",
            3: "double",
            4: "string",
            5: "datetime"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5])];
    }
    static fromObject(data: {
        boolean?: boolean;
        long?: number;
        double?: number;
        string?: string;
        datetime?: number;
    }): ParametersProtoFilterValue {
        const message = new ParametersProtoFilterValue({});
        if (data.boolean != null) {
            message.boolean = data.boolean;
        }
        if (data.long != null) {
            message.long = data.long;
        }
        if (data.double != null) {
            message.double = data.double;
        }
        if (data.string != null) {
            message.string = data.string;
        }
        if (data.datetime != null) {
            message.datetime = data.datetime;
        }
        return message;
    }
    toObject() {
        const data: {
            boolean?: boolean;
            long?: number;
            double?: number;
            string?: string;
            datetime?: number;
        } = {};
        if (this.boolean != null) {
            data.boolean = this.boolean;
        }
        if (this.long != null) {
            data.long = this.long;
        }
        if (this.double != null) {
            data.double = this.double;
        }
        if (this.string != null) {
            data.string = this.string;
        }
        if (this.datetime != null) {
            data.datetime = this.datetime;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBoolean)
            writer.writeBool(1, this.boolean);
        if (this.hasLong)
            writer.writeInt64(2, this.long);
        if (this.hasDouble)
            writer.writeDouble(3, this.double);
        if (this.hasString)
            writer.writeString(4, this.string);
        if (this.hasDatetime)
            writer.writeSint64(5, this.datetime);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ParametersProtoFilterValue {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ParametersProtoFilterValue();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.boolean = reader.readBool();
                    break;
                case 2:
                    message.long = reader.readInt64();
                    break;
                case 3:
                    message.double = reader.readDouble();
                    break;
                case 4:
                    message.string = reader.readString();
                    break;
                case 5:
                    message.datetime = reader.readSint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ParametersProtoFilterValue {
        return ParametersProtoFilterValue.deserialize(bytes);
    }
}
export class ParametersProtoSorting extends pb_1.Message {
    #one_of_decls: number[][] = [[1]];
    constructor(data?: any[] | ({
        attributeType?: string;
        direction?: ParametersProtoSortingDirection;
    } & (({
        ownerType?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("ownerType" in data && data.ownerType != undefined) {
                this.ownerType = data.ownerType;
            }
            if ("attributeType" in data && data.attributeType != undefined) {
                this.attributeType = data.attributeType;
            }
            if ("direction" in data && data.direction != undefined) {
                this.direction = data.direction;
            }
        }
    }
    get ownerType() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set ownerType(value: string) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasOwnerType() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get attributeType() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set attributeType(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get direction() {
        return pb_1.Message.getFieldWithDefault(this, 3, ParametersProtoSortingDirection.ASC) as ParametersProtoSortingDirection;
    }
    set direction(value: ParametersProtoSortingDirection) {
        pb_1.Message.setField(this, 3, value);
    }
    get _owner_type() {
        const cases: {
            [index: number]: "none" | "ownerType";
        } = {
            0: "none",
            1: "ownerType"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    static fromObject(data: {
        ownerType?: string;
        attributeType?: string;
        direction?: ParametersProtoSortingDirection;
    }): ParametersProtoSorting {
        const message = new ParametersProtoSorting({});
        if (data.ownerType != null) {
            message.ownerType = data.ownerType;
        }
        if (data.attributeType != null) {
            message.attributeType = data.attributeType;
        }
        if (data.direction != null) {
            message.direction = data.direction;
        }
        return message;
    }
    toObject() {
        const data: {
            ownerType?: string;
            attributeType?: string;
            direction?: ParametersProtoSortingDirection;
        } = {};
        if (this.ownerType != null) {
            data.ownerType = this.ownerType;
        }
        if (this.attributeType != null) {
            data.attributeType = this.attributeType;
        }
        if (this.direction != null) {
            data.direction = this.direction;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasOwnerType)
            writer.writeString(1, this.ownerType);
        if (this.attributeType.length)
            writer.writeString(2, this.attributeType);
        if (this.direction != ParametersProtoSortingDirection.ASC)
            writer.writeEnum(3, this.direction);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ParametersProtoSorting {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ParametersProtoSorting();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.ownerType = reader.readString();
                    break;
                case 2:
                    message.attributeType = reader.readString();
                    break;
                case 3:
                    message.direction = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ParametersProtoSorting {
        return ParametersProtoSorting.deserialize(bytes);
    }
}
export enum ParametersProtoSortingDirection {
    ASC = 0,
    DESC = 1
}
export class ParametersProtoPagination extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        offset?: number;
        limit?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("offset" in data && data.offset != undefined) {
                this.offset = data.offset;
            }
            if ("limit" in data && data.limit != undefined) {
                this.limit = data.limit;
            }
        }
    }
    get offset() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set offset(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get limit() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set limit(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        offset?: number;
        limit?: number;
    }): ParametersProtoPagination {
        const message = new ParametersProtoPagination({});
        if (data.offset != null) {
            message.offset = data.offset;
        }
        if (data.limit != null) {
            message.limit = data.limit;
        }
        return message;
    }
    toObject() {
        const data: {
            offset?: number;
            limit?: number;
        } = {};
        if (this.offset != null) {
            data.offset = this.offset;
        }
        if (this.limit != null) {
            data.limit = this.limit;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.offset != 0)
            writer.writeInt32(1, this.offset);
        if (this.limit != 0)
            writer.writeInt32(2, this.limit);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ParametersProtoPagination {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ParametersProtoPagination();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.offset = reader.readInt32();
                    break;
                case 2:
                    message.limit = reader.readInt32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ParametersProtoPagination {
        return ParametersProtoPagination.deserialize(bytes);
    }
}
