/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { Organization, OrgColumn } from "../../../concept/organization";
import { MatSortModule } from "@angular/material/sort";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { DatetimePipe, SpinnerComponent } from "typedb-platform-framework";
import { TableSelectionCellComponent, TableSelectionHeaderCellComponent } from "../../../framework/table";
import { AccessLevelPipe } from "../../../framework/access-level.pipe";

@Component({
    selector: "tp-orgs-table",
    templateUrl: "./orgs-table.component.html",
    standalone: true,
    imports: [
        AsyncPipe, DatetimePipe, MatTableModule, MatSortModule,
        TableSelectionHeaderCellComponent, TableSelectionCellComponent, SpinnerComponent, AccessLevelPipe,
    ],
})
export class OrgsTableComponent {
    @Input({ required: true}) table!: ResourceTable<Organization, OrgColumn>;

    constructor(public tableService: ResourceTableService) {}
}
