<div class="tp-email-verification">
  @switch (state.status) {
    @case ("pending") {
      <tp-spinner/>
    }
    @case ("emailSent") {
      <div class="tp-big-envelope"><fa-icon [icon]="['fat', 'envelope']"/></div>

      <h1>Please verify your email</h1>

      <p class="tp-headline">A link to verify your email has been sent to {{ state.email }}</p>

      <hr/>

      <aside class="tp-actions">
        <span>Didn't receive it?</span>
        <button class="button-secondary td-button-size-s" (click)="signOut()">Change email address</button>
        <button class="button-secondary td-button-size-s" (click)="resendVerificationEmail()">Resend email</button>
      </aside>
    }
  }
</div>
