/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { ApiListResponse } from "../../concept/api-response";
import { Parameters } from "../../concept/common";
import { Cluster, ClusterColumn, clusterPropertiesList } from "../../concept/cluster";
import { Organization } from "../../concept/organization";
import { Project } from "../../concept/project";
import {
    ProjectTeamAssignment,
    projectTeamAssignmentProperties,
    projectTeamAssignmentPropertiesList,
    TeamColumn,
} from "../../concept/team";
import {
    ProjectUserAssignment,
    projectUserAssignmentProperties,
    projectUserAssignmentPropertiesList,
    UserColumn,
} from "../../concept/user";
import { ApplicationState } from "../application-state.service";
import { AuthorizationService } from "../authorization.service";
import { OrgApi } from "../org/org-api.service";
import { BaseMembersTable, BaseTeamsTable, OrgClustersTable } from "../org/org-controller.service";
import { Observable, Subject } from "rxjs";
import { ResourceTable } from "../resource-table.service";
import { ProjectApi } from "./project-api.service";
import { stringFilterSpec } from "../../framework/table";
import { accessLevelValues } from "../../concept/iam";

@Injectable({
    providedIn: "root",
})
export class ProjectController {
    constructor(private api: ProjectApi, private orgApi: OrgApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    clustersTable(project: Project, unsub$: Subject<void>): ResourceTable<Cluster, ClusterColumn> {
        return new ProjectClustersTable(this.app.requireCurrentOrg(), this.orgApi, project, this.api, this.authorization, unsub$);
    }

    membersTable(project: Project, unsub$: Subject<void>): ResourceTable<ProjectUserAssignment, UserColumn> {
        return new ProjectUsersTable(this.app.requireCurrentOrg(), project, this.api, this.authorization, unsub$);
    }

    teamsTable(project: Project, unsub$: Subject<void>): ResourceTable<ProjectTeamAssignment, TeamColumn> {
        return new ProjectTeamsTable(this.app.requireCurrentOrg(), project, this.api, this.authorization, unsub$);
    }
}

class ProjectClustersTable extends OrgClustersTable {
    override readonly properties = clusterPropertiesList.filter(x => x.id !== "project");
    override displayedProperties = [...this.properties.filter(x => !["storageType", "storageSize", "createdAt", "typeDBVersion"].includes(x.id))];

    constructor(org: Organization, orgApi: OrgApi, private project: Project, private projectApi: ProjectApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, orgApi, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Cluster>> {
        return this.projectApi.listClusters(this.project.uuid, params, this.unsub$);
    }

    override get displayedColumns(): ClusterColumn[] {
        return [...this.displayedProperties.map(x => x.id)] as ClusterColumn[];
    }
}

class ProjectUsersTable extends BaseMembersTable<ProjectUserAssignment, UserColumn> {
    override properties = projectUserAssignmentPropertiesList;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(projectUserAssignmentProperties.id),
        stringFilterSpec(projectUserAssignmentProperties.email),
        stringFilterSpec(projectUserAssignmentProperties.firstName),
        stringFilterSpec(projectUserAssignmentProperties.lastName),
        stringFilterSpec(projectUserAssignmentProperties.accessLevel, accessLevelValues)
    ];

    constructor(org: Organization, private project: Project, private projectApi: ProjectApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<ProjectUserAssignment>> {
        return this.projectApi.listUsers(this.project.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (UserColumn | "actions")[] {
        return ["avatar", ...this.displayedProperties.map(x => x.id), "actions"] as (UserColumn | "actions")[];
    }
}

class ProjectTeamsTable extends BaseTeamsTable<ProjectTeamAssignment, TeamColumn> {
    override properties = projectTeamAssignmentPropertiesList;
    override displayedProperties = [...this.properties];
    override filterSpecs = [
        stringFilterSpec(projectTeamAssignmentProperties.id),
        stringFilterSpec(projectTeamAssignmentProperties.name),
        stringFilterSpec(projectTeamAssignmentProperties.accessLevel, accessLevelValues),
    ];

    constructor(org: Organization, private project: Project, private projectApi: ProjectApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<ProjectTeamAssignment>> {
        return this.projectApi.listTeams(this.project.uuid, params, this.unsub$);
    }

    override get displayedColumns(): (TeamColumn | "actions")[] {
        return [...this.displayedProperties.map(x => x.id), "actions"] as (TeamColumn | "actions")[];
    }
}
