<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    <tp-button
      [matTooltip]="(cannotCreateReason$ | async) || ''" buttonStyle="secondary" buttonId="createProject"
      [enabled]="(createEnabled$ | async) || false" (click)="openCreateModal()"
    >
      <fa-icon [icon]="['fal', 'square-plus']"/> Create
    </tp-button>
  </div>
  <article>
    <section>
      <tp-table-toolbar [table]="projects" filterGroupId="projects" tableTitle="Projects"/>
      <tp-projects-table [table]="projects" [rowPopupMenuItems]="projectsTableRowPopupMenuItems"/>
      <tp-table-paginator [table]="projects"/>
    </section>
  </article>
</tp-page-scaffold>
