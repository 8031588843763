/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { Property } from "../../concept/base";
import { Parameters } from "../../concept/common";
import {
    Invitation,
    InvitationColumn,
    invitationProperties,
    invitationPropertiesList,
    Organization,
    OrgColumn,
    orgProperties,
    orgPropertiesListWithAccessLevel
} from "../../concept/organization";
import {
    ProjectColumn, projectProperties,
    UserProject, userProjectProperties,
    userProjectPropertiesList
} from "../../concept/project";
import { UserTeam, memberTeamProperties, memberTeamPropertiesList, TeamColumn } from "../../concept/team";
import { AccessLevel, accessLevelValues } from "../../concept/iam";
import { User } from "../../concept/user";
import { stringFilterSpec } from "../../framework/table";
import { AuthorizationService } from "../authorization.service";
import { BaseProjectsTable, BaseTeamsTable } from "../org/org-controller.service";
import { ResourceTable } from "../resource-table.service";
import { first, map, Observable, of, Subject } from "rxjs";
import { ApiListResponse } from "../../concept/api-response";
import { UserApi } from "./user-api.service";
import { ApplicationState } from "../application-state.service";

@Injectable({
    providedIn: "root",
})
export class UserController {
    constructor(private api: UserApi, private authorization: AuthorizationService, private app: ApplicationState) {
    }

    orgsTable(unsub$: Subject<void>): ResourceTable<Organization, OrgColumn> {
        return new UserOrgsTable(this.api, this.authorization, unsub$);
    }

    projectsTable(user: User, unsub$: Subject<void>): ResourceTable<UserProject, ProjectColumn> {
        return new UserProjectsTable(this.app.requireCurrentOrg(), user, this.api, this.authorization, unsub$);
    }

    teamsTable(user: User, unsub$: Subject<void>): ResourceTable<UserTeam, TeamColumn> {
        return new UserTeamsTable(this.app.requireCurrentOrg(), user, this.api, this.authorization, unsub$);
    }

    invitationsTable(user: User, unsub$: Subject<void>): ResourceTable<Invitation, InvitationColumn> {
        return new UserInvitationsTable(user, this.api, this.authorization, unsub$);
    }

    getDefaultOrg(): Observable<Organization> {
        const lastUsedOrgId = this.app.lastUsedOrgId();
        return this.api.listOrgs({ pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } }, of(undefined)).pipe(
            first(res => !!res.initial),
            map(res => res.initial!),
            map(orgs => {
                return orgs.find(x => x.id === lastUsedOrgId) || orgs[0];
            }),
        );
    }
}

class UserOrgsTable extends ResourceTable<Organization, OrgColumn> {
    override readonly properties = orgPropertiesListWithAccessLevel;
    override readonly primaryProperty = orgProperties.id;
    override displayedProperties = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(orgProperties.id),
        stringFilterSpec(orgProperties.name),
        stringFilterSpec(orgProperties.accessLevel, accessLevelValues),
    ];

    constructor(private api: UserApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        super(unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Organization>> {
        return this.api.listOrgs(params, this.unsub$);
    }

    override getAccessLevels(resources: Organization[]): Observable<AccessLevel[]> {
        return this.authorization.orgAccessLevels(resources.map(x => x.uuid));
    }

    override get displayedColumns(): OrgColumn[] {
        return [...this.displayedProperties.map(x => x.id)] as OrgColumn[];
    }

    override itemRoute(org: Organization): string {
        return `/org/${org.id}`;
    }

    override noAccessMessage(org: Organization): string {
        return `You don't have access to the organization '${org.id}'`;
    }
}

class UserProjectsTable extends BaseProjectsTable<UserProject> {
    override properties = userProjectPropertiesList
    override displayedProperties = [...this.properties]
    override filterSpecs = [
        stringFilterSpec(projectProperties.id),
        stringFilterSpec(projectProperties.name),
        stringFilterSpec(userProjectProperties.accessLevel, accessLevelValues),
    ];

    constructor(org: Organization, private user: User, private userApi: UserApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, authorization, unsub$);
    }

    override get displayedColumns(): ProjectColumn[] {
        return [...this.displayedProperties.map(x => x.id as ProjectColumn)];
    }

    override getData(params: Parameters): Observable<ApiListResponse<UserProject>> {
        return this.userApi.listProjects({ userUuid: this.user.uuid, orgUuid: this.org.uuid }, params, this.unsub$);
    }
}

class UserTeamsTable extends BaseTeamsTable<UserTeam, TeamColumn> {
    override properties = memberTeamPropertiesList
    override displayedProperties = [...this.properties]
    override filterSpecs = [
        stringFilterSpec(memberTeamProperties.id),
        stringFilterSpec(memberTeamProperties.name),
        stringFilterSpec(memberTeamProperties.accessLevel, accessLevelValues),
    ];

    constructor(org: Organization, private user: User, private userApi: UserApi, authorization: AuthorizationService, unsub$: Subject<void>) {
        super(org, authorization, unsub$);
    }

    override getData(params: Parameters): Observable<ApiListResponse<UserTeam>> {
        return this.userApi.listTeams({ userUuid: this.user.uuid, orgUuid: this.org.uuid }, params, this.unsub$);
    }

    override get displayedColumns(): TeamColumn[] {
        return [...this.displayedProperties.map(x => x.id as TeamColumn)];
    }
}

class UserInvitationsTable extends ResourceTable<Invitation, InvitationColumn> {
    override readonly properties = invitationPropertiesList.filter(x => x.id !== invitationProperties.userEmail.id);
    override readonly primaryProperty = invitationProperties.orgId;
    override displayedProperties: Property[] = [...this.properties];
    override readonly filterSpecs = [
        stringFilterSpec(invitationProperties.orgId),
        stringFilterSpec(invitationProperties.orgName),
        stringFilterSpec(invitationProperties.orgAccessLevel, accessLevelValues),
    ];

    constructor(private user: User, private userApi: UserApi, private authorization: AuthorizationService, unsub$: Subject<void>) {
        const initialParams: Parameters = {
            pagination: { offset: 0, limit: 10 },
            sorting: { attributeType: "created-at", direction: "desc" },
            filters: [],
        };
        super(unsub$, initialParams);
    }

    override getData(params: Parameters): Observable<ApiListResponse<Invitation>> {
        return this.userApi.listInvitations(this.user.uuid, params, this.unsub$);
    }

    override getAccessLevels(resources: Invitation[]): Observable<AccessLevel[]> {
        return this.authorization.orgAccessLevels(resources.map(x => x.org.uuid));
    }

    override get displayedColumns(): ("actions" | InvitationColumn)[] {
        return [...this.displayedProperties.map(x => x.id as InvitationColumn), "actions"];
    }

    override itemRoute(): string {
        return ``;
    }

    override noAccessMessage(): string {
        return ``;
    }
}
