/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component } from "@angular/core";

import { FormActionsComponent, FormComponent, FormInputComponent, patternValidator, requiredValidator, ButtonComponent } from "typedb-platform-framework";
import { emailPattern, emailPatternErrorText } from "typedb-web-common/lib";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { FormBuilder } from "@angular/forms";
import { Subject } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "tp-forgot-password",
    templateUrl: "forgot-password.component.html",
    standalone: true,
    imports: [
        FormComponent, FormActionsComponent, FormInputComponent, AsyncPipe,
        ButtonComponent, RouterLink, FontAwesomeModule,
    ],
})
export class ForgotPasswordComponent {
    isSubmitting$ = new Subject<boolean>();
    resetPasswordSuccess = false;
    email?: string;
    form = this.formBuilder.nonNullable.group({
        email: ["", [patternValidator(emailPattern, emailPatternErrorText), requiredValidator]],
    });

    constructor(private auth: PlatformAuthService, private formBuilder: FormBuilder) {}

    onSubmit() {
        this.email = this.form.value.email!;
        this.requestPasswordResetEmail();
    }

    requestPasswordResetEmail() {
        // TODO: prevent multiple emails being sent in quick succession
        this.resetPasswordSuccess = false;
        this.auth.sendResetPassword(this.email!).subscribe({
            next: () => {
                this.resetPasswordSuccess = true;
                this.isSubmitting$.next(false);
            },
            error: () => {
                this.resetPasswordSuccess = false;
                this.isSubmitting$.next(false);
            }
        });
    }
}
