/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

export function generateName(validation: (str: string) => boolean): string {
    return generateNameWithRetryLimit(validation, 0);
}

const MAX_TRIES = 5;
function generateNameWithRetryLimit(validation: (str: string) => boolean, totalTries: number): string {
    const adjectiveIndex = Math.floor(Math.random() * adjectives.length);
    const animalIndex = Math.floor(Math.random() * animals.length);
    const generated = `${adjectives[adjectiveIndex]}-${animals[animalIndex]}`;
    if (validation(generated) || totalTries == MAX_TRIES) {
        return generated
    } else {
        return generateNameWithRetryLimit(validation, totalTries + 1);
    }
}

const adjectives = [
    "able",
    "absent",
    "academic",
    "accurate",
    "active",
    "acute",
    "adaptable",
    "adorable",
    "adventurous",
    "affectionate",
    "aggressive",
    "agreeable",
    "alert",
    "alive",
    "alluring",
    "ambitious",
    "ample",
    "amused",
    "ancient",
    "angry",
    "annoyed",
    "annoying",
    "anti",
    "anxious",
    "appreciative",
    "arctic",
    "arrogant",
    "artistic",
    "artless",
    "ashamed",
    "assertive",
    "athletic",
    "attractive",
    "average",
    "awful",
    "awkward",
    "babbling",
    "bad",
    "basic",
    "beautiful",
    "better",
    "bewildered",
    "big",
    "bionic",
    "blue",
    "blue-eyed",
    "blushing",
    "boastful",
    "bold",
    "boney",
    "bony",
    "bored",
    "bovine",
    "brainy",
    "brave",
    "breakable",
    "breezy",
    "bright",
    "broad",
    "broken",
    "bubbly",
    "bungling",
    "busy",
    "calm",
    "capable",
    "carefree",
    "careful",
    "caring",
    "casual",
    "cautious",
    "certain",
    "chaotic",
    "charming",
    "cheeky",
    "cheerful",
    "chill",
    "chubby",
    "civic",
    "civil",
    "clean",
    "clear",
    "clever",
    "cloudy",
    "clumsy",
    "colorful",
    "colossal",
    "combative",
    "comfortable",
    "comfy",
    "common",
    "compact",
    "competent",
    "complex",
    "compound",
    "concerned",
    "condemned",
    "confident",
    "confused",
    "conscious",
    "constant",
    "content",
    "contrary",
    "cool",
    "cooperative",
    "cosmic",
    "courageous",
    "covered",
    "crazy",
    "creepy",
    "crooked",
    "crowded",
    "cruel",
    "curious",
    "curved",
    "cute",
    "dangerous",
    "dapper",
    "daring",
    "dazzling",
    "deafening",
    "decent",
    "decisive",
    "decorous",
    "dedicated",
    "deep",
    "defeated",
    "defiant",
    "deliberate",
    "delightful",
    "depressed",
    "detailed",
    "determined",
    "devoted",
    "different",
    "difficult",
    "dignified",
    "diligent",
    "disagreeable",
    "discreet",
    "disgusted",
    "distinct",
    "disturbed",
    "dizzy",
    "double",
    "doubtful",
    "drab",
    "dull",
    "dynamic",
    "eager",
    "easy",
    "eaten",
    "efficient",
    "elated",
    "elder",
    "elegant",
    "embarrassed",
    "emotional",
    "empty",
    "enchanting",
    "encouraging",
    "endurable",
    "energetic",
    "enthusiastic",
    "envious",
    "ethical",
    "evil",
    "excited",
    "exclusive",
    "expensive",
    "extant",
    "exuberant",
    "faint",
    "fair",
    "faithful",
    "false",
    "famous",
    "fanatic",
    "fancy",
    "fantastic",
    "fast",
    "fatal",
    "fearless",
    "feline",
    "feminine",
    "fierce",
    "fine",
    "firm",
    "fiscal",
    "flat",
    "flexible",
    "floral",
    "focused",
    "fool",
    "foolish",
    "forceful",
    "foreign",
    "formal",
    "forward",
    "fragile",
    "frail",
    "frank",
    "frantic",
    "friendly",
    "frightened",
    "frozen",
    "fun",
    "funky",
    "funny",
    "future",
    "gaelic",
    "generous",
    "gentle",
    "giddy",
    "gifted",
    "gigantic",
    "given",
    "glamorous",
    "gleaming",
    "glorious",
    "good",
    "gorgeous",
    "gothic",
    "graceful",
    "great",
    "grieving",
    "groovy",
    "grotesque",
    "growing",
    "grumbly",
    "grumpy",
    "handsome",
    "happy",
    "harmless",
    "harsh",
    "healthy",
    "heavy",
    "helpful",
    "hermetic",
    "high-pitched",
    "hilarious",
    "hissing",
    "hollow",
    "holy",
    "homely",
    "honest",
    "horrible",
    "hostile",
    "human",
    "humble",
    "hungry",
    "hushed",
    "ideal",
    "idiot",
    "idle",
    "immaculate",
    "immature",
    "immense",
    "important",
    "impossible",
    "independent",
    "inexpensive",
    "influential",
    "informal",
    "innocent",
    "inquisitive",
    "insane",
    "inside",
    "insipid",
    "intellectual",
    "intense",
    "inventive",
    "itchy",
    "jaded",
    "jealous",
    "jittery",
    "jolly",
    "joyful",
    "joyous",
    "juicy",
    "keen",
    "kind",
    "kooky",
    "landed",
    "large",
    "lasting",
    "lazy",
    "learned",
    "legal",
    "light",
    "likable",
    "linear",
    "liquid",
    "little",
    "lively",
    "long",
    "loud",
    "lovely",
    "loving",
    "loyal",
    "lucky",
    "lunar",
    "magnificent",
    "major",
    "maltese",
    "mammoth",
    "maniac",
    "many",
    "marine",
    "married",
    "massive",
    "mature",
    "mean",
    "melodic",
    "mental",
    "middle",
    "mindless",
    "mini",
    "miniature",
    "minor",
    "misty",
    "mobile",
    "modern",
    "molar",
    "mortal",
    "mosaic",
    "motionless",
    "moving",
    "muddy",
    "mushy",
    "mysterious",
    "narrow",
    "nasty",
    "native",
    "naughty",
    "neat",
    "nervous",
    "neuter",
    "neutral",
    "nice",
    "nifty",
    "noble",
    "noisy",
    "normal",
    "norman",
    "northern",
    "nuclear",
    "nutty",
    "obedient",
    "obliging",
    "obnoxious",
    "obtuse",
    "odd",
    "old",
    "old-fashioned",
    "open",
    "optimistic",
    "original",
    "outrageous",
    "outstanding",
    "panicky",
    "patient",
    "patriotic",
    "peppy",
    "perfect",
    "petite",
    "plain",
    "playful",
    "pleasant",
    "pleasing",
    "plural",
    "poetic",
    "pointless",
    "poised",
    "polar",
    "polite",
    "poor",
    "positive",
    "postal",
    "potent",
    "powerful",
    "practical",
    "precious",
    "precise",
    "prepared",
    "present",
    "prickly",
    "private",
    "proud",
    "prudent",
    "public",
    "pungent",
    "punky",
    "puny",
    "putrid",
    "puzzled",
    "quaint",
    "quick",
    "quiet",
    "radiant",
    "random",
    "rapid",
    "raspy",
    "rational",
    "ready",
    "real",
    "realistic",
    "reasonable",
    "relaxed",
    "relieved",
    "repulsive",
    "reserved",
    "resilient",
    "resonant",
    "respectable",
    "responsive",
    "reverse",
    "rich",
    "ringing",
    "rising",
    "robust",
    "romantic",
    "round",
    "safe",
    "sandy",
    "sane",
    "sassy",
    "scary",
    "scrawny",
    "seamless",
    "second",
    "seeking",
    "selfish",
    "sensible",
    "serious",
    "setting",
    "shallow",
    "shiny",
    "shrill",
    "shy",
    "silly",
    "simple",
    "sincere",
    "single",
    "sitting",
    "skinny",
    "sleek",
    "sleepy",
    "slow",
    "small",
    "smiling",
    "smoggy",
    "snazzy",
    "sober",
    "sociable",
    "social",
    "soft",
    "solar",
    "solid",
    "solvent",
    "sonic",
    "sore",
    "sorry",
    "southern",
    "sparking",
    "sparkling",
    "spirited",
    "spiritual",
    "splendid",
    "sporty",
    "spotless",
    "spritely",
    "square",
    "stable",
    "standard",
    "standing",
    "static",
    "steady",
    "stormy",
    "strange",
    "strong",
    "stupid",
    "successful",
    "super",
    "surface",
    "swift",
    "tactful",
    "taken",
    "talented",
    "tame",
    "tasty",
    "teeny",
    "tender",
    "tense",
    "terrible",
    "textile",
    "thankful",
    "thermal",
    "thirsty",
    "thoughtful",
    "thoughtless",
    "thundering",
    "tidy",
    "tiny",
    "tired",
    "tolerable",
    "tough",
    "toxic",
    "trojan",
    "troubled",
    "trusting",
    "trustworthy",
    "truthful",
    "ugly",
    "unassuming",
    "unclear",
    "understanding",
    "uninterested",
    "union",
    "unique",
    "unreliable",
    "unsightly",
    "untrustworthy",
    "unusual",
    "upset",
    "uptight",
    "urban",
    "vast",
    "verbal",
    "versatile",
    "vibrant",
    "victorious",
    "vivacious",
    "vocal",
    "wandering",
    "wanted",
    "wary",
    "wasted",
    "weary",
    "weekday",
    "welcome",
    "western",
    "whimsical",
    "whispering",
    "wicked",
    "wide",
    "wide-eyed",
    "wild",
    "witty",
    "worldly",
    "worried",
    "worrisome",
    "written",
    "wrong",
    "young",
    "younger",
    "zany",
    "zealous",
    "zesty",
];

const animals = [
    "aardvark",
    "aardwolf",
    "abalone",
    "abyssinian",
    "accentor",
    "addax",
    "adelie-penguin",
    "affenpinscher",
    "african-buffalo",
    "african-grey-parrot",
    "agama",
    "agouti",
    "akita",
    "albatross",
    "alligator",
    "alpaca",
    "alpine-ibex",
    "american-black-bear",
    "american-sparrow",
    "amur-leopard",
    "anaconda",
    "anchovy",
    "andean-condor",
    "andean-mountain-cat",
    "anemone",
    "angelfish",
    "anhinga",
    "anole",
    "ant",
    "anteater",
    "antelope",
    "archerfish",
    "arctic-fox",
    "arctic-hare",
    "arctic-tern",
    "armadillo",
    "arowana",
    "asian-black-bear",
    "asian-red-fox",
    "asiatic-jackal",
    "auk",
    "australian-magpie",
    "avocet",
    "axolotl",
    "aye-aye",
    "azaras-zorro",
    "babirusa",
    "baboon",
    "bactrian-camel",
    "badger",
    "bald-eagle",
    "balinese",
    "banded-mongoose",
    "banded-palm-civet",
    "barb",
    "barbet",
    "barn-owl",
    "barnacle",
    "barracuda",
    "basenji-dog",
    "basilisk-lizard",
    "basset-hound",
    "bat",
    "bat-ray",
    "bat-eared-fox",
    "batfish",
    "beagle",
    "bean-goose",
    "bear",
    "bearded-dragon",
    "beaver",
    "bee",
    "bee-eater",
    "beetle",
    "bellbird",
    "belted-kingfisher",
    "beluga-whale",
    "bengal-tigers",
    "bernese-mountain-dog",
    "betta",
    "bichon-frise",
    "bigeyes",
    "binturong",
    "bird",
    "birman",
    "bison",
    "bittern",
    "black-bear",
    "black-howler-monkey",
    "black-mamba",
    "black-swan",
    "black-backed-jackal",
    "black-footed-cat",
    "black-necked-stilt",
    "blister-beetle",
    "blobfish",
    "bloodhound",
    "blue-crab",
    "bluebird",
    "boa-constrictor",
    "boar",
    "bobcat",
    "bolognese-dog",
    "bombay",
    "bongo",
    "bonobo",
    "booby",
    "border-collie",
    "border-terrier",
    "bornean-orangutan",
    "boston-terrier",
    "bottlenose-dolphin",
    "bowerbird",
    "boykin-spaniel",
    "bristlecone-pine-beetle",
    "broadbill",
    "brown-bear",
    "brown-trout",
    "bubble-eye-goldfish",
    "budgerigar",
    "buffalo",
    "bufflehead-duck",
    "bulbul",
    "bull-terrier",
    "bulldog",
    "bullet-ant",
    "bullfrog",
    "bullmastiff",
    "bumble-bee",
    "bunny",
    "burmese",
    "burying-beetle",
    "bush-dog",
    "bushbaby",
    "bushmaster",
    "bustard",
    "butterfly",
    "butterflyfish",
    "buzzard",
    "caiman",
    "cairn-terrier",
    "camel",
    "canaan-dog",
    "canary",
    "capuchin-monkey",
    "capybara",
    "caracal",
    "cardinal-bird",
    "caribou",
    "carp",
    "cassowary",
    "cat",
    "caterpillar",
    "cave-salamander",
    "centipede",
    "chameleon",
    "chamois",
    "cheetah",
    "chickadee",
    "chicken",
    "chihuahua",
    "chimpanzee",
    "chinchilla",
    "chinstrap-penguin",
    "chipmunk",
    "chiru",
    "chital",
    "chow-chow",
    "christmas-beetle",
    "cichlid",
    "clam",
    "click-beetle",
    "climbing-perch",
    "clouded-leopard",
    "clownfish",
    "cnidarian",
    "coati",
    "cobra",
    "cockatoo",
    "cockroach",
    "coelacanth",
    "collared-peccary",
    "colugo",
    "common-octopus",
    "common-ostrich",
    "condor",
    "coral",
    "cormorant",
    "cotton-top-tamarin",
    "cougar",
    "cow",
    "coyote",
    "coypu",
    "crab",
    "crab-eating-fox",
    "crabeater-seal",
    "crane",
    "crane-fly",
    "crayfish",
    "crested-gecko",
    "crocodile",
    "crow",
    "curlew",
    "cuttlefish",
    "dabra-goby",
    "dachshund",
    "dalmatian",
    "damselfish",
    "dark-eyed-junco",
    "dassie-rat",
    "deer",
    "degu",
    "desert-tortoise",
    "dhole",
    "dik-dik",
    "dingo",
    "dipper",
    "discus",
    "dog",
    "dogo-argentino",
    "dolphin",
    "donkey",
    "dormouse",
    "dotterel",
    "dove",
    "dragonfly",
    "dromedary-camel",
    "drongo",
    "duck",
    "dugong",
    "duiker",
    "dumbo-octopus",
    "dung-beetle",
    "dunlin",
    "dusky-dolphin",
    "eagle",
    "eagle-ray",
    "eared-seal",
    "earthworm",
    "earwig",
    "eastern-bluebird",
    "eastern-coral-snake",
    "eastern-cougar",
    "eastern-hognose-snake",
    "eastern-mole",
    "echidna",
    "echinoderm",
    "eel",
    "egret",
    "eider",
    "eland",
    "electric-eel",
    "eleonoras-falcon",
    "elephant",
    "elk",
    "emperor-penguin",
    "emperor-tamarin",
    "emu",
    "ermine",
    "ethiopian-wolf",
    "eurasian-beaver",
    "eurasian-red-squirrel",
    "falcon",
    "fennec-fox",
    "ferret",
    "fiddler-crab",
    "finch",
    "finnish-spitz",
    "fire-ant",
    "fire-salamander",
    "firecrest",
    "firefly",
    "fish",
    "fishing-cat",
    "flamingo",
    "flat-coated-retriever",
    "flea",
    "fly",
    "flying-fox",
    "flying-lemur",
    "flying-squirrel",
    "fossa",
    "fox",
    "fox-terrier",
    "frigatebird",
    "frill-necked-lizard",
    "frog",
    "frogmouth",
    "fulmar",
    "galah",
    "gaur",
    "gazelle",
    "gecko",
    "gerbil",
    "german-shepherd",
    "gharial",
    "giant-clam",
    "giant-otter",
    "giant-panda",
    "gibbon",
    "gila-monster",
    "giraffe",
    "glass-frog",
    "gnu",
    "goat",
    "golden-lion-tamarin",
    "golden-poison-frog",
    "goldfish",
    "goliath-bird-eating-spider",
    "goose",
    "gorilla",
    "grasshopper",
    "great-blue-heron",
    "great-hammerhead-shark",
    "greater-flamingo",
    "green-sea-turtle",
    "green-tree-python",
    "greenland-shark",
    "grison",
    "grizzly-bear",
    "groundhog",
    "guanaco",
    "guinea-pig",
    "gull",
    "haddock",
    "haikal",
    "hamerkop-bird",
    "hammerhead-shark",
    "hamster",
    "harbor-seal",
    "hare",
    "harp-seal",
    "harpy-eagle",
    "harpy-eagle",
    "harrier-hawk",
    "hartebeest",
    "havanese",
    "hawk",
    "hazel-dormouse",
    "hedgehog",
    "hercules-beetle",
    "hermit-crab",
    "heron",
    "herring-gull",
    "highland-cattle",
    "hippopotamus",
    "hissing-cockroach",
    "hoatzin",
    "hoatzin",
    "honey-badger",
    "honey-bee",
    "honeyeater",
    "honeyguide",
    "hoopoe-bird",
    "horn-shark",
    "hornbill",
    "horned-puffin",
    "hornet",
    "horse",
    "horseshoe-crab",
    "hoverfly",
    "howler-monkey",
    "humboldt-penguin",
    "hummingbird",
    "humpback-whale",
    "humphead-wrasse",
    "hyena",
    "hyrax",
    "iberian-frog",
    "ibex",
    "ibis",
    "ibisbill",
    "ibizan-hound",
    "iceland-gull",
    "icterine-warbler",
    "iguana",
    "impala",
    "imperial-eagle",
    "indian-bullfrog",
    "indian-elephant",
    "indian-flying-fox",
    "indian-palm-squirrel",
    "indian-rhinoceros",
    "indian-star-tortoise",
    "indochinese-tiger",
    "indri",
    "inland-taipan",
    "insect",
    "iriomote-cat",
    "irish-setter",
    "italian-wall-lizard",
    "italian-wolf",
    "ivory-gull",
    "jabiru",
    "jabiru-stork",
    "jacamar",
    "jacana",
    "jacana-bird",
    "jack-mackerel",
    "jack-russell",
    "jackal",
    "jackrabbit",
    "jaeger",
    "jaguar",
    "jaguarundi",
    "japanese-macaque",
    "jay",
    "jellyfish",
    "jerboa",
    "john-dory",
    "jumping-mouse",
    "jumping-spider",
    "junco",
    "jungle-cat",
    "juniper-vole",
    "kagu",
    "kakapo",
    "kangaroo",
    "kangaroo-rat",
    "katydid",
    "kea-parrot",
    "keel-billed-toucan",
    "kermode-bear",
    "kestrel",
    "killer-whale",
    "king-crab",
    "king-penguin",
    "kingbird",
    "kingfisher",
    "kinkajou",
    "kirks-dik-dik",
    "kite",
    "kitten",
    "kiwi",
    "kiwi-bird",
    "klipspringer",
    "knifefish",
    "koala",
    "kodiak-bear",
    "kodkod",
    "koi",
    "komodo-dragon",
    "kookaburra",
    "kouprey",
    "kowari",
    "krill",
    "kudu",
    "labradoodle",
    "labrador-retrievers",
    "lacewing",
    "ladybug/-ladybird",
    "lamb",
    "langur",
    "lapwing",
    "leafy-sea-dragon",
    "least-weasel",
    "leatherback-sea-turtle",
    "leech",
    "lemming",
    "lemur",
    "leopard",
    "leopard-seal",
    "liger",
    "lilac-breasted-roller",
    "limpkin",
    "lion",
    "lionfish",
    "lizard",
    "llama",
    "lobster",
    "locust",
    "loggerhead-sea-turtle",
    "long-tailed-tit",
    "longhorn-cowfish",
    "loon",
    "lorikeet",
    "loris",
    "lovebird",
    "lungfish",
    "lynx",
    "macaque",
    "macaw",
    "magpie",
    "maine-coon",
    "mallard",
    "mamba",
    "manakin",
    "manatee",
    "mandrill",
    "maned-wolf",
    "mangabey",
    "manta-ray",
    "mantella-frog",
    "mantis-shrimp",
    "mara",
    "marbled-salamander",
    "margay",
    "marine-angelfish",
    "markhor",
    "marlin",
    "marmoset",
    "marmot",
    "marten",
    "martial-eagle",
    "mastiff",
    "mayfly",
    "meadowlark",
    "meerkat",
    "mexican-redknee-tarantula",
    "millipede",
    "mink",
    "minke-whale",
    "minnow",
    "mockingbird",
    "mole",
    "mollusk",
    "monarch-butterfly",
    "mongoose",
    "mongrel",
    "monitor-lizard",
    "monk-seal",
    "monkey",
    "moon-jellyfish",
    "moorhen",
    "moose",
    "moray-eel",
    "mosquito",
    "moth",
    "motmot",
    "mountain-goat",
    "mountain-gorilla",
    "mountain-lion",
    "mouse",
    "mouse-deer",
    "mouse-spider",
    "mudskipper",
    "mule",
    "mullet",
    "muntjac",
    "musk-ox",
    "nabarlek",
    "naked-mole-rat",
    "nandu",
    "napu",
    "narwhal",
    "natterjack",
    "nautilus",
    "neddicky",
    "needlefish",
    "nematode",
    "nene",
    "newfoundland",
    "newt",
    "nicator",
    "nightfish",
    "nighthawk",
    "nightingale",
    "nile-crocodile",
    "nilgai",
    "ningaui",
    "norfolk-terrier",
    "northern-cardinal",
    "norwegian-forest",
    "nudibranch",
    "numbat",
    "nunbird",
    "nurse-shark",
    "nuthatch",
    "nutria",
    "nyala",
    "ocellaris-clownfish",
    "ocelot",
    "octopus",
    "okapi",
    "old-english-sheepdog",
    "onager",
    "opossum",
    "orangutan",
    "orb-weaver",
    "orca",
    "oribi",
    "oriole",
    "oropendola",
    "oryx",
    "osprey",
    "ostrich",
    "otter",
    "ovenbird",
    "owl",
    "owl-butterfly",
    "ox",
    "oyster",
    "oystercatcher",
    "ozark-big-eared-bat",
    "pacific-gull",
    "pademelon",
    "painted-stork",
    "pallas-cat",
    "palm-squirrel",
    "panda",
    "pangolin",
    "panther",
    "parakeet",
    "parrot",
    "parrotfish",
    "partridge",
    "patas-monkey",
    "peacock",
    "peacock-spider",
    "peccary",
    "pekingese",
    "pelican",
    "penguin",
    "peregrine-falcon",
    "persian",
    "pheasant",
    "pied-avocet",
    "pied-crow",
    "pied-tamarin",
    "pig",
    "pigeon",
    "pika",
    "pike",
    "pine-siskin",
    "piranha",
    "pistol-shrimp",
    "platypus",
    "plover",
    "plumed-basilisk",
    "pogona",
    "pointer",
    "poison-dart-frog",
    "polar-bear",
    "polecat",
    "pond-skater",
    "pony",
    "poodle",
    "porbeagle",
    "porcupine",
    "porpoise",
    "portuguese man-o-war",
    "possum",
    "pot-bellied-pig",
    "potoo",
    "potoroo",
    "potto",
    "prairie-dog",
    "prairie-rattlesnake",
    "prawn",
    "praying-mantis",
    "proboscis-monkey",
    "pronghorn",
    "przewalskis-horse",
    "ptarmigan",
    "pterosaur",
    "puff-adder",
    "puffer",
    "puffin",
    "pug",
    "puku",
    "puma",
    "puna-ibis",
    "puppy",
    "purple-emperor",
    "purple-grenadier",
    "puss-moth",
    "pygmy-goat",
    "pygmy-hippo",
    "pygmy-marmoset",
    "python",
    "qinling-panda",
    "quagga",
    "quahog",
    "quail",
    "queen-alexandras-birdwing",
    "queen-angelfish",
    "queen of-shebas-gazelle",
    "queen-snake",
    "queen-snapper",
    "queen-triggerfish",
    "queensland-grouper",
    "queensland-tube-nosed-bat",
    "quelea",
    "quetzal",
    "quokka",
    "quoll",
    "rabbit",
    "raccoon",
    "ragdoll",
    "rainbow-lorikeet",
    "ram",
    "rat",
    "ratel",
    "rattlesnake",
    "raven",
    "ray",
    "red-fox",
    "red-kangaroo",
    "red-panda",
    "red-wolf",
    "red-eyed-tree-frog",
    "redback-spider",
    "reef-shark",
    "reindeer",
    "rhea",
    "rhesus-macaque",
    "rhinoceros",
    "rhinoceros-beetle",
    "ring-tailed-lemur",
    "ringed-seal",
    "roadrunner",
    "robin",
    "rock-hyrax",
    "roller",
    "rook",
    "roseate-spoonbill",
    "rottweiler",
    "sabah-damsel",
    "sage-grouse",
    "saiga",
    "saiga-antelope",
    "sailfish",
    "saint-bernard",
    "saki-monkey",
    "salamander",
    "sally-lightfoot-crab",
    "salmon",
    "sambar",
    "sand-cat",
    "sand-lizard",
    "sanderling",
    "sandgrouse",
    "sandhill-crane",
    "sandpiper",
    "saola",
    "saola",
    "sawfish",
    "scarab-beetle",
    "scarlet-ibis",
    "scarlet-macaw",
    "schnauzer",
    "sco-shi",
    "scorpion",
    "scorpion-fish",
    "scotch-collie",
    "screech-owl",
    "scutulatus",
    "sea-anemone",
    "sea-cucumber",
    "sea-dragon",
    "sea-lion",
    "sea-otter",
    "sea-slug",
    "sea-snake",
    "sea-turtle",
    "sea-urchin",
    "seahorse",
    "seal",
    "seastar",
    "secretary-bird",
    "secretary-bird",
    "serval",
    "shaggy-bat",
    "shark",
    "shearwater",
    "sheep",
    "shelduck",
    "shih-tzu",
    "shoebill",
    "shoebill-stork",
    "shrew",
    "shrike",
    "shrimp",
    "siamang",
    "siamese",
    "siberian-husky",
    "siberian-tiger",
    "silver-dollar",
    "skate",
    "skimmer",
    "skink",
    "skipper-butterfly",
    "skua",
    "skunk",
    "skylark",
    "slender-loris",
    "sloth",
    "sloth-bear",
    "slow-loris",
    "slow-worm",
    "slug",
    "snail",
    "snake",
    "snares-penguin",
    "snipe",
    "snow-leopard",
    "snow-monkey",
    "snowshoe-hare",
    "snowshoe-hare",
    "snowy-owl",
    "softshell-turtle",
    "song-thrush",
    "sora",
    "southern-elephant-seal",
    "sparrow",
    "spectacled-bear",
    "sperm-whale",
    "spider",
    "spider-monkey",
    "spinner-dolphin",
    "spiny-lobster",
    "sponge",
    "spoonbill",
    "spotted-beetle",
    "spotted-hyena",
    "squid",
    "squirrel",
    "squirrel-glider",
    "squirrel-monkey",
    "stag-beetle",
    "starling",
    "stick-insect",
    "stingray",
    "stink-bug",
    "stoat",
    "stone-curlew",
    "stonefish",
    "stork",
    "sturgeon",
    "sugar-glider",
    "sumatran-orangutan",
    "sumatran-rhinoceros",
    "sun-bear",
    "sun-bear",
    "sunbird",
    "sunfish",
    "surgeonfish",
    "swallow",
    "swamphen",
    "swan",
    "swift",
    "swordfish",
    "tahr",
    "tailless-tenrec",
    "takin",
    "tamarin",
    "tanager",
    "tang",
    "tapanuli-orangutan",
    "tapir",
    "tapir",
    "tarantula",
    "tarpon",
    "tarsier",
    "tasmanian-devil",
    "tasmanian-devil",
    "tawny-eagle",
    "tawny-owl",
    "tayra",
    "tegu",
    "teira-batfish",
    "tenrec",
    "termite",
    "tern",
    "tetra",
    "thorny-devil",
    "thylacine",
    "tibetan-mastiff",
    "tiffany",
    "tiger",
    "tiger-beetle",
    "tiger-shark",
    "timber-wolf",
    "toad",
    "toddy-cat",
    "tokay-gecko",
    "topi",
    "tortoise",
    "toucan",
    "tree-frog",
    "tropicbird",
    "tsessebe",
    "tsetse-fly",
    "tuatara",
    "turkish-angora",
    "turtle",
    "uakari",
    "uganda-kob",
    "uguisu",
    "uinta-chipmunk",
    "uinta-ground-squirrel",
    "ulysses-butterfly",
    "umar-treefrog",
    "umbrellabird",
    "unadorned-rock-wallaby",
    "unau",
    "unicornfish",
    "upupa",
    "urchin",
    "urial",
    "uromastyx",
    "urraca",
    "urubu",
    "vanikolo-goatfish",
    "veiled-chameleon",
    "verreauxs-sifaka",
    "vervet-monkey",
    "vicuna",
    "viper",
    "vireo",
    "virginia-opossum",
    "viscacha",
    "vixen",
    "vole",
    "vulture",
    "wallaby",
    "walleye",
    "walrus",
    "wandering-albatross",
    "wapiti",
    "warbler",
    "warthog",
    "wasp",
    "water-dragon",
    "water-vole",
    "weasel",
    "weevil",
    "welsh-corgi",
    "west-indian-manatee",
    "western-gorilla",
    "whale",
    "whale-shark",
    "whippet",
    "white-tiger",
    "wild-boar",
    "wildcat",
    "wildebeest",
    "wolf",
    "wolverine",
    "wombat",
    "woodchuck",
    "woodpecker",
    "worm",
    "wrasse",
    "wren",
    "x-ray-tetra",
    "xantic-sargo",
    "xantis",
    "xantus",
    "xantus-murrelet",
    "xenarthra",
    "xenopoecilus",
    "xenops",
    "xerus",
    "xingu-river-ray",
    "xolmis",
    "xoloitzcuintli",
    "yabby",
    "yacare-caiman",
    "yak",
    "yearling",
    "yellow-baboon",
    "yellow-mongoose",
    "yellow-billed-hornbill",
    "yellowhammer",
    "yellowjacket",
    "yorkshire-terrier",
    "yucatan-squirrel",
    "yuma-myotis",
    "zapata-wren",
    "zebra",
    "zebra-finch",
    "zebra-shark",
    "zebrafish",
    "zebu",
    "zenaida-dove",
    "zokor",
    "zone-tailed-pigeon",
    "zorilla",
    "zorro",
    "zorse",
];
