/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AsyncPipe } from "@angular/common";
import { AsyncValidatorFn, FormBuilder } from "@angular/forms";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { Subject, combineLatest, delay, first, map, of, shareReplay, switchMap, tap } from "rxjs";
import { ButtonComponent, FormActionsComponent, FormComponent, FormInputComponent, FormTextareaComponent, SpinnerComponent, patternValidator, requiredValidator } from "typedb-platform-framework";
import { ParagraphWithHighlights } from "typedb-web-schema/lib";
import { Invitation } from "../../../concept/organization";
import { ParagraphWithHighlightsComponent } from "../../../framework/text/text-with-highlights.component";
import { AnalyticsService } from "../../../service/analytics.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { DEFAULT_INITIAL_PARAMS } from "../../../service/resource-table.service";
import { SanityService } from "../../../service/sanity.service";
import { emailListPattern, emailListPatternErrorText, idPattern, idPatternErrorText, namePattern, namePatternErrorText } from "typedb-web-common/lib";
import { OrgApi } from "../../../service/org/org-api.service";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { UserApi } from "../../../service/user/user-api.service";
import { SubscriptionApi } from "../../../service/subscription/subscription-api.service";
import { MultipleChoiceQuestionComponent } from "../project/question/multiple-choice/multiple-choice-question.component";
import { SurveyInstance } from "../project/survey-instance";

@Component({
    selector: "tp-setup-org",
    templateUrl: "./setup-org.component.html",
    styleUrls: ["./setup-org.component.scss"],
    standalone: true,
    imports: [
        FormComponent, AsyncPipe, FormInputComponent, FormActionsComponent, FormTextareaComponent,
        SpinnerComponent, ButtonComponent, SpinnerComponent, MatProgressBarModule, ParagraphWithHighlightsComponent, MultipleChoiceQuestionComponent
    ],
})
export class SetupOrgComponent implements OnDestroy {
    private orgIdValidator: AsyncValidatorFn = (control) => {
        return of(control.value as string).pipe(
            delay(500),
            switchMap((id) => this.orgApi.checkOrgId(id)),
            map((res) => res.exists ? { errorText: "This organization ID is already in use" } : null),
            tap((validationError) => { if (validationError) control.markAsTouched(); })
        );
    };
    unsub$ = new Subject<void>();
    // TODO: don't unsubscribe immediately - subscribe and live-update invite list
    invitations$ = this.app.currentUser$.pipe(switchMap((user) => {
        if (!user) return of(null);
        return this.userApi.listInvitations(
            user.uuid,
            { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "created-at", direction: "desc" } },
            this.unsub$
        ).pipe(
            first(x => !!x.initial),
            map(x => x.initial!),
            shareReplay(1),
        );
    }));
    readonly createOrgForm = this.formBuilder.nonNullable.group({
        id: ["", [patternValidator(idPattern, idPatternErrorText), requiredValidator], [this.orgIdValidator]],
        name: ["", [patternValidator(namePattern, namePatternErrorText), requiredValidator]],
        teamEmails: ["", [patternValidator(emailListPattern, emailListPatternErrorText)]],
    });
    readonly createOrgFormIsSubmitting$ = new Subject<boolean>();
    readonly invitationReviewForm = this.formBuilder.nonNullable.group({})
    readonly invitationReviewFormIsSubmitting$ = new Subject<boolean>();
    readonly selectedTab$ = new Subject<"joinOrg" | "createOrg">();
    orgInfoSurvey?: SurveyInstance;
    tagline$ = this.sanity.onboarding.pipe(map(x => x.joinOrgText ? ParagraphWithHighlights.fromSanity(x.joinOrgText) : null));

    constructor(
        private identityAuth: IdentityAuthService, private router: Router, private orgApi: OrgApi,
        private app: ApplicationState, private platformAuth: PlatformAuthService, private userApi: UserApi,
        private subscriptionApi: SubscriptionApi, private formBuilder: FormBuilder, private analytics: AnalyticsService,
        private sanity: SanityService, private orgCtl: OrgController,
    ) {
        this.invitations$.pipe(first(x => x != null), map(x => x!)).subscribe(invitations => {
            this.selectedTab$.next(invitations.length ? "joinOrg" : "createOrg");
        });
        this.sanity.orgInfoSurvey.subscribe((survey) => {
            this.orgInfoSurvey = new SurveyInstance(survey);
        });
    }

    ngOnDestroy() {
        this.unsub$.next();
    }

    get orgIdHint(): string {
        return this.createOrgForm.controls.id.valid ? "This organization ID is available" : "";
    }

    acceptInvitation(invitation: Invitation) {
        this.invitationReviewFormIsSubmitting$.next(true);
        this.userApi.acceptInvitation(invitation.uuid).subscribe({
            next: () => {
                this.analytics.google.reportAdConversion("joinOrg");
                if (invitation.accessLevel == "admin") {
                    this.subscriptionApi.getMarketplaceAccounts(invitation.org.uuid).subscribe({
                        next: (accounts) => {
                            if ((!accounts.gcp || !accounts.azure || !accounts.aws) && invitation.org.id) this.navigateToMarketplaceIntegration();
                            else window.location.href = window.location.origin;
                        },
                        error: () => window.location.href = window.location.origin
                    })
                } else {
                    window.location.href = window.location.origin;
                }
            },
            error: () => {
                this.invitationReviewFormIsSubmitting$.next(false);
            }
        });
    }

    declineInvitation(invitation: Invitation) {
        this.invitationReviewFormIsSubmitting$.next(true);
        this.userApi.rejectInvitation(invitation.uuid).subscribe({
            next: () => {
                window.location.href = window.location.origin;
            },
            error: () => {
                this.invitationReviewFormIsSubmitting$.next(false);
            }
        });
    }

    submitCreateOrgForm() {
        const id = this.createOrgForm.value.id!;
        const name = this.createOrgForm.value.name!;
        const teamMembers = (this.createOrgForm.value.teamEmails || "").split(",").map(x => x.trim()).filter(x => x.length);
        this.orgApi.createOrg({ id, name }).pipe(
            switchMap(() => this.inviteTeamMembers(id, teamMembers)),
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap((userAuth) => this.app.userAuth$.next(userAuth)),
        ).subscribe({
            next: () => {
                this.analytics.google.reportAdConversion("joinOrg");
                this.navigateToMarketplaceIntegration();
            },
            error: () => {
                this.createOrgFormIsSubmitting$.next(false);
            }
        });
    }

    private inviteTeamMembers(orgId: string, emails: string[]) {
        if (!emails.length) return of([]);
        else return this.orgApi.getOrg(orgId, of(undefined)).pipe(first(), map(x => x.initial!)).pipe(
            switchMap((org) => combineLatest([of(org), this.orgApi.listTeams(org.uuid, DEFAULT_INITIAL_PARAMS, of(undefined)).pipe(first(), map(x => x.initial!))])),
            switchMap(([org, [defaultTeam]]) => combineLatest(
                emails.map(email => this.orgApi.inviteMember({
                    orgUuid: org.uuid, accessLevel: "read", userEmail: email, projects: [],
                    teams: [{ uuid: defaultTeam.uuid, accessLevel: "read" }]
                }))
            )),
        );
    }

    navigateToMarketplaceIntegration() {
        this.router.navigate(["setup", "marketplace"]);
    }
}
