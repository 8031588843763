/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { DatetimePipe, MenuItem, SpinnerComponent } from "typedb-platform-framework";
import { OrgMember, User, UserColumn } from "../../../concept/user";
import { MatSortModule } from "@angular/material/sort";
import { AccessLevelPipe } from "../../../framework/access-level.pipe";
import { TableSelectionCellComponent, TableSelectionHeaderCellComponent } from "../../../framework/table";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { MatMenuModule } from "@angular/material/menu";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonModule } from "@angular/material/button";
import { UserAvatarComponent } from "../../user/avatar/user-avatar.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: "tp-users-table",
    templateUrl: "./users-table.component.html",
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe, DatetimePipe, MatTableModule, MatSortModule, MatMenuModule, FontAwesomeModule, MatButtonModule, MatTooltipModule,
        TableSelectionHeaderCellComponent, TableSelectionCellComponent, SpinnerComponent, AccessLevelPipe, UserAvatarComponent,
    ],
})
export class UsersTableComponent<ENTITY extends User> {
    @Input({ required: true}) table!: ResourceTable<ENTITY, UserColumn>;
    @Input() rowPopupMenuItems?: ((row: ENTITY) => MenuItem[]) | null;

    constructor(public tableService: ResourceTableService) {}
}
