/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Project } from "../../../concept/project";
import { User } from "../../../concept/user";
import { DialogResult } from "../../../service/dialog.service";
import { ProjectApi } from "../../../service/project/project-api.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { FormActionsComponent, FormComponent, FormOption, FormSelectComponent, ModalComponent, requiredValidator } from "typedb-platform-framework";
import { AccessLevel, accessLevelToString, accessLevelValues } from "../../../concept/iam";

export type ProjectAddUserDialogData = { project: Project, orgMembers: User[] };

function userOptionOf(user: User): FormOption<User> {
    return { value: user, viewValue: user.id };
}

@Component({
    selector: "tp-project-assign-user-dialog",
    templateUrl: "./project-assign-user-dialog.component.html",
    standalone: true,
    imports: [ModalComponent, AsyncPipe, FormActionsComponent, FormsModule, ReactiveFormsModule, FormComponent, FormSelectComponent],
})
export class ProjectAssignUserDialogComponent {
    readonly isSubmitting$ = new Subject<boolean>();
    readonly form = this.formBuilder.nonNullable.group({
        user: [null as User | null, [requiredValidator]],
        accessLevel: ["read" as AccessLevel, [requiredValidator]],
    });
    readonly orgMembers = this.data.orgMembers.map(userOptionOf);
    readonly accessLevelOptions: FormOption<AccessLevel>[] = accessLevelValues.map(x => ({ value: x, viewValue: accessLevelToString(x) }));

    constructor(
        private dialogRef: MatDialogRef<ProjectAssignUserDialogComponent, DialogResult>, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: ProjectAddUserDialogData, private projectApi: ProjectApi, private snackbar: SnackbarService) {}

    submit() {
        const form = this.form.value;
        const user = form.user!;
        const accessLevel = form.accessLevel!;
        this.projectApi.assignUser({ projectUuid: this.data.project.uuid, userUuid: user.uuid, accessLevel }).subscribe({
            next: () => {
                this.snackbar.success(`User '${user.id}' is now assigned to project ${this.data.project.id}.`);
                this.close("ok");
            },
            error: () => {
                this.isSubmitting$.next(false);
            },
        });
    }

    close(result?: DialogResult) {
        this.dialogRef.close(result);
    }
}
