<tp-modal dialogTitle="Add a team" [isBusy]="isSubmitting$ | async">
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <tp-form-select [form]="form" field="team" label="Team" [options]="teams"/>
    <tp-form-select [form]="form" field="accessLevel" label="Access Level" [options]="accessLevelOptions"/>
    <tp-form-actions
      [cancellable]="true" submitText="Submit" [isSubmitting]="isSubmitting$ | async"
      buttonIdPrefix="projectAddTeamModal" (cancel)="close()"
    />
  </tp-form>
</tp-modal>
