<div class="form-container">
  @switch (this.phase) {
    @case ("loading") {
      <tp-spinner/>
    }
    @case ("valid") {
      <p class="box-title">Create new password</p>
      <p class="box-text">Enter a new password</p>
      <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="onSubmit()">
        <tp-form-password-input autocomplete="new-password" [form]="form" label="Enter Password" field="password"/>
        <tp-form-password-input autocomplete="new-password" [form]="form" label="Confirm Password" field="confirmPassword"/>
        <mat-error>{{ form.getError("errorText") }}</mat-error>
        <tp-form-actions [isSubmitting]="isSubmitting$ | async" [submitDisabled]="!form.dirty" buttonIdPrefix="setNewPassword"/>
      </tp-form>
    }
    @case ("success") {
      <p class="box-title">Password changed successfully</p>
      <p class="box-text">Please sign in to your account with your new password</p>
      <a routerLink="/sign-in">
        <tp-button class="box-content" type="button" buttonStyle="primary-solid green" buttonId="signIn">Sign In</tp-button>
      </a>
    }
    @case ("error") {
      <p class="box-title">Invalid password reset</p>
      <p class="box-content">
        <span class="box-text">There was an error while attempting to reset your password</span>
      </p>
      <p class="box-text">
      <span>
        Please <a class="box-text-clickable" routerLink="/forgot-password">click here</a>
        to request a new password reset email.
      </span>
      </p>
    }
  }
</div>
