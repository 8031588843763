<tp-modal dialogTitle="Change Access Level" [isBusy]="isSubmitting$ | async">
  <p>Change access level for team '{{ this.data.team.id }}' in project '{{ this.data.project.name }}'</p>
  <br/>
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <tp-form-select [form]="form" field="accessLevel" label="Access Level" [options]="accessLevelOptions"/>
    <tp-form-actions
      [submitDisabled]="form.value.accessLevel == data.team.accessLevel"
      [cancellable]="true" submitText="Submit" [isSubmitting]="isSubmitting$ | async"
      buttonIdPrefix="projectSetTeamAccessModal" (cancel)="close()"
    />
  </tp-form>
</tp-modal>
