<tp-page-scaffold [hideSidenav]="true">
  <tp-form class="dc-form" [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <div class="dc-form-container">
      <mat-stepper [(selectedIndex)]="currentStep" (selectionChange)="onStepChange($event)">
        <mat-step label="Cloud" [completed]="false">
          <div class="form">
            @if (providerSectionReady) {
              <label>Provider</label>
              <div class="cards-row">
                @for (provider of providers$ | async; track provider) {
                  <button type="button" class="provider-card" [ngClass]="providerCardClasses(provider)" (click)="selectProvider(provider)" [disabled]="!form.enabled">
                    <div class="card-content">
                      <img [src]="providerImageUrl(provider)" [alt]="provider.name" />
                    </div>
                    <div class="card-footer">{{ provider.name }}</div>
                  </button>
                }
              </div>
              <div class="form-input-row region-input-row">
                <label>Region</label>
                <mat-form-field>
                  <mat-select class="region-mat-select" [formControl]="form.controls.region">
                    <mat-select-trigger class="region-mat-select-trigger" [class.region-free-available]="form.value.region?.isFreeAvailable && !(orgAlreadyHasFreeCluster$ | async)">
                      @if (form.value.region; as region) {
                        <img [ngSrc]="regionFlagUrl(region)" width="20" height="15" alt="" />&nbsp;<span>{{ region | region }}</span>
                      }
                    </mat-select-trigger>
                    @for (regionGroup of regionGroups; track regionGroup) {
                      <mat-optgroup [label]="regionGroup.name">
                        @for (region of regionGroup.options; track region) {
                          <mat-option [value]="region.value" [class.region-free-available]="region.value.isFreeAvailable && !(orgAlreadyHasFreeCluster$ | async)">
                            <img [ngSrc]="regionFlagUrl(region.value)" width="20" height="15" alt="" />&nbsp;<span>{{ region.viewValue }}</span>
                          </mat-option>
                        }
                      </mat-optgroup>
                    }
                  </mat-select>
                  @if (form.controls.region.getError("errorText")) {
                    <mat-error>{{ form.controls.region.getError("errorText") }}</mat-error>
                  }
                </mat-form-field>
              </div>
            } @else {
              <tp-spinner/>
            }
          </div>
        </mat-step>
        <mat-step label="Capacity" [completed]="false">
          <div class="form">
            <div class="cards-row">
              @for (preset of clusterPresets; track preset) {
                <button [disabled]="advancedConfig" type="button" class="preset-card" [ngClass]="presetCardClasses(preset.value)" (click)="selectPreset(preset.value)">
                  @if (selectedPreset?.id == preset.value.id && !advancedConfig) {
                    <img class="tp-logo" src="assets/logo/typedb-bot.svg" alt="TypeDB"/>
                  }
                  <div class="card-content">
                    <div class="preset-card-wrapper">
                      <div class="preset-card-image">
                        <div class="preset-card-bg"></div>
                        <img src="https://cdn.sanity.io/images/xndl14mc/production/ab910f27e737370f3ed4df4007f93fc6cf57e259-122x135.svg" [alt]="preset.value.name"/>
                      </div>
                    </div>
                    <table>
                      <tr>
                        <th>Compute</th>
                        <td>{{ preset.value.machineType | machineType:"compute" }}</td>
                      </tr>
                      <tr>
                        <th>Cluster Size</th>
                        <td>{{ preset.value.serverCount }}</td>
                      </tr>
                      <tr>
                        <th>Storage</th>
                        <td>{{ storageSizeToString(preset.value.storageGB) }}</td>
                      </tr>
                      <tr>
                        <th>Price</th>
                          @if (preset.value.machineType.isFree && !(orgAlreadyHasFreeCluster$ | async)) {
                            <td class="highlight tooltip" matTooltip="Free forever. One free cluster per organization">
                              Free
                            </td>
                          } @else {
                            <td>{{ pricePerHourToPerHourString(preset.value.pricePerHourCents) }} per hour</td>
                          }
                      </tr>
                    </table>
                  </div>
                  <div class="card-footer">{{ preset.value.name }}</div>
                </button>
              }
            </div>
            <mat-slide-toggle color="primary" [checked]="advancedConfig" (change)="toggleAdvanced()">
              Enable advanced configuration
            </mat-slide-toggle>
          </div>
          @if (advancedConfig) {
            <div class="form advanced-config">
              <div class="form-input-row">
                <div class="form-input-row-description"><p>The specs of your cluster's machines</p></div>
                <mat-form-field [title]="machineTypeFieldTitle">
                  <mat-select class="machine-type-mat-select" [formControl]="form.controls.machineType">
                    <mat-select-trigger class="machine-type-mat-select-trigger" [class.machine-type-free]="form.value.machineType?.isFree">
                      @if (form.value.machineType; as machineType) {
                        <span>{{ machineType | machineType }}</span>
                      }
                    </mat-select-trigger>
                    @for (machineType of machineTypes; track machineType) {
                      <mat-option [value]="machineType.value" [class.machine-type-free]="machineType.value.isFree">
                        <span>{{ machineType.viewValue }}</span>
                      </mat-option>
                    }
                  </mat-select>
                  @if (form.controls.machineType.getError("errorText")) {
                    <mat-error>{{ form.controls.machineType.getError("errorText") }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="form-input-row form-toggle-group-row">
                <div class="form-input-row-description"><p>The size of your cluster (multi-machine clusters provide high availability)</p></div>
                <tp-form-toggle-group
                  [form]="form" field="serverCount" [options]="(serverCounts$ | async)!" [matTooltip]="(serverCountTooltip$ | async)!"
                  [disabled]="serverCountLocked"
                />
              </div>
              <div class="form-input-row">
                <div class="form-input-row-description"><p>The specs of your cluster's storage device</p></div>
                <tp-form-select [form]="form" field="storageType" [options]="storageTypes"/>
              </div>
              <div class="form-input-row form-slider-row">
                <div class="form-input-row-description"><p>Storage in gigabytes</p></div>
                <div class="form-slider-field" [matTooltip]="(storageSliderTooltip$ | async)!">
                  <mat-form-field class="form-slider-field-text-box">
                    <input
                      matInput type="text" readonly
                      [value]="storageSliderIndicatorText"
                    />
                  </mat-form-field>
                  <mat-slider min="0" max="9" [showTickMarks]="true" [displayWith]="storageSliderValueToString" [disabled]="storageSliderLocked">
                    <input [formControl]="form.controls.storageSlider" matSliderThumb>
                  </mat-slider>
                </div>
              </div>
            </div>
          }
        </mat-step>
        <mat-step label="Details" [completed]="false">
          <div class="form">
            <div class="form-input-row">
              <div class="form-input-row-description"><p>Your cluster's name. Must be unique within a project</p></div>
              <div class="tp-id-input">
                @if (this.idGenerateCount > 10) {
                  @for (animalOption of animalIconOptions; track animalOption) {
                    @if (this.animalIcon == animalOption) {
                      <fa-icon class="tp-animal" [icon]="['fal', animalOption]"/>
                    }
                  }
                }
                <tp-form-input [form]="form" field="id"/>
                <button class="regenerate-id-button" type="button" buttonId="regenerateId" (click)="generateAndFillID(); $event.stopPropagation()" [disabled]="isSubmitting$ | async">
                  <fa-icon [icon]="['fal', 'refresh']"/>
                </button>
              </div>
            </div>
            <div class="form-input-row">
              <div class="form-input-row-description"><p>The project for this cluster</p></div>
              @if (projectOptions$ | async; as projectOptions) {
                <tp-form-select [form]="form" field="project" [options]="projectOptions"/>
              } @else {
                <tp-spinner/>
              }
            </div>
            <div class="form-input-row">
              <div class="form-input-row-description"><p>The TypeDB version your cluster will use</p></div>
              @if (typeDBVersions$ | async; as typeDBVersions) {
                <tp-form-select [form]="form" field="typeDBVersion" [options]="typeDBVersions"/>
              } @else {
                <tp-spinner/>
              }
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </div>

    <div class="dc-basket-bar">
      <div class="subtitle subtitle-first-of-type"><span>Cloud</span></div>
      <table>
        <tr>
          <th>Provider</th>
          <td>{{ form.value.provider ? (form.value.provider | providerId) : "--" }}</td>
        </tr>
        <tr>
          <th>Location</th>
          <td>{{ form.value.region?.locationName || "--" }}</td>
        </tr>
        <tr>
          <th>Region</th>
          <td>{{ form.value.region?.vendorId || "--" }}</td>
        </tr>
      </table>

      <div class="subtitle"><span>Capacity</span></div>
      <table>
        <tr>
          <th>Compute</th>
          <td>{{ form.value.machineType ? (form.value.machineType | machineType:"compute") : "--" }}</td>
        </tr>
        <tr>
          <th>Cluster Size</th>
          <td>{{ form.value.serverCount || "--" }}</td>
        </tr>
        <tr>
          <th>Storage</th>
          <td>{{ storageSliderCurrentValueToString }}</td>
        </tr>
        <button type="button" class="show-more" id="showMore" (click)="checkoutShowMore = !checkoutShowMore">
          @if (checkoutShowMore) {
            Hide details <fa-icon class="tp-chevron-up" [icon]="['fal', 'chevron-up']"/>
          } @else {
            Show details <fa-icon [icon]="['fal', 'chevron-down']"/>
          }
        </button>
        @if (checkoutShowMore) {
          <tr>
            <th>Machine Type</th>
            <td>{{ form.value.machineType?.vendorId || "--" }}</td>
          </tr>
          <tr>
            <th>Storage Speed</th>
            <td>{{ form.value.storageType?.iops || "--" }}</td>
          </tr>
          <tr>
            <th>Storage Type</th>
            <td>{{ form.value.storageType?.vendorId || "--" }}</td>
          </tr>
        }
      </table>

      <div class="subtitle"><span>Cost</span></div>
      <table>
        <tr>
          <th>Per hour</th>
          <td [ngClass]="{ highlight: (pricePerHourCents$ | async) == 0 }">
            @if (pricePerHourString$ | async; as pricePerHourString) {
              {{ pricePerHourString }}
            } @else {
              <tp-spinner [size]="16"/>
            }
          </td>
        </tr>
        <tr>
          <th>Per 30 days</th>
          <td [ngClass]="{ highlight: (pricePerHourCents$ | async) == 0 }">
            @if (pricePer30DaysString$ | async; as pricePerMonthString) {
              {{ pricePerMonthString }}
            } @else {
              <tp-spinner [size]="16"/>
            }
          </td>
        </tr>
      </table>

      <div class="form-additional-info">
        @if (showAskAdminForPaymentMethodPrompt$ | async) {
          <div class="info-box status-info">
            Please ask an organization administrator to add a payment method, then
            <a href="javascript:void(0);" (click)="fetchPayableProviders(true)">click here</a> to
            refresh payment methods.
          </div>
        }
        @if (cannotDeploySecondFreeCluster$ | async) {
          <div class="info-box status-error">
            Limited to 1 free cluster per organization. Please select a paid provider, region, or machine tier.
          </div>
        }
      </div>

      <div class="flex-spacer"></div>

      @if (currentStep == ClusterCreationSteps.CONFIGURATION) {
        @if (mustAddPaymentMethod$ | async) {
          <tp-button
            class="continue-button" buttonStyle="primary-solid green"
            buttonId="addPaymentMethod" (click)="openPaymentDetailsDialog()">
            @if (isSubmitting$ | async) {<tp-spinner [size]="20"/>} @else {Add payment method}
          </tp-button>
        } @else {
          <p class="terms-privacy-additional-info">
            By creating a cluster, you are accepting the
            <a class="tos-privacy-link" href="https://typedb.com/legal/terms-of-service" target="_blank" rel="noopener">Terms of Service</a>
            and the
            <a class="tos-privacy-link" href="https://typedb.com/legal/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>.
          </p>
          <tp-button
            type="submit" [enabled]="!(isSubmitting$ | async) || false" buttonId="deploy"
            buttonStyle="primary-solid green"
          >
            @if (isSubmitting$ | async) {<tp-spinner [size]="20"/>} @else {Deploy}
          </tp-button>
        }
      } @else {
        <tp-button class="continue-button" buttonId="continue" buttonStyle="primary-solid green" (click)="advance()">Continue</tp-button>
      }
    </div>

  </tp-form>

  @if (currentStep == ClusterCreationSteps.CLOUD) {
    <button class="back-button" (click)="cancel()">
      <fa-icon [icon]="['fal', 'xmark']"/>
    </button>
  } @else {
    <button class="back-button" (click)="return()">
      <fa-icon class="back-button" [icon]="['fal', 'arrow-left']"/>
    </button>
  }
</tp-page-scaffold>
