/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { PartialWithId } from "../concept/base";
import { Cluster, Server } from "../concept/cluster";
import { Organization } from "../concept/organization";
import { Project } from "../concept/project";
import { Team } from "../concept/team";
import { User } from "../concept/user";

export function orgPath(org: Organization): string {
    return `/org/${org.id}`;
}

export function projectsPath(org: Organization): string {
    return `${orgPath(org)}/projects`;
}

export function projectDetailsPath(project: PartialWithId<Project>, org: Organization): string {
    return `${projectsPath(org)}/${project.id}`;
}

export function clustersPath(org: Organization): string {
    return `${orgPath(org)}/clusters`;
}

export function clusterDetailsPath(cluster: PartialWithId<Cluster> & { project: Project }, org: Organization): string {
    return `${projectDetailsPath(cluster.project, org)}/clusters/${cluster.id}`;
}

export function serverDetailsPath(server: Server, cluster: PartialWithId<Cluster> & { project: Project }, org: Organization): string {
    return `${clusterDetailsPath(cluster, org)}/servers/${server.id}`;
}

export function teamsPath(org: Organization): string {
    return `${orgPath(org)}/teams`;
}

export function teamDetailsPath(team: PartialWithId<Team>, org: Organization): string {
    return `${teamsPath(org)}/${team.id}`;
}

export function membersPath(org: Organization): string {
    return `${orgPath(org)}/members`;
}

export function memberDetailsPath(member: PartialWithId<User>, org: Organization): string {
    return `${membersPath(org)}/${member.id}`;
}

export function orgSettingsPath(org: Organization): string {
    return `${orgPath(org)}/settings`;
}
