/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
    ApplicationProtoPrivateReq, ApplicationProtoPrivateRes, ApplicationProtoPrivateResType, ApplicationProtoPublicReq
} from "../../../application/protocol/application";
import { ClusterCtlProtoReq } from "../../../application/protocol/cluster-controller";
import { OrganizationCtlProtoReq } from "../../../application/protocol/organization-controller";
import { ProjectCtlProtoReq } from "../../../application/protocol/project-controller";
import { SubscriptionCtlProtoReq } from "../../../application/protocol/subscription-controller";
import { TeamCtlProtoReq } from "../../../application/protocol/team-controller";
import { UserCtlProtoReq } from "../../../application/protocol/user-controller";
import {
    AuthenticationCtlProtoPrivateReq, AuthenticationCtlProtoPublicReq, AuthenticationCtlProtoPublicRes
} from "../../../protocol/authentication-controller";
import { Ok } from "../../concept/base";
import { PlatformApiBackend, UnsubListener } from "./platform-api-backend.service";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    constructor(public backend: PlatformApiBackend) {}

    authPublicReq(req: AuthenticationCtlProtoPublicReq): Observable<AuthenticationCtlProtoPublicRes> {
        return this.backend.publicReqRes(new ApplicationProtoPublicReq({ authCtl: req }))
            .pipe(map((res) => res.authCtl));
    }

    authPrivateReq(req: AuthenticationCtlProtoPrivateReq): Observable<Ok> {
        return this.backend.privateReq(new ApplicationProtoPrivateReq({ authCtl: req }));
    }

    clusterReq(request: ClusterCtlProtoReq) {
        return this.backend.privateReq(new ApplicationProtoPrivateReq({ clusterCtl: request }));
    }

    clusterReqRes(request: ClusterCtlProtoReq) {
        return this.backend.privateReqRes(new ApplicationProtoPrivateReq({ clusterCtl: request }))
            .pipe(map(res => res.clusterCtl));
    }

    clusterReqSub(request: ClusterCtlProtoReq, unsub$: UnsubListener) {
        return this.backend
            .privateReqSub(new ApplicationProtoPrivateReq({ clusterCtl: request }), unsub$)
            .pipe(map((res) => this.mapResSub(res, res.clusterCtl)));
    }

    userReq(request: UserCtlProtoReq) {
        return this.backend.privateReq(new ApplicationProtoPrivateReq({ userCtl: request }));
    }

    userReqRes(request: UserCtlProtoReq) {
        return this.backend.privateReqRes(new ApplicationProtoPrivateReq({ userCtl: request }))
            .pipe(map(res => res.userCtl));
    }

    userReqSub(request: UserCtlProtoReq, unsub$: UnsubListener) {
        return this.backend
            .privateReqSub(new ApplicationProtoPrivateReq({ userCtl: request }), unsub$)
            .pipe(map((res) => this.mapResSub(res, res.userCtl)));
    }

    orgReq(request: OrganizationCtlProtoReq) {
        return this.backend.privateReq(new ApplicationProtoPrivateReq({ orgCtl: request }));
    }

    orgReqRes(request: OrganizationCtlProtoReq) {
        return this.backend.privateReqRes(new ApplicationProtoPrivateReq({ orgCtl: request }))
            .pipe(map(res => res.orgCtl));
    }

    orgReqSub(request: OrganizationCtlProtoReq, unsub$: UnsubListener) {
        return this.backend
            .privateReqSub(new ApplicationProtoPrivateReq({ orgCtl: request }), unsub$)
            .pipe(map((res) => this.mapResSub(res, res.orgCtl)))
    }

    projectReq(request: ProjectCtlProtoReq) {
        return this.backend.privateReq(new ApplicationProtoPrivateReq({ projectCtl: request }));
    }

    projectReqRes(request: ProjectCtlProtoReq) {
        return this.backend.privateReqRes(new ApplicationProtoPrivateReq({ projectCtl: request }))
            .pipe(map(res => res.projectCtl));
    }

    projectReqSub(request: ProjectCtlProtoReq, unsub$: UnsubListener) {
        return this.backend
            .privateReqSub(new ApplicationProtoPrivateReq({ projectCtl: request }), unsub$)
            .pipe(map((res) => this.mapResSub(res, res.projectCtl)));
    }

    teamReq(request: TeamCtlProtoReq) {
        return this.backend.privateReq(new ApplicationProtoPrivateReq({ teamCtl: request }));
    }

    teamReqRes(request: TeamCtlProtoReq) {
        return this.backend.privateReqRes(new ApplicationProtoPrivateReq({ teamCtl: request }))
            .pipe(map(res => res.teamCtl));
    }

    teamReqSub(request: TeamCtlProtoReq, unsub$: UnsubListener) {
        return this.backend
            .privateReqSub(new ApplicationProtoPrivateReq({ teamCtl: request }), unsub$)
            .pipe(map((res) => this.mapResSub(res, res.teamCtl)));
    }

    subscriptionReq(request: SubscriptionCtlProtoReq) {
        return this.backend.privateReq(new ApplicationProtoPrivateReq({ subscriptionCtl: request }));
    }

    subscriptionReqRes(request: SubscriptionCtlProtoReq) {
        return this.backend.privateReqRes(new ApplicationProtoPrivateReq({ subscriptionCtl: request }))
            .pipe(map((res) => res.subscriptionCtl));
    }

    subscriptionReqSub(request: SubscriptionCtlProtoReq, unsub$: UnsubListener) {
        return this.backend
            .privateReqSub(new ApplicationProtoPrivateReq({ subscriptionCtl: request }), unsub$)
            .pipe(map((res) => this.mapResSub(res, res.subscriptionCtl)));
    }

    private mapResSub<T>(res: ApplicationProtoPrivateRes, mapped: T): { type: ApplicationProtoPrivateResType, data: T } {
        return { type: res.type, data: mapped };
    }

    get onUnauthenticatedRequest$() {
        return this.backend.onUnauthenticatedRequest$;
    }
}
