/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { SelectionModel } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { ApiListResponse, mergeListChanges } from "../concept/api-response";
import { Parameters, Filter, Pagination, Sorting } from "../concept/common";
import { BaseConcept, Property } from "../concept/base";
import { BehaviorSubject, Observable, Subject, combineLatest, debounceTime, defaultIfEmpty, first, forkJoin, map, of, shareReplay, startWith, switchMap, tap } from "rxjs";
import { AccessLevel, hasAdminAccess, hasReadAccess, hasWriteAccess } from "../concept/iam";
import { Sort } from "@angular/material/sort";
import { FilterSpec } from "../framework/table";
import { isBlank } from "../util";
import { SnackbarService } from "./snackbar.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class ResourceTableService {

    constructor(private snackbar: SnackbarService, private router: Router) {
    }

    // TODO: maybe convert to directive (tpSort)?
    handleMatSortChange<ENTITY extends BaseConcept>(table: ResourceTable<ENTITY, string>, event: Sort) {
        const property = event.direction === ""
            ? table.primaryProperty
            : table.properties.find(x => x.id === event.active);
        if (!property) {
            this.snackbar.errorPersistent(`Unexpected internal error: Unknown table property selected`);
            throw "Unknown table property selected";
        }
        const direction = event.direction === "" ? "asc" : event.direction;
        table.sorting$.next({ attributeType: property.attributeType, ownerType: property.ownerType, direction: direction });
    }

    handleRowClick<ENTITY extends BaseConcept>(table: ResourceTable<ENTITY, string>, item: ENTITY) {
        setTimeout(() => {
            if (!isBlank(window.getSelection()?.toString())) return; // handle case where user just wants to select some text
            table.ready$.next(false);
            table.canAccessResource$(item.uuid).pipe(first()).subscribe((hasReadAccess) => {
                table.ready$.next(true);
                if (hasReadAccess) {
                    this.router.navigate([table.itemRoute(item)]);
                } else {
                    this.snackbar.info(table.noAccessMessage(item), { duration: 4000 });
                }
            });
        });
    }

    rowClass$<ENTITY extends BaseConcept>(table: ResourceTable<ENTITY, string>, item: ENTITY): Observable<string | undefined> {
        return table.canAccessResource$(item.uuid).pipe(map(hasAccess => hasAccess ? 'table-row-clickable' : undefined));
    }
}

export const DEFAULT_INITIAL_PAGINATION: Pagination = { offset: 0, limit: 10 };
export const DEFAULT_INITIAL_SORTING: Sorting = { attributeType: "id", direction: "asc" };
export const DEFAULT_INITIAL_PARAMS: Parameters = {
    pagination: DEFAULT_INITIAL_PAGINATION,
    sorting: DEFAULT_INITIAL_SORTING,
    filters: [],
};

export type SpecialColumn = "select" | "actions" | "status" | "avatar";

export abstract class ResourceTable<ENTITY extends BaseConcept, COLUMN extends string> {
    readonly items$ = new BehaviorSubject<ENTITY[]>([]);
    readonly totalItems$: Observable<number | "many" | null>;
    readonly accessLevelsByUuid$ = new BehaviorSubject<Record<string, AccessLevel>>({});
    readonly ready$ = new BehaviorSubject(false);

    readonly pagination$: BehaviorSubject<Pagination>;
    readonly sorting$: BehaviorSubject<Sorting>;
    readonly filters$: BehaviorSubject<Filter[]>;
    protected readonly params$: Observable<Parameters>;
    protected readonly selection = new SelectionModel<ENTITY>(true, []);
    readonly hasWriteAccessToSelected$ = this.selectionChanged$.pipe(
        switchMap(() => forkJoin(
            this.selected.map(x => this.canModifyResource$(x.uuid).pipe(first()))
        ).pipe(defaultIfEmpty([]))),
        map((results) => results.every(x => !!x))
    );
    readonly hasAdminAccessToSelected$ = this.selectionChanged$.pipe(
        switchMap(() => forkJoin(
            this.selected.map(x => this.canAdminResource$(x.uuid).pipe(first()))
        ).pipe(defaultIfEmpty([]))),
        map((results) => results.every(x => !!x)),
        shareReplay(1),
    );

    protected readonly unsub$: Subject<void>;

    abstract filterSpecs: FilterSpec[];
    abstract properties: Property[];
    abstract primaryProperty: Property;
    abstract displayedProperties: Property[];

    abstract getData(params: Parameters): Observable<ApiListResponse<ENTITY>>;
    abstract getAccessLevels(resources: ENTITY[]): Observable<AccessLevel[]>;
    abstract itemRoute(item: ENTITY): string;
    abstract noAccessMessage(item: ENTITY): string;

    protected constructor(unsub$: Subject<void>, initialParams: Parameters = DEFAULT_INITIAL_PARAMS) {
        this.pagination$ = new BehaviorSubject(initialParams.pagination);
        this.sorting$ = new BehaviorSubject(initialParams.sorting);
        this.filters$ = new BehaviorSubject(initialParams.filters || []);
        this.params$ = combineLatest([this.pagination$, this.sorting$, this.filters$]).pipe(
            debounceTime(300),
            map(([pagination, sorting, filters]) => ({ pagination, sorting, filters }))
        );
        this.totalItems$ = combineLatest([this.items$, this.params$]).pipe(
            map(([items, params]) => {
                if (items == null) return null;
                else if (items.length < params.pagination.limit) return params.pagination.offset + items.length;
                else return "many" as const;
            }),
            shareReplay(1),
        );
        this.unsub$ = unsub$;
        this.initDataLoader();
    }

    private initDataLoader() {
        this.params$.pipe(
            tap(() => this.unsub$.next()),
            switchMap((params) => this.getData(params)),
            tap((res) => {
                this.ready$.next(true);
                const mergeResult = mergeListChanges(this.items$.value, res);
                if (mergeResult.result === "shouldResync") {
                    console.warn(`Received update is not valid in the current state; forcing a full resync`);
                    this.initDataLoader();
                    return;
                }
                this.items$.next(mergeResult.data || []);
            }),
            map(() => this.items$.value),
            tap(() => this.accessLevelsByUuid$.next({})),
            switchMap((items) => combineLatest([of(items), this.getAccessLevels(items)])),
            tap(([items, accessLevels]) => {
                this.accessLevelsByUuid$.next(Object.fromEntries(items.map((x, idx) => [x.uuid, accessLevels[idx]])));
            }),
        ).subscribe();
    }

    refresh() {
        this.initDataLoader();
    }

    get items(): ENTITY[] | null {
        return this.items$.value;
    }

    get columns(): Record<COLUMN, Property> {
        return Object.fromEntries(this.properties.map(x => [x.id, x])) as Record<COLUMN, Property>;
    }

    get displayedColumns(): (COLUMN | SpecialColumn)[] {
        return [...this.displayedProperties.map(x => x.id), "actions"] as (COLUMN | SpecialColumn)[];
    }

    accessLevelByUuid$(uuid: string): Observable<AccessLevel | null> {
        return this.accessLevelsByUuid$.pipe(map(x => x[uuid] || null));
    }

    canAccessResource$(uuid: string): Observable<boolean> {
        return this.accessLevelByUuid$(uuid).pipe(map(x => !!x && hasReadAccess(x)));
    }

    canModifyResource$(uuid: string): Observable<boolean> {
        return this.accessLevelByUuid$(uuid).pipe(map(x => !!x && hasWriteAccess(x)));
    }

    canAdminResource$(uuid: string): Observable<boolean> {
        return this.accessLevelByUuid$(uuid).pipe(map(x => !!x && hasAdminAccess(x)));
    }

    get selected() {
        return this.selection.selected;
    }

    get selectionChanged$() {
        return this.selection.changed.pipe(startWith(null));
    }

    isSelected(item: ENTITY) {
        return this.selection.isSelected(item);
    }

    isAnySelected() {
        return !!this.selection.selected.length;
    }

    toggleSelection(item: ENTITY) {
        this.selection.toggle(item);
    }

    isNonEmptyAndAllSelected(): boolean {
        return !!this.selection.selected.length && this.selection.selected.length === this.items$.value.length;
    }

    toggleAllSelected() {
        this.isNonEmptyAndAllSelected() ?
            this.selection.clear() :
            this.selection.select(...this.items$.value);
    }
}
