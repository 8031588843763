<tp-modal dialogTitle="Add a user" [isBusy]="isSubmitting$ | async">
  <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="submit()">
    <tp-form-select [form]="form" field="user" label="User" [options]="users"/>
    <tp-form-select [form]="form" field="accessLevel" label="Access Level" [options]="accessLevelOptions"/>
    <tp-form-actions
      [cancellable]="true" submitText="Submit" [isSubmitting]="isSubmitting$ | async"
      buttonIdPrefix="teamAddUserModal" (cancel)="close()"
    />
  </tp-form>
</tp-modal>
