/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, Input } from '@angular/core';
import { MatTableModule } from "@angular/material/table";
import { AsyncPipe, DatePipe } from '@angular/common';
import { SpinnerComponent } from "typedb-platform-framework";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { Discount, DiscountColumn } from "../../../concept/subscription";
import { CurrencyPipe } from "../currency.pipe";
import { MatSortModule } from '@angular/material/sort';

@Component({
    selector: 'tp-discounts-table',
    templateUrl: './discounts-table.component.html',
    standalone: true,
    imports: [AsyncPipe, DatePipe, MatSortModule, MatTableModule, SpinnerComponent, CurrencyPipe],
})
export class DiscountsTableComponent {
    @Input({ required: true }) table!: ResourceTable<Discount, DiscountColumn>
    constructor(public tableService: ResourceTableService) { }
}
