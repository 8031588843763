<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    <tp-button
      [matTooltip]="(cannotInviteReason$ | async) || ''" buttonStyle="secondary" buttonId="openInviteMembersModal"
      [enabled]="(inviteEnabled$ | async) || false" (click)="onInviteClick()"
    >
      <fa-icon [icon]="['fal', 'envelope']"/> Invite
    </tp-button>
  </div>
  <article>
    <section>
      <tp-table-toolbar [table]="members" filterGroupId="members" tableTitle="Members" />
      <tp-users-table [table]="members" [rowPopupMenuItems]="usersTableRowPopupMenuItems"/>
      <tp-table-paginator [table]="members"/>
    </section>

    @if (invitations) {
      <section>
        <tp-table-toolbar [table]="invitations" filterGroupId="invitations" tableTitle="Invitations"/>
        <tp-invitations-table [table]="invitations" [rowPopupMenuItems]="invitationsTableRowPopupMenuItems"/>
        <tp-table-paginator [table]="invitations"/>
      </section>
    }
  </article>
</tp-page-scaffold>
