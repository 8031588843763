/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/user-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/common";
import * as dependency_2 from "./../../protocol/concept";
import * as pb_1 from "google-protobuf";
export class UserCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProto {
        const message = new UserCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProto {
        return UserCtlProto.deserialize(bytes);
    }
}
export class UserCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: UserCtlProtoGetAccessLevelsReq;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: UserCtlProtoCheckUserIDReq;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: UserCtlProtoCheckUserEmailReq;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: UserCtlProtoSendVerifyEmailReq;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: UserCtlProtoSignUpReq;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: UserCtlProtoUpdateVerificationStatusReq;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: UserCtlProtoCreateUserReq;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: UserCtlProtoGetUserReq;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: UserCtlProtoUpdateUserReq;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: UserCtlProtoCompleteOnboardingSurveyReq;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: UserCtlProtoListProjectsReq;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: UserCtlProtoListTeamsReq;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: UserCtlProtoListOrgsReq;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: UserCtlProtoListInvitationsReq;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: UserCtlProtoAcceptInvitationReq;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: UserCtlProtoRejectInvitationReq;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: UserCtlProtoLeaveOrganizationReq;
        setProperty?: never;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: UserCtlProtoSetPropertyReq;
        unsetProperty?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        sendVerifyEmail?: never;
        signUp?: never;
        updateVerificationStatus?: never;
        createUser?: never;
        getUser?: never;
        updateUser?: never;
        completeOnboardingSurvey?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
        acceptInvitation?: never;
        rejectInvitation?: never;
        leaveOrg?: never;
        setProperty?: never;
        unsetProperty?: UserCtlProtoUnsetPropertyReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkUserId" in data && data.checkUserId != undefined) {
                this.checkUserId = data.checkUserId;
            }
            if ("checkUserEmail" in data && data.checkUserEmail != undefined) {
                this.checkUserEmail = data.checkUserEmail;
            }
            if ("sendVerifyEmail" in data && data.sendVerifyEmail != undefined) {
                this.sendVerifyEmail = data.sendVerifyEmail;
            }
            if ("signUp" in data && data.signUp != undefined) {
                this.signUp = data.signUp;
            }
            if ("updateVerificationStatus" in data && data.updateVerificationStatus != undefined) {
                this.updateVerificationStatus = data.updateVerificationStatus;
            }
            if ("createUser" in data && data.createUser != undefined) {
                this.createUser = data.createUser;
            }
            if ("getUser" in data && data.getUser != undefined) {
                this.getUser = data.getUser;
            }
            if ("updateUser" in data && data.updateUser != undefined) {
                this.updateUser = data.updateUser;
            }
            if ("completeOnboardingSurvey" in data && data.completeOnboardingSurvey != undefined) {
                this.completeOnboardingSurvey = data.completeOnboardingSurvey;
            }
            if ("listProjects" in data && data.listProjects != undefined) {
                this.listProjects = data.listProjects;
            }
            if ("listTeams" in data && data.listTeams != undefined) {
                this.listTeams = data.listTeams;
            }
            if ("listOrgs" in data && data.listOrgs != undefined) {
                this.listOrgs = data.listOrgs;
            }
            if ("listInvitations" in data && data.listInvitations != undefined) {
                this.listInvitations = data.listInvitations;
            }
            if ("acceptInvitation" in data && data.acceptInvitation != undefined) {
                this.acceptInvitation = data.acceptInvitation;
            }
            if ("rejectInvitation" in data && data.rejectInvitation != undefined) {
                this.rejectInvitation = data.rejectInvitation;
            }
            if ("leaveOrg" in data && data.leaveOrg != undefined) {
                this.leaveOrg = data.leaveOrg;
            }
            if ("setProperty" in data && data.setProperty != undefined) {
                this.setProperty = data.setProperty;
            }
            if ("unsetProperty" in data && data.unsetProperty != undefined) {
                this.unsetProperty = data.unsetProperty;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoGetAccessLevelsReq, 1) as UserCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: UserCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkUserId() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoCheckUserIDReq, 2) as UserCtlProtoCheckUserIDReq;
    }
    set checkUserId(value: UserCtlProtoCheckUserIDReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckUserId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get checkUserEmail() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoCheckUserEmailReq, 3) as UserCtlProtoCheckUserEmailReq;
    }
    set checkUserEmail(value: UserCtlProtoCheckUserEmailReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasCheckUserEmail() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get sendVerifyEmail() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoSendVerifyEmailReq, 4) as UserCtlProtoSendVerifyEmailReq;
    }
    set sendVerifyEmail(value: UserCtlProtoSendVerifyEmailReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasSendVerifyEmail() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get signUp() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoSignUpReq, 5) as UserCtlProtoSignUpReq;
    }
    set signUp(value: UserCtlProtoSignUpReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasSignUp() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get updateVerificationStatus() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoUpdateVerificationStatusReq, 6) as UserCtlProtoUpdateVerificationStatusReq;
    }
    set updateVerificationStatus(value: UserCtlProtoUpdateVerificationStatusReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasUpdateVerificationStatus() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get createUser() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoCreateUserReq, 7) as UserCtlProtoCreateUserReq;
    }
    set createUser(value: UserCtlProtoCreateUserReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasCreateUser() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get getUser() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoGetUserReq, 8) as UserCtlProtoGetUserReq;
    }
    set getUser(value: UserCtlProtoGetUserReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasGetUser() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get updateUser() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoUpdateUserReq, 9) as UserCtlProtoUpdateUserReq;
    }
    set updateUser(value: UserCtlProtoUpdateUserReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasUpdateUser() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get completeOnboardingSurvey() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoCompleteOnboardingSurveyReq, 10) as UserCtlProtoCompleteOnboardingSurveyReq;
    }
    set completeOnboardingSurvey(value: UserCtlProtoCompleteOnboardingSurveyReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasCompleteOnboardingSurvey() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get listProjects() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoListProjectsReq, 12) as UserCtlProtoListProjectsReq;
    }
    set listProjects(value: UserCtlProtoListProjectsReq) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasListProjects() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get listTeams() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoListTeamsReq, 13) as UserCtlProtoListTeamsReq;
    }
    set listTeams(value: UserCtlProtoListTeamsReq) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasListTeams() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get listOrgs() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoListOrgsReq, 14) as UserCtlProtoListOrgsReq;
    }
    set listOrgs(value: UserCtlProtoListOrgsReq) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
    }
    get hasListOrgs() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get listInvitations() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoListInvitationsReq, 15) as UserCtlProtoListInvitationsReq;
    }
    set listInvitations(value: UserCtlProtoListInvitationsReq) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
    }
    get hasListInvitations() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get acceptInvitation() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoAcceptInvitationReq, 16) as UserCtlProtoAcceptInvitationReq;
    }
    set acceptInvitation(value: UserCtlProtoAcceptInvitationReq) {
        pb_1.Message.setOneofWrapperField(this, 16, this.#one_of_decls[0], value);
    }
    get hasAcceptInvitation() {
        return pb_1.Message.getField(this, 16) != null;
    }
    get rejectInvitation() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoRejectInvitationReq, 17) as UserCtlProtoRejectInvitationReq;
    }
    set rejectInvitation(value: UserCtlProtoRejectInvitationReq) {
        pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0], value);
    }
    get hasRejectInvitation() {
        return pb_1.Message.getField(this, 17) != null;
    }
    get leaveOrg() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoLeaveOrganizationReq, 18) as UserCtlProtoLeaveOrganizationReq;
    }
    set leaveOrg(value: UserCtlProtoLeaveOrganizationReq) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0], value);
    }
    get hasLeaveOrg() {
        return pb_1.Message.getField(this, 18) != null;
    }
    get setProperty() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoSetPropertyReq, 19) as UserCtlProtoSetPropertyReq;
    }
    set setProperty(value: UserCtlProtoSetPropertyReq) {
        pb_1.Message.setOneofWrapperField(this, 19, this.#one_of_decls[0], value);
    }
    get hasSetProperty() {
        return pb_1.Message.getField(this, 19) != null;
    }
    get unsetProperty() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoUnsetPropertyReq, 20) as UserCtlProtoUnsetPropertyReq;
    }
    set unsetProperty(value: UserCtlProtoUnsetPropertyReq) {
        pb_1.Message.setOneofWrapperField(this, 20, this.#one_of_decls[0], value);
    }
    get hasUnsetProperty() {
        return pb_1.Message.getField(this, 20) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkUserId" | "checkUserEmail" | "sendVerifyEmail" | "signUp" | "updateVerificationStatus" | "createUser" | "getUser" | "updateUser" | "completeOnboardingSurvey" | "listProjects" | "listTeams" | "listOrgs" | "listInvitations" | "acceptInvitation" | "rejectInvitation" | "leaveOrg" | "setProperty" | "unsetProperty";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkUserId",
            3: "checkUserEmail",
            4: "sendVerifyEmail",
            5: "signUp",
            6: "updateVerificationStatus",
            7: "createUser",
            8: "getUser",
            9: "updateUser",
            10: "completeOnboardingSurvey",
            12: "listProjects",
            13: "listTeams",
            14: "listOrgs",
            15: "listInvitations",
            16: "acceptInvitation",
            17: "rejectInvitation",
            18: "leaveOrg",
            19: "setProperty",
            20: "unsetProperty"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof UserCtlProtoGetAccessLevelsReq.prototype.toObject>;
        checkUserId?: ReturnType<typeof UserCtlProtoCheckUserIDReq.prototype.toObject>;
        checkUserEmail?: ReturnType<typeof UserCtlProtoCheckUserEmailReq.prototype.toObject>;
        sendVerifyEmail?: ReturnType<typeof UserCtlProtoSendVerifyEmailReq.prototype.toObject>;
        signUp?: ReturnType<typeof UserCtlProtoSignUpReq.prototype.toObject>;
        updateVerificationStatus?: ReturnType<typeof UserCtlProtoUpdateVerificationStatusReq.prototype.toObject>;
        createUser?: ReturnType<typeof UserCtlProtoCreateUserReq.prototype.toObject>;
        getUser?: ReturnType<typeof UserCtlProtoGetUserReq.prototype.toObject>;
        updateUser?: ReturnType<typeof UserCtlProtoUpdateUserReq.prototype.toObject>;
        completeOnboardingSurvey?: ReturnType<typeof UserCtlProtoCompleteOnboardingSurveyReq.prototype.toObject>;
        listProjects?: ReturnType<typeof UserCtlProtoListProjectsReq.prototype.toObject>;
        listTeams?: ReturnType<typeof UserCtlProtoListTeamsReq.prototype.toObject>;
        listOrgs?: ReturnType<typeof UserCtlProtoListOrgsReq.prototype.toObject>;
        listInvitations?: ReturnType<typeof UserCtlProtoListInvitationsReq.prototype.toObject>;
        acceptInvitation?: ReturnType<typeof UserCtlProtoAcceptInvitationReq.prototype.toObject>;
        rejectInvitation?: ReturnType<typeof UserCtlProtoRejectInvitationReq.prototype.toObject>;
        leaveOrg?: ReturnType<typeof UserCtlProtoLeaveOrganizationReq.prototype.toObject>;
        setProperty?: ReturnType<typeof UserCtlProtoSetPropertyReq.prototype.toObject>;
        unsetProperty?: ReturnType<typeof UserCtlProtoUnsetPropertyReq.prototype.toObject>;
    }): UserCtlProtoReq {
        const message = new UserCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = UserCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.checkUserId != null) {
            message.checkUserId = UserCtlProtoCheckUserIDReq.fromObject(data.checkUserId);
        }
        if (data.checkUserEmail != null) {
            message.checkUserEmail = UserCtlProtoCheckUserEmailReq.fromObject(data.checkUserEmail);
        }
        if (data.sendVerifyEmail != null) {
            message.sendVerifyEmail = UserCtlProtoSendVerifyEmailReq.fromObject(data.sendVerifyEmail);
        }
        if (data.signUp != null) {
            message.signUp = UserCtlProtoSignUpReq.fromObject(data.signUp);
        }
        if (data.updateVerificationStatus != null) {
            message.updateVerificationStatus = UserCtlProtoUpdateVerificationStatusReq.fromObject(data.updateVerificationStatus);
        }
        if (data.createUser != null) {
            message.createUser = UserCtlProtoCreateUserReq.fromObject(data.createUser);
        }
        if (data.getUser != null) {
            message.getUser = UserCtlProtoGetUserReq.fromObject(data.getUser);
        }
        if (data.updateUser != null) {
            message.updateUser = UserCtlProtoUpdateUserReq.fromObject(data.updateUser);
        }
        if (data.completeOnboardingSurvey != null) {
            message.completeOnboardingSurvey = UserCtlProtoCompleteOnboardingSurveyReq.fromObject(data.completeOnboardingSurvey);
        }
        if (data.listProjects != null) {
            message.listProjects = UserCtlProtoListProjectsReq.fromObject(data.listProjects);
        }
        if (data.listTeams != null) {
            message.listTeams = UserCtlProtoListTeamsReq.fromObject(data.listTeams);
        }
        if (data.listOrgs != null) {
            message.listOrgs = UserCtlProtoListOrgsReq.fromObject(data.listOrgs);
        }
        if (data.listInvitations != null) {
            message.listInvitations = UserCtlProtoListInvitationsReq.fromObject(data.listInvitations);
        }
        if (data.acceptInvitation != null) {
            message.acceptInvitation = UserCtlProtoAcceptInvitationReq.fromObject(data.acceptInvitation);
        }
        if (data.rejectInvitation != null) {
            message.rejectInvitation = UserCtlProtoRejectInvitationReq.fromObject(data.rejectInvitation);
        }
        if (data.leaveOrg != null) {
            message.leaveOrg = UserCtlProtoLeaveOrganizationReq.fromObject(data.leaveOrg);
        }
        if (data.setProperty != null) {
            message.setProperty = UserCtlProtoSetPropertyReq.fromObject(data.setProperty);
        }
        if (data.unsetProperty != null) {
            message.unsetProperty = UserCtlProtoUnsetPropertyReq.fromObject(data.unsetProperty);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof UserCtlProtoGetAccessLevelsReq.prototype.toObject>;
            checkUserId?: ReturnType<typeof UserCtlProtoCheckUserIDReq.prototype.toObject>;
            checkUserEmail?: ReturnType<typeof UserCtlProtoCheckUserEmailReq.prototype.toObject>;
            sendVerifyEmail?: ReturnType<typeof UserCtlProtoSendVerifyEmailReq.prototype.toObject>;
            signUp?: ReturnType<typeof UserCtlProtoSignUpReq.prototype.toObject>;
            updateVerificationStatus?: ReturnType<typeof UserCtlProtoUpdateVerificationStatusReq.prototype.toObject>;
            createUser?: ReturnType<typeof UserCtlProtoCreateUserReq.prototype.toObject>;
            getUser?: ReturnType<typeof UserCtlProtoGetUserReq.prototype.toObject>;
            updateUser?: ReturnType<typeof UserCtlProtoUpdateUserReq.prototype.toObject>;
            completeOnboardingSurvey?: ReturnType<typeof UserCtlProtoCompleteOnboardingSurveyReq.prototype.toObject>;
            listProjects?: ReturnType<typeof UserCtlProtoListProjectsReq.prototype.toObject>;
            listTeams?: ReturnType<typeof UserCtlProtoListTeamsReq.prototype.toObject>;
            listOrgs?: ReturnType<typeof UserCtlProtoListOrgsReq.prototype.toObject>;
            listInvitations?: ReturnType<typeof UserCtlProtoListInvitationsReq.prototype.toObject>;
            acceptInvitation?: ReturnType<typeof UserCtlProtoAcceptInvitationReq.prototype.toObject>;
            rejectInvitation?: ReturnType<typeof UserCtlProtoRejectInvitationReq.prototype.toObject>;
            leaveOrg?: ReturnType<typeof UserCtlProtoLeaveOrganizationReq.prototype.toObject>;
            setProperty?: ReturnType<typeof UserCtlProtoSetPropertyReq.prototype.toObject>;
            unsetProperty?: ReturnType<typeof UserCtlProtoUnsetPropertyReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkUserId != null) {
            data.checkUserId = this.checkUserId.toObject();
        }
        if (this.checkUserEmail != null) {
            data.checkUserEmail = this.checkUserEmail.toObject();
        }
        if (this.sendVerifyEmail != null) {
            data.sendVerifyEmail = this.sendVerifyEmail.toObject();
        }
        if (this.signUp != null) {
            data.signUp = this.signUp.toObject();
        }
        if (this.updateVerificationStatus != null) {
            data.updateVerificationStatus = this.updateVerificationStatus.toObject();
        }
        if (this.createUser != null) {
            data.createUser = this.createUser.toObject();
        }
        if (this.getUser != null) {
            data.getUser = this.getUser.toObject();
        }
        if (this.updateUser != null) {
            data.updateUser = this.updateUser.toObject();
        }
        if (this.completeOnboardingSurvey != null) {
            data.completeOnboardingSurvey = this.completeOnboardingSurvey.toObject();
        }
        if (this.listProjects != null) {
            data.listProjects = this.listProjects.toObject();
        }
        if (this.listTeams != null) {
            data.listTeams = this.listTeams.toObject();
        }
        if (this.listOrgs != null) {
            data.listOrgs = this.listOrgs.toObject();
        }
        if (this.listInvitations != null) {
            data.listInvitations = this.listInvitations.toObject();
        }
        if (this.acceptInvitation != null) {
            data.acceptInvitation = this.acceptInvitation.toObject();
        }
        if (this.rejectInvitation != null) {
            data.rejectInvitation = this.rejectInvitation.toObject();
        }
        if (this.leaveOrg != null) {
            data.leaveOrg = this.leaveOrg.toObject();
        }
        if (this.setProperty != null) {
            data.setProperty = this.setProperty.toObject();
        }
        if (this.unsetProperty != null) {
            data.unsetProperty = this.unsetProperty.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckUserId)
            writer.writeMessage(2, this.checkUserId, () => this.checkUserId.serialize(writer));
        if (this.hasCheckUserEmail)
            writer.writeMessage(3, this.checkUserEmail, () => this.checkUserEmail.serialize(writer));
        if (this.hasSendVerifyEmail)
            writer.writeMessage(4, this.sendVerifyEmail, () => this.sendVerifyEmail.serialize(writer));
        if (this.hasSignUp)
            writer.writeMessage(5, this.signUp, () => this.signUp.serialize(writer));
        if (this.hasUpdateVerificationStatus)
            writer.writeMessage(6, this.updateVerificationStatus, () => this.updateVerificationStatus.serialize(writer));
        if (this.hasCreateUser)
            writer.writeMessage(7, this.createUser, () => this.createUser.serialize(writer));
        if (this.hasGetUser)
            writer.writeMessage(8, this.getUser, () => this.getUser.serialize(writer));
        if (this.hasUpdateUser)
            writer.writeMessage(9, this.updateUser, () => this.updateUser.serialize(writer));
        if (this.hasCompleteOnboardingSurvey)
            writer.writeMessage(10, this.completeOnboardingSurvey, () => this.completeOnboardingSurvey.serialize(writer));
        if (this.hasListProjects)
            writer.writeMessage(12, this.listProjects, () => this.listProjects.serialize(writer));
        if (this.hasListTeams)
            writer.writeMessage(13, this.listTeams, () => this.listTeams.serialize(writer));
        if (this.hasListOrgs)
            writer.writeMessage(14, this.listOrgs, () => this.listOrgs.serialize(writer));
        if (this.hasListInvitations)
            writer.writeMessage(15, this.listInvitations, () => this.listInvitations.serialize(writer));
        if (this.hasAcceptInvitation)
            writer.writeMessage(16, this.acceptInvitation, () => this.acceptInvitation.serialize(writer));
        if (this.hasRejectInvitation)
            writer.writeMessage(17, this.rejectInvitation, () => this.rejectInvitation.serialize(writer));
        if (this.hasLeaveOrg)
            writer.writeMessage(18, this.leaveOrg, () => this.leaveOrg.serialize(writer));
        if (this.hasSetProperty)
            writer.writeMessage(19, this.setProperty, () => this.setProperty.serialize(writer));
        if (this.hasUnsetProperty)
            writer.writeMessage(20, this.unsetProperty, () => this.unsetProperty.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = UserCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkUserId, () => message.checkUserId = UserCtlProtoCheckUserIDReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.checkUserEmail, () => message.checkUserEmail = UserCtlProtoCheckUserEmailReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.sendVerifyEmail, () => message.sendVerifyEmail = UserCtlProtoSendVerifyEmailReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.signUp, () => message.signUp = UserCtlProtoSignUpReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.updateVerificationStatus, () => message.updateVerificationStatus = UserCtlProtoUpdateVerificationStatusReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.createUser, () => message.createUser = UserCtlProtoCreateUserReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.getUser, () => message.getUser = UserCtlProtoGetUserReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.updateUser, () => message.updateUser = UserCtlProtoUpdateUserReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.completeOnboardingSurvey, () => message.completeOnboardingSurvey = UserCtlProtoCompleteOnboardingSurveyReq.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.listProjects, () => message.listProjects = UserCtlProtoListProjectsReq.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.listTeams, () => message.listTeams = UserCtlProtoListTeamsReq.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.listOrgs, () => message.listOrgs = UserCtlProtoListOrgsReq.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.listInvitations, () => message.listInvitations = UserCtlProtoListInvitationsReq.deserialize(reader));
                    break;
                case 16:
                    reader.readMessage(message.acceptInvitation, () => message.acceptInvitation = UserCtlProtoAcceptInvitationReq.deserialize(reader));
                    break;
                case 17:
                    reader.readMessage(message.rejectInvitation, () => message.rejectInvitation = UserCtlProtoRejectInvitationReq.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.leaveOrg, () => message.leaveOrg = UserCtlProtoLeaveOrganizationReq.deserialize(reader));
                    break;
                case 19:
                    reader.readMessage(message.setProperty, () => message.setProperty = UserCtlProtoSetPropertyReq.deserialize(reader));
                    break;
                case 20:
                    reader.readMessage(message.unsetProperty, () => message.unsetProperty = UserCtlProtoUnsetPropertyReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoReq {
        return UserCtlProtoReq.deserialize(bytes);
    }
}
export class UserCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 7, 10, 11, 12, 13]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: UserCtlProtoGetAccessLevelsRes;
        checkUserId?: never;
        checkUserEmail?: never;
        getUser?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: UserCtlProtoCheckUserIDRes;
        checkUserEmail?: never;
        getUser?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: UserCtlProtoCheckUserEmailRes;
        getUser?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        getUser?: UserCtlProtoGetUserRes;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        getUser?: never;
        listProjects?: UserCtlProtoListProjectsRes;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        getUser?: never;
        listProjects?: never;
        listTeams?: UserCtlProtoListTeamsRes;
        listOrgs?: never;
        listInvitations?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        getUser?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: UserCtlProtoListOrgsRes;
        listInvitations?: never;
    } | {
        getAccessLevels?: never;
        checkUserId?: never;
        checkUserEmail?: never;
        getUser?: never;
        listProjects?: never;
        listTeams?: never;
        listOrgs?: never;
        listInvitations?: UserCtlProtoListInvitationsRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("checkUserId" in data && data.checkUserId != undefined) {
                this.checkUserId = data.checkUserId;
            }
            if ("checkUserEmail" in data && data.checkUserEmail != undefined) {
                this.checkUserEmail = data.checkUserEmail;
            }
            if ("getUser" in data && data.getUser != undefined) {
                this.getUser = data.getUser;
            }
            if ("listProjects" in data && data.listProjects != undefined) {
                this.listProjects = data.listProjects;
            }
            if ("listTeams" in data && data.listTeams != undefined) {
                this.listTeams = data.listTeams;
            }
            if ("listOrgs" in data && data.listOrgs != undefined) {
                this.listOrgs = data.listOrgs;
            }
            if ("listInvitations" in data && data.listInvitations != undefined) {
                this.listInvitations = data.listInvitations;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoGetAccessLevelsRes, 1) as UserCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: UserCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get checkUserId() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoCheckUserIDRes, 2) as UserCtlProtoCheckUserIDRes;
    }
    set checkUserId(value: UserCtlProtoCheckUserIDRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasCheckUserId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get checkUserEmail() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoCheckUserEmailRes, 3) as UserCtlProtoCheckUserEmailRes;
    }
    set checkUserEmail(value: UserCtlProtoCheckUserEmailRes) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasCheckUserEmail() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getUser() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoGetUserRes, 7) as UserCtlProtoGetUserRes;
    }
    set getUser(value: UserCtlProtoGetUserRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasGetUser() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listProjects() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoListProjectsRes, 10) as UserCtlProtoListProjectsRes;
    }
    set listProjects(value: UserCtlProtoListProjectsRes) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasListProjects() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get listTeams() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoListTeamsRes, 11) as UserCtlProtoListTeamsRes;
    }
    set listTeams(value: UserCtlProtoListTeamsRes) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasListTeams() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get listOrgs() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoListOrgsRes, 12) as UserCtlProtoListOrgsRes;
    }
    set listOrgs(value: UserCtlProtoListOrgsRes) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasListOrgs() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get listInvitations() {
        return pb_1.Message.getWrapperField(this, UserCtlProtoListInvitationsRes, 13) as UserCtlProtoListInvitationsRes;
    }
    set listInvitations(value: UserCtlProtoListInvitationsRes) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasListInvitations() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "checkUserId" | "checkUserEmail" | "getUser" | "listProjects" | "listTeams" | "listOrgs" | "listInvitations";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "checkUserId",
            3: "checkUserEmail",
            7: "getUser",
            10: "listProjects",
            11: "listTeams",
            12: "listOrgs",
            13: "listInvitations"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 7, 10, 11, 12, 13])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof UserCtlProtoGetAccessLevelsRes.prototype.toObject>;
        checkUserId?: ReturnType<typeof UserCtlProtoCheckUserIDRes.prototype.toObject>;
        checkUserEmail?: ReturnType<typeof UserCtlProtoCheckUserEmailRes.prototype.toObject>;
        getUser?: ReturnType<typeof UserCtlProtoGetUserRes.prototype.toObject>;
        listProjects?: ReturnType<typeof UserCtlProtoListProjectsRes.prototype.toObject>;
        listTeams?: ReturnType<typeof UserCtlProtoListTeamsRes.prototype.toObject>;
        listOrgs?: ReturnType<typeof UserCtlProtoListOrgsRes.prototype.toObject>;
        listInvitations?: ReturnType<typeof UserCtlProtoListInvitationsRes.prototype.toObject>;
    }): UserCtlProtoRes {
        const message = new UserCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = UserCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.checkUserId != null) {
            message.checkUserId = UserCtlProtoCheckUserIDRes.fromObject(data.checkUserId);
        }
        if (data.checkUserEmail != null) {
            message.checkUserEmail = UserCtlProtoCheckUserEmailRes.fromObject(data.checkUserEmail);
        }
        if (data.getUser != null) {
            message.getUser = UserCtlProtoGetUserRes.fromObject(data.getUser);
        }
        if (data.listProjects != null) {
            message.listProjects = UserCtlProtoListProjectsRes.fromObject(data.listProjects);
        }
        if (data.listTeams != null) {
            message.listTeams = UserCtlProtoListTeamsRes.fromObject(data.listTeams);
        }
        if (data.listOrgs != null) {
            message.listOrgs = UserCtlProtoListOrgsRes.fromObject(data.listOrgs);
        }
        if (data.listInvitations != null) {
            message.listInvitations = UserCtlProtoListInvitationsRes.fromObject(data.listInvitations);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof UserCtlProtoGetAccessLevelsRes.prototype.toObject>;
            checkUserId?: ReturnType<typeof UserCtlProtoCheckUserIDRes.prototype.toObject>;
            checkUserEmail?: ReturnType<typeof UserCtlProtoCheckUserEmailRes.prototype.toObject>;
            getUser?: ReturnType<typeof UserCtlProtoGetUserRes.prototype.toObject>;
            listProjects?: ReturnType<typeof UserCtlProtoListProjectsRes.prototype.toObject>;
            listTeams?: ReturnType<typeof UserCtlProtoListTeamsRes.prototype.toObject>;
            listOrgs?: ReturnType<typeof UserCtlProtoListOrgsRes.prototype.toObject>;
            listInvitations?: ReturnType<typeof UserCtlProtoListInvitationsRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.checkUserId != null) {
            data.checkUserId = this.checkUserId.toObject();
        }
        if (this.checkUserEmail != null) {
            data.checkUserEmail = this.checkUserEmail.toObject();
        }
        if (this.getUser != null) {
            data.getUser = this.getUser.toObject();
        }
        if (this.listProjects != null) {
            data.listProjects = this.listProjects.toObject();
        }
        if (this.listTeams != null) {
            data.listTeams = this.listTeams.toObject();
        }
        if (this.listOrgs != null) {
            data.listOrgs = this.listOrgs.toObject();
        }
        if (this.listInvitations != null) {
            data.listInvitations = this.listInvitations.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasCheckUserId)
            writer.writeMessage(2, this.checkUserId, () => this.checkUserId.serialize(writer));
        if (this.hasCheckUserEmail)
            writer.writeMessage(3, this.checkUserEmail, () => this.checkUserEmail.serialize(writer));
        if (this.hasGetUser)
            writer.writeMessage(7, this.getUser, () => this.getUser.serialize(writer));
        if (this.hasListProjects)
            writer.writeMessage(10, this.listProjects, () => this.listProjects.serialize(writer));
        if (this.hasListTeams)
            writer.writeMessage(11, this.listTeams, () => this.listTeams.serialize(writer));
        if (this.hasListOrgs)
            writer.writeMessage(12, this.listOrgs, () => this.listOrgs.serialize(writer));
        if (this.hasListInvitations)
            writer.writeMessage(13, this.listInvitations, () => this.listInvitations.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = UserCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.checkUserId, () => message.checkUserId = UserCtlProtoCheckUserIDRes.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.checkUserEmail, () => message.checkUserEmail = UserCtlProtoCheckUserEmailRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.getUser, () => message.getUser = UserCtlProtoGetUserRes.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.listProjects, () => message.listProjects = UserCtlProtoListProjectsRes.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.listTeams, () => message.listTeams = UserCtlProtoListTeamsRes.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.listOrgs, () => message.listOrgs = UserCtlProtoListOrgsRes.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.listInvitations, () => message.listInvitations = UserCtlProtoListInvitationsRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoRes {
        return UserCtlProtoRes.deserialize(bytes);
    }
}
export class UserCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoGetAccessLevels {
        const message = new UserCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoGetAccessLevels {
        return UserCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class UserCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userUuids" in data && data.userUuids != undefined) {
                this.userUuids = data.userUuids;
            }
        }
    }
    get userUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set userUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        userUuids?: Uint8Array[];
    }): UserCtlProtoGetAccessLevelsReq {
        const message = new UserCtlProtoGetAccessLevelsReq({});
        if (data.userUuids != null) {
            message.userUuids = data.userUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            userUuids?: Uint8Array[];
        } = {};
        if (this.userUuids != null) {
            data.userUuids = this.userUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userUuids.length)
            writer.writeRepeatedBytes(1, this.userUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoGetAccessLevelsReq {
        return UserCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class UserCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_2.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_2.AccessLevelProto[];
    }
    set accessLevels(value: dependency_2.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_2.AccessLevelProto[];
    }): UserCtlProtoGetAccessLevelsRes {
        const message = new UserCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_2.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoGetAccessLevelsRes {
        return UserCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class UserCtlProtoCheckUserID extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoCheckUserID {
        const message = new UserCtlProtoCheckUserID({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCheckUserID {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCheckUserID();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCheckUserID {
        return UserCtlProtoCheckUserID.deserialize(bytes);
    }
}
export class UserCtlProtoCheckUserIDReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        id?: string;
    }): UserCtlProtoCheckUserIDReq {
        const message = new UserCtlProtoCheckUserIDReq({});
        if (data.id != null) {
            message.id = data.id;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCheckUserIDReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCheckUserIDReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCheckUserIDReq {
        return UserCtlProtoCheckUserIDReq.deserialize(bytes);
    }
}
export class UserCtlProtoCheckUserIDRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        exists?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("exists" in data && data.exists != undefined) {
                this.exists = data.exists;
            }
        }
    }
    get exists() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set exists(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        exists?: boolean;
    }): UserCtlProtoCheckUserIDRes {
        const message = new UserCtlProtoCheckUserIDRes({});
        if (data.exists != null) {
            message.exists = data.exists;
        }
        return message;
    }
    toObject() {
        const data: {
            exists?: boolean;
        } = {};
        if (this.exists != null) {
            data.exists = this.exists;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.exists != false)
            writer.writeBool(1, this.exists);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCheckUserIDRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCheckUserIDRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.exists = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCheckUserIDRes {
        return UserCtlProtoCheckUserIDRes.deserialize(bytes);
    }
}
export class UserCtlProtoCheckUserEmail extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoCheckUserEmail {
        const message = new UserCtlProtoCheckUserEmail({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCheckUserEmail {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCheckUserEmail();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCheckUserEmail {
        return UserCtlProtoCheckUserEmail.deserialize(bytes);
    }
}
export class UserCtlProtoCheckUserEmailReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        email?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
        }
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        email?: string;
    }): UserCtlProtoCheckUserEmailReq {
        const message = new UserCtlProtoCheckUserEmailReq({});
        if (data.email != null) {
            message.email = data.email;
        }
        return message;
    }
    toObject() {
        const data: {
            email?: string;
        } = {};
        if (this.email != null) {
            data.email = this.email;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.email.length)
            writer.writeString(1, this.email);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCheckUserEmailReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCheckUserEmailReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.email = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCheckUserEmailReq {
        return UserCtlProtoCheckUserEmailReq.deserialize(bytes);
    }
}
export class UserCtlProtoCheckUserEmailRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        exists?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("exists" in data && data.exists != undefined) {
                this.exists = data.exists;
            }
        }
    }
    get exists() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set exists(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        exists?: boolean;
    }): UserCtlProtoCheckUserEmailRes {
        const message = new UserCtlProtoCheckUserEmailRes({});
        if (data.exists != null) {
            message.exists = data.exists;
        }
        return message;
    }
    toObject() {
        const data: {
            exists?: boolean;
        } = {};
        if (this.exists != null) {
            data.exists = this.exists;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.exists != false)
            writer.writeBool(1, this.exists);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCheckUserEmailRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCheckUserEmailRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.exists = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCheckUserEmailRes {
        return UserCtlProtoCheckUserEmailRes.deserialize(bytes);
    }
}
export class UserCtlProtoSendVerifyEmail extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoSendVerifyEmail {
        const message = new UserCtlProtoSendVerifyEmail({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoSendVerifyEmail {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoSendVerifyEmail();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoSendVerifyEmail {
        return UserCtlProtoSendVerifyEmail.deserialize(bytes);
    }
}
export class UserCtlProtoSendVerifyEmailReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        isAsync?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("isAsync" in data && data.isAsync != undefined) {
                this.isAsync = data.isAsync;
            }
        }
    }
    get isAsync() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set isAsync(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        isAsync?: boolean;
    }): UserCtlProtoSendVerifyEmailReq {
        const message = new UserCtlProtoSendVerifyEmailReq({});
        if (data.isAsync != null) {
            message.isAsync = data.isAsync;
        }
        return message;
    }
    toObject() {
        const data: {
            isAsync?: boolean;
        } = {};
        if (this.isAsync != null) {
            data.isAsync = this.isAsync;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.isAsync != false)
            writer.writeBool(1, this.isAsync);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoSendVerifyEmailReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoSendVerifyEmailReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.isAsync = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoSendVerifyEmailReq {
        return UserCtlProtoSendVerifyEmailReq.deserialize(bytes);
    }
}
export class UserCtlProtoSignUp extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoSignUp {
        const message = new UserCtlProtoSignUp({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoSignUp {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoSignUp();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoSignUp {
        return UserCtlProtoSignUp.deserialize(bytes);
    }
}
export class UserCtlProtoSignUpReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        email?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
        }
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        email?: string;
    }): UserCtlProtoSignUpReq {
        const message = new UserCtlProtoSignUpReq({});
        if (data.email != null) {
            message.email = data.email;
        }
        return message;
    }
    toObject() {
        const data: {
            email?: string;
        } = {};
        if (this.email != null) {
            data.email = this.email;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.email.length)
            writer.writeString(1, this.email);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoSignUpReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoSignUpReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.email = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoSignUpReq {
        return UserCtlProtoSignUpReq.deserialize(bytes);
    }
}
export class UserCtlProtoUpdateVerificationStatus extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoUpdateVerificationStatus {
        const message = new UserCtlProtoUpdateVerificationStatus({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoUpdateVerificationStatus {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoUpdateVerificationStatus();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoUpdateVerificationStatus {
        return UserCtlProtoUpdateVerificationStatus.deserialize(bytes);
    }
}
export class UserCtlProtoUpdateVerificationStatusReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoUpdateVerificationStatusReq {
        const message = new UserCtlProtoUpdateVerificationStatusReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoUpdateVerificationStatusReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoUpdateVerificationStatusReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoUpdateVerificationStatusReq {
        return UserCtlProtoUpdateVerificationStatusReq.deserialize(bytes);
    }
}
export class UserCtlProtoCreateUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoCreateUser {
        const message = new UserCtlProtoCreateUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCreateUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCreateUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCreateUser {
        return UserCtlProtoCreateUser.deserialize(bytes);
    }
}
export class UserCtlProtoCreateUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        user?: dependency_2.UserProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
        }
    }
    get user() {
        return pb_1.Message.getWrapperField(this, dependency_2.UserProto, 1) as dependency_2.UserProto;
    }
    set user(value: dependency_2.UserProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasUser() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        user?: ReturnType<typeof dependency_2.UserProto.prototype.toObject>;
    }): UserCtlProtoCreateUserReq {
        const message = new UserCtlProtoCreateUserReq({});
        if (data.user != null) {
            message.user = dependency_2.UserProto.fromObject(data.user);
        }
        return message;
    }
    toObject() {
        const data: {
            user?: ReturnType<typeof dependency_2.UserProto.prototype.toObject>;
        } = {};
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUser)
            writer.writeMessage(1, this.user, () => this.user.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCreateUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCreateUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.user, () => message.user = dependency_2.UserProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCreateUserReq {
        return UserCtlProtoCreateUserReq.deserialize(bytes);
    }
}
export class UserCtlProtoGetUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoGetUser {
        const message = new UserCtlProtoGetUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoGetUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoGetUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoGetUser {
        return UserCtlProtoGetUser.deserialize(bytes);
    }
}
export class UserCtlProtoGetUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userId" in data && data.userId != undefined) {
                this.userId = data.userId;
            }
        }
    }
    get userId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set userId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        userId?: string;
    }): UserCtlProtoGetUserReq {
        const message = new UserCtlProtoGetUserReq({});
        if (data.userId != null) {
            message.userId = data.userId;
        }
        return message;
    }
    toObject() {
        const data: {
            userId?: string;
        } = {};
        if (this.userId != null) {
            data.userId = this.userId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userId.length)
            writer.writeString(1, this.userId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoGetUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoGetUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.userId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoGetUserReq {
        return UserCtlProtoGetUserReq.deserialize(bytes);
    }
}
export class UserCtlProtoGetUserRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        user?: dependency_2.UserProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
        }
    }
    get user() {
        return pb_1.Message.getWrapperField(this, dependency_2.UserProto, 1) as dependency_2.UserProto;
    }
    set user(value: dependency_2.UserProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasUser() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        user?: ReturnType<typeof dependency_2.UserProto.prototype.toObject>;
    }): UserCtlProtoGetUserRes {
        const message = new UserCtlProtoGetUserRes({});
        if (data.user != null) {
            message.user = dependency_2.UserProto.fromObject(data.user);
        }
        return message;
    }
    toObject() {
        const data: {
            user?: ReturnType<typeof dependency_2.UserProto.prototype.toObject>;
        } = {};
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasUser)
            writer.writeMessage(1, this.user, () => this.user.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoGetUserRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoGetUserRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.user, () => message.user = dependency_2.UserProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoGetUserRes {
        return UserCtlProtoGetUserRes.deserialize(bytes);
    }
}
export class UserCtlProtoUpdateUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoUpdateUser {
        const message = new UserCtlProtoUpdateUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoUpdateUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoUpdateUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoUpdateUser {
        return UserCtlProtoUpdateUser.deserialize(bytes);
    }
}
export class UserCtlProtoUpdateUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1]];
    constructor(data?: any[] | ({
        user?: dependency_2.UserProto;
    } & (({
        token?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
        }
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get user() {
        return pb_1.Message.getWrapperField(this, dependency_2.UserProto, 2) as dependency_2.UserProto;
    }
    set user(value: dependency_2.UserProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasUser() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _token() {
        const cases: {
            [index: number]: "none" | "token";
        } = {
            0: "none",
            1: "token"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    static fromObject(data: {
        token?: string;
        user?: ReturnType<typeof dependency_2.UserProto.prototype.toObject>;
    }): UserCtlProtoUpdateUserReq {
        const message = new UserCtlProtoUpdateUserReq({});
        if (data.token != null) {
            message.token = data.token;
        }
        if (data.user != null) {
            message.user = dependency_2.UserProto.fromObject(data.user);
        }
        return message;
    }
    toObject() {
        const data: {
            token?: string;
            user?: ReturnType<typeof dependency_2.UserProto.prototype.toObject>;
        } = {};
        if (this.token != null) {
            data.token = this.token;
        }
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeString(1, this.token);
        if (this.hasUser)
            writer.writeMessage(2, this.user, () => this.user.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoUpdateUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoUpdateUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.token = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.user, () => message.user = dependency_2.UserProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoUpdateUserReq {
        return UserCtlProtoUpdateUserReq.deserialize(bytes);
    }
}
export class UserCtlProtoListProjects extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoListProjects {
        const message = new UserCtlProtoListProjects({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListProjects {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListProjects();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListProjects {
        return UserCtlProtoListProjects.deserialize(bytes);
    }
}
export class UserCtlProtoListProjectsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userUuid?: Uint8Array;
        orgUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 3) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 3) != null;
    }
    static fromObject(data: {
        userUuid?: Uint8Array;
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): UserCtlProtoListProjectsReq {
        const message = new UserCtlProtoListProjectsReq({});
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            userUuid?: Uint8Array;
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userUuid.length)
            writer.writeBytes(1, this.userUuid);
        if (this.orgUuid.length)
            writer.writeBytes(2, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(3, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListProjectsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListProjectsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.userUuid = reader.readBytes();
                    break;
                case 2:
                    message.orgUuid = reader.readBytes();
                    break;
                case 3:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListProjectsReq {
        return UserCtlProtoListProjectsReq.deserialize(bytes);
    }
}
export class UserCtlProtoListProjectsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projects?: dependency_2.UserProjectProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projects" in data && data.projects != undefined) {
                this.projects = data.projects;
            }
        }
    }
    get projects() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.UserProjectProto, 1) as dependency_2.UserProjectProto[];
    }
    set projects(value: dependency_2.UserProjectProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        projects?: ReturnType<typeof dependency_2.UserProjectProto.prototype.toObject>[];
    }): UserCtlProtoListProjectsRes {
        const message = new UserCtlProtoListProjectsRes({});
        if (data.projects != null) {
            message.projects = data.projects.map(item => dependency_2.UserProjectProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            projects?: ReturnType<typeof dependency_2.UserProjectProto.prototype.toObject>[];
        } = {};
        if (this.projects != null) {
            data.projects = this.projects.map((item: dependency_2.UserProjectProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projects.length)
            writer.writeRepeatedMessage(1, this.projects, (item: dependency_2.UserProjectProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListProjectsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListProjectsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.projects, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.UserProjectProto.deserialize(reader), dependency_2.UserProjectProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListProjectsRes {
        return UserCtlProtoListProjectsRes.deserialize(bytes);
    }
}
export class UserCtlProtoListTeams extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoListTeams {
        const message = new UserCtlProtoListTeams({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListTeams {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListTeams();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListTeams {
        return UserCtlProtoListTeams.deserialize(bytes);
    }
}
export class UserCtlProtoListTeamsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userUuid?: Uint8Array;
        orgUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 3) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 3) != null;
    }
    static fromObject(data: {
        userUuid?: Uint8Array;
        orgUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): UserCtlProtoListTeamsReq {
        const message = new UserCtlProtoListTeamsReq({});
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            userUuid?: Uint8Array;
            orgUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userUuid.length)
            writer.writeBytes(1, this.userUuid);
        if (this.orgUuid.length)
            writer.writeBytes(2, this.orgUuid);
        if (this.hasParams)
            writer.writeMessage(3, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListTeamsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListTeamsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.userUuid = reader.readBytes();
                    break;
                case 2:
                    message.orgUuid = reader.readBytes();
                    break;
                case 3:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListTeamsReq {
        return UserCtlProtoListTeamsReq.deserialize(bytes);
    }
}
export class UserCtlProtoListTeamsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        teams?: dependency_2.UserTeamProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("teams" in data && data.teams != undefined) {
                this.teams = data.teams;
            }
        }
    }
    get teams() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.UserTeamProto, 1) as dependency_2.UserTeamProto[];
    }
    set teams(value: dependency_2.UserTeamProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        teams?: ReturnType<typeof dependency_2.UserTeamProto.prototype.toObject>[];
    }): UserCtlProtoListTeamsRes {
        const message = new UserCtlProtoListTeamsRes({});
        if (data.teams != null) {
            message.teams = data.teams.map(item => dependency_2.UserTeamProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            teams?: ReturnType<typeof dependency_2.UserTeamProto.prototype.toObject>[];
        } = {};
        if (this.teams != null) {
            data.teams = this.teams.map((item: dependency_2.UserTeamProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.teams.length)
            writer.writeRepeatedMessage(1, this.teams, (item: dependency_2.UserTeamProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListTeamsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListTeamsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.teams, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.UserTeamProto.deserialize(reader), dependency_2.UserTeamProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListTeamsRes {
        return UserCtlProtoListTeamsRes.deserialize(bytes);
    }
}
export class UserCtlProtoListOrgs extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoListOrgs {
        const message = new UserCtlProtoListOrgs({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListOrgs {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListOrgs();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListOrgs {
        return UserCtlProtoListOrgs.deserialize(bytes);
    }
}
export class UserCtlProtoListOrgsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): UserCtlProtoListOrgsReq {
        const message = new UserCtlProtoListOrgsReq({});
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListOrgsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListOrgsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListOrgsReq {
        return UserCtlProtoListOrgsReq.deserialize(bytes);
    }
}
export class UserCtlProtoListOrgsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgs?: dependency_2.UserOrgProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgs" in data && data.orgs != undefined) {
                this.orgs = data.orgs;
            }
        }
    }
    get orgs() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.UserOrgProto, 1) as dependency_2.UserOrgProto[];
    }
    set orgs(value: dependency_2.UserOrgProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        orgs?: ReturnType<typeof dependency_2.UserOrgProto.prototype.toObject>[];
    }): UserCtlProtoListOrgsRes {
        const message = new UserCtlProtoListOrgsRes({});
        if (data.orgs != null) {
            message.orgs = data.orgs.map(item => dependency_2.UserOrgProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            orgs?: ReturnType<typeof dependency_2.UserOrgProto.prototype.toObject>[];
        } = {};
        if (this.orgs != null) {
            data.orgs = this.orgs.map((item: dependency_2.UserOrgProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgs.length)
            writer.writeRepeatedMessage(1, this.orgs, (item: dependency_2.UserOrgProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListOrgsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListOrgsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.orgs, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.UserOrgProto.deserialize(reader), dependency_2.UserOrgProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListOrgsRes {
        return UserCtlProtoListOrgsRes.deserialize(bytes);
    }
}
export class UserCtlProtoListInvitations extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoListInvitations {
        const message = new UserCtlProtoListInvitations({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListInvitations {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListInvitations();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListInvitations {
        return UserCtlProtoListInvitations.deserialize(bytes);
    }
}
export class UserCtlProtoListInvitationsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        userUuid?: Uint8Array;
        params?: dependency_1.ParametersProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("userUuid" in data && data.userUuid != undefined) {
                this.userUuid = data.userUuid;
            }
            if ("params" in data && data.params != undefined) {
                this.params = data.params;
            }
        }
    }
    get userUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set userUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get params() {
        return pb_1.Message.getWrapperField(this, dependency_1.ParametersProto, 2) as dependency_1.ParametersProto;
    }
    set params(value: dependency_1.ParametersProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasParams() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        userUuid?: Uint8Array;
        params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
    }): UserCtlProtoListInvitationsReq {
        const message = new UserCtlProtoListInvitationsReq({});
        if (data.userUuid != null) {
            message.userUuid = data.userUuid;
        }
        if (data.params != null) {
            message.params = dependency_1.ParametersProto.fromObject(data.params);
        }
        return message;
    }
    toObject() {
        const data: {
            userUuid?: Uint8Array;
            params?: ReturnType<typeof dependency_1.ParametersProto.prototype.toObject>;
        } = {};
        if (this.userUuid != null) {
            data.userUuid = this.userUuid;
        }
        if (this.params != null) {
            data.params = this.params.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.userUuid.length)
            writer.writeBytes(1, this.userUuid);
        if (this.hasParams)
            writer.writeMessage(2, this.params, () => this.params.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListInvitationsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListInvitationsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.userUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.params, () => message.params = dependency_1.ParametersProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListInvitationsReq {
        return UserCtlProtoListInvitationsReq.deserialize(bytes);
    }
}
export class UserCtlProtoListInvitationsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitations?: dependency_2.InvitationProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitations" in data && data.invitations != undefined) {
                this.invitations = data.invitations;
            }
        }
    }
    get invitations() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.InvitationProto, 1) as dependency_2.InvitationProto[];
    }
    set invitations(value: dependency_2.InvitationProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        invitations?: ReturnType<typeof dependency_2.InvitationProto.prototype.toObject>[];
    }): UserCtlProtoListInvitationsRes {
        const message = new UserCtlProtoListInvitationsRes({});
        if (data.invitations != null) {
            message.invitations = data.invitations.map(item => dependency_2.InvitationProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            invitations?: ReturnType<typeof dependency_2.InvitationProto.prototype.toObject>[];
        } = {};
        if (this.invitations != null) {
            data.invitations = this.invitations.map((item: dependency_2.InvitationProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitations.length)
            writer.writeRepeatedMessage(1, this.invitations, (item: dependency_2.InvitationProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoListInvitationsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoListInvitationsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.invitations, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.InvitationProto.deserialize(reader), dependency_2.InvitationProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoListInvitationsRes {
        return UserCtlProtoListInvitationsRes.deserialize(bytes);
    }
}
export class UserCtlProtoAcceptInvitation extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoAcceptInvitation {
        const message = new UserCtlProtoAcceptInvitation({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoAcceptInvitation {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoAcceptInvitation();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoAcceptInvitation {
        return UserCtlProtoAcceptInvitation.deserialize(bytes);
    }
}
export class UserCtlProtoAcceptInvitationReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitationUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitationUuid" in data && data.invitationUuid != undefined) {
                this.invitationUuid = data.invitationUuid;
            }
        }
    }
    get invitationUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set invitationUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        invitationUuid?: Uint8Array;
    }): UserCtlProtoAcceptInvitationReq {
        const message = new UserCtlProtoAcceptInvitationReq({});
        if (data.invitationUuid != null) {
            message.invitationUuid = data.invitationUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            invitationUuid?: Uint8Array;
        } = {};
        if (this.invitationUuid != null) {
            data.invitationUuid = this.invitationUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitationUuid.length)
            writer.writeBytes(1, this.invitationUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoAcceptInvitationReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoAcceptInvitationReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.invitationUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoAcceptInvitationReq {
        return UserCtlProtoAcceptInvitationReq.deserialize(bytes);
    }
}
export class UserCtlProtoRejectInvitation extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoRejectInvitation {
        const message = new UserCtlProtoRejectInvitation({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoRejectInvitation {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoRejectInvitation();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoRejectInvitation {
        return UserCtlProtoRejectInvitation.deserialize(bytes);
    }
}
export class UserCtlProtoRejectInvitationReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        invitationUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("invitationUuid" in data && data.invitationUuid != undefined) {
                this.invitationUuid = data.invitationUuid;
            }
        }
    }
    get invitationUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set invitationUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        invitationUuid?: Uint8Array;
    }): UserCtlProtoRejectInvitationReq {
        const message = new UserCtlProtoRejectInvitationReq({});
        if (data.invitationUuid != null) {
            message.invitationUuid = data.invitationUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            invitationUuid?: Uint8Array;
        } = {};
        if (this.invitationUuid != null) {
            data.invitationUuid = this.invitationUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.invitationUuid.length)
            writer.writeBytes(1, this.invitationUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoRejectInvitationReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoRejectInvitationReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.invitationUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoRejectInvitationReq {
        return UserCtlProtoRejectInvitationReq.deserialize(bytes);
    }
}
export class UserCtlProtoLeaveOrganization extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoLeaveOrganization {
        const message = new UserCtlProtoLeaveOrganization({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoLeaveOrganization {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoLeaveOrganization();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoLeaveOrganization {
        return UserCtlProtoLeaveOrganization.deserialize(bytes);
    }
}
export class UserCtlProtoLeaveOrganizationReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
    }): UserCtlProtoLeaveOrganizationReq {
        const message = new UserCtlProtoLeaveOrganizationReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoLeaveOrganizationReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoLeaveOrganizationReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoLeaveOrganizationReq {
        return UserCtlProtoLeaveOrganizationReq.deserialize(bytes);
    }
}
export class UserCtlProtoSetProperty extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoSetProperty {
        const message = new UserCtlProtoSetProperty({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoSetProperty {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoSetProperty();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoSetProperty {
        return UserCtlProtoSetProperty.deserialize(bytes);
    }
}
export class UserCtlProtoSetPropertyReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        key?: string;
        value?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("key" in data && data.key != undefined) {
                this.key = data.key;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get key() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set key(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        key?: string;
        value?: string;
    }): UserCtlProtoSetPropertyReq {
        const message = new UserCtlProtoSetPropertyReq({});
        if (data.key != null) {
            message.key = data.key;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: {
            key?: string;
            value?: string;
        } = {};
        if (this.key != null) {
            data.key = this.key;
        }
        if (this.value != null) {
            data.value = this.value;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.key.length)
            writer.writeString(1, this.key);
        if (this.value.length)
            writer.writeString(2, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoSetPropertyReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoSetPropertyReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.key = reader.readString();
                    break;
                case 2:
                    message.value = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoSetPropertyReq {
        return UserCtlProtoSetPropertyReq.deserialize(bytes);
    }
}
export class UserCtlProtoUnsetProperty extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoUnsetProperty {
        const message = new UserCtlProtoUnsetProperty({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoUnsetProperty {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoUnsetProperty();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoUnsetProperty {
        return UserCtlProtoUnsetProperty.deserialize(bytes);
    }
}
export class UserCtlProtoUnsetPropertyReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        key?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("key" in data && data.key != undefined) {
                this.key = data.key;
            }
        }
    }
    get key() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set key(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        key?: string;
    }): UserCtlProtoUnsetPropertyReq {
        const message = new UserCtlProtoUnsetPropertyReq({});
        if (data.key != null) {
            message.key = data.key;
        }
        return message;
    }
    toObject() {
        const data: {
            key?: string;
        } = {};
        if (this.key != null) {
            data.key = this.key;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.key.length)
            writer.writeString(1, this.key);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoUnsetPropertyReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoUnsetPropertyReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.key = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoUnsetPropertyReq {
        return UserCtlProtoUnsetPropertyReq.deserialize(bytes);
    }
}
export class UserCtlProtoCompleteOnboardingSurvey extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoCompleteOnboardingSurvey {
        const message = new UserCtlProtoCompleteOnboardingSurvey({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCompleteOnboardingSurvey {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCompleteOnboardingSurvey();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCompleteOnboardingSurvey {
        return UserCtlProtoCompleteOnboardingSurvey.deserialize(bytes);
    }
}
export class UserCtlProtoCompleteOnboardingSurveyReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): UserCtlProtoCompleteOnboardingSurveyReq {
        const message = new UserCtlProtoCompleteOnboardingSurveyReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserCtlProtoCompleteOnboardingSurveyReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserCtlProtoCompleteOnboardingSurveyReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UserCtlProtoCompleteOnboardingSurveyReq {
        return UserCtlProtoCompleteOnboardingSurveyReq.deserialize(bytes);
    }
}
