/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { ClusterStatus, clusterStatusToString } from "../../concept/cluster";
import { Provider } from "../../concept/cluster-options";

@Pipe({
    name: "providerId",
    standalone: true,
})
export class ProviderIdPipe implements PipeTransform {
    transform(value: Provider): string {
        switch (value.id) {
            case "aws": return "AWS";
            case "gcp": return "GCP";
            case "azure": return "Azure";
            default: return value.name;
        }
    }
}
