<tp-modal [dialogTitle]="'Invitation for ' + data.userEmail">
  <p>This invitation will grant {{ data.userEmail }} the following access:</p>
  <br/>
  <tp-properties-table class="dark" [title]="'Organization Access'">
    <tp-properties-table-row [key]="data.org.name!">{{ data.accessLevel | accessLevel }}</tp-properties-table-row>
  </tp-properties-table>
  <tp-properties-table class="dark" [title]="'Team Access'">
    @for (teamAccess of data.teamAccess; track teamAccess.team.uuid) {
      <tp-properties-table-row [key]="teamAccess.team.name!">{{ teamAccess.accessLevel | accessLevel }}</tp-properties-table-row>
    }
  </tp-properties-table>
  <tp-properties-table class="dark" [title]="'Project Access'">
    @for (projectAccess of data.projectAccess; track projectAccess.project.uuid) {
      <tp-properties-table-row [key]="projectAccess.project.name!">{{ projectAccess.accessLevel | accessLevel }}</tp-properties-table-row>
    }
  </tp-properties-table>
</tp-modal>
