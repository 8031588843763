<mat-sidenav-container>
  <mat-sidenav mode="side" [opened]="!hideSidenav"><tp-sidebar/></mat-sidenav>

  <mat-sidenav-content>
    @switch (pageAvailability) {
      @case ("ready") {
        <ng-content />
      }
      @case ("loading") {
        <tp-spinner/>
      }
      @case (null) {
        <tp-spinner/>
      }
      @default {
      }
    }
  </mat-sidenav-content>
</mat-sidenav-container>
